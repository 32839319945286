import { Injectable } from "@angular/core";
import { EventService } from "./talos/event.service";
import { IEventInput } from "../talosApi/api/EventApi";
import { TAConstants, TAValues } from "../talosApi/settings";
import { UserService } from "./talos/user.service";
import { AppGlobalsService } from "./appGlobals.service";
import { isNullOrUndefined } from "util";
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import { TokenService } from "./talos/token.service";
import { to } from "../../../src/utils/utils";
import { UtilsService } from "./utils.service";
import Settings = TAConstants.Settings;
import { ShortifyService } from "./shared/shortify.service";

@Injectable()
export class SocialSharingService {
  private redirectUrl = "";

  constructor(
    private eventSrv: EventService,
    private userSrv: UserService,
    private appGlobalsSrv: AppGlobalsService,
    private tokenSrv: TokenService,
    private shortifyService: ShortifyService,
    private utils: UtilsService
  ) {
    this.redirectUrl =
      appGlobalsSrv.config[APPLICATION_SETTING_KEYS.SHARER_URL] || null;
  }

  /**
   * Formats url
   * @param baseUrl
   * @param redir_url
   */
  private formatUrl(ext_url: string, tokenId: string, brandStoreId?: string) {
    if (
      isNullOrUndefined(this.redirectUrl) ||
      isNullOrUndefined(ext_url) ||
      isNullOrUndefined(tokenId)
    ) {
      return;
    }

    const b = brandStoreId || "null";
    return (
      this.redirectUrl +
      encodeURIComponent(
        "?tokenId=" + tokenId + "&redirUrl=" + ext_url + "&brandStoreId=" + b
      )
    );
  }

  /**
   *
   * @param {string} message
   * @param {string} url
   * @param {string} message
   * @param {number} eventTypeId
   * @param {string} eventProperties
   * @param {string} notSupportedMessage
   */
  async shareFacebook(
    itemId: string,
    itemTypeId: string,
    message: string,
    url: string,
    eventTypeId: number,
    eventProperties: string,
    brandStoreId?: string
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const w = window.open("", "_blank");

      const generateShareToken = await to(
        this.tokenSrv.generateShareToken(
          TAValues.UserId,
          itemId,
          itemTypeId,
          "facebook"
        )
      );
      if (isNullOrUndefined(generateShareToken.data)) return;
      const tokenId = generateShareToken.data.token;
      const fUrl = this.formatUrl(url, tokenId, brandStoreId);
      const bUrl = await to(this.shortifyService.getShortifyLink(fUrl));

      if (isNullOrUndefined(bUrl) || isNullOrUndefined(bUrl.data)) return;

      const urlFinal = bUrl.data;

      // window.open('https://www.facebook.com/sharer/sharer.php?u=' + fUrl, "_blank");

      w.location.replace(
        "https://www.facebook.com/sharer/sharer.php?u=" + urlFinal
      );

      const input: IEventInput = {
        userId: TAValues.UserId,
        applicationId: TAValues.APPLICATION_ID,
        gameTypeId: Settings.GAME_TYPE,
        eventTypeId: eventTypeId,
        eventProperties: eventProperties,
        clientTypeId: TAValues.CLIENT_TYPE_ID,
        withNoRewards: false,
      };
      this.eventSrv
        .addEvent(input)
        .then((result) => {
          this.userSrv
            .updateUserStatus()
            .then((result) => logger.log(result))
            .catch((err) => logger.log(err));
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });

      // this.shareEvent(eventTypeId, eventProperties).then(res => {
      //     resolve(true);
      // }).catch(err => {
      //     reject(err);
      // });
    });
  }

  /**
   *
   * @param {string} message
   * @param {string} url
   * @param {number} eventTypeId
   * @param {string} eventProperties
   * @param {string} notSupportedMessage
   */
  async shareWhatsapp(
    itemId: string,
    itemTypeId: string,
    message: string,
    url: string,
    eventTypeId: number,
    eventProperties: string,
    notSupportedMessage: string
  ) {
    // const isChromium = window.chrome;

    const winNav = window.navigator;
    const isIOSChrome = winNav.userAgent.match("CriOS");

    let w;
    if (TAValues.CLIENT_TYPE_ID == TAConstants.Client_Types.IOS)
      w = window.open("", "_blank");

    const generateShareToken = await to(
      this.tokenSrv.generateShareToken(
        TAValues.UserId,
        itemId,
        itemTypeId,
        "whatsapp"
      )
    );
    if (isNullOrUndefined(generateShareToken.data)) return;
    const tokenId = generateShareToken.data.token;
    const fUrl = this.formatUrl(url, tokenId);
    const bUrl = await to(this.shortifyService.getShortifyLink(fUrl));

    if (isNullOrUndefined(bUrl) || isNullOrUndefined(bUrl.data)) return;

    const urlFinal = bUrl.data;

    // window.open("whatsapp://send?text=" + message + " " + fUrl, "_blank");
    // w.location.href.replace('$message', message);
    // w.location.href.replace('fUrl', fUrl);
    // w.location.reload(true);

    if (TAValues.CLIENT_TYPE_ID == TAConstants.Client_Types.IOS) {
      w.location.replace("whatsapp://send?text=" + message + " " + urlFinal);
    } else if (TAValues.CLIENT_TYPE_ID == TAConstants.Client_Types.ANDROID) {
      window.open("whatsapp://send?text=" + message + " " + fUrl, "_blank");
    } else {
      window.open(
        "https://api.whatsapp.com/send?text=" + message + " " + urlFinal,
        "_blank"
      );
    }

    // window['protocolCheck']("whatsapp://send?text=" + message + " " + url, function () {
    //     // $rootScope.showInfo(notSupportedMessage);
    // });
    this.shareEvent(eventTypeId, eventProperties);
  }

  async shareWhatsappMessage(
    itemId: string,
    itemTypeId: string,
    message: string,
    eventTypeId: number,
    eventProperties: string,
    notSupportedMessage: string
  ) {
    let w;

    const winNav = window.navigator;
    const isIOSChrome = winNav.userAgent.match("GSA");
    // alert(isIOSChrome);
    if (TAValues.CLIENT_TYPE_ID == TAConstants.Client_Types.IOS && !isIOSChrome)
      w = window.open("", "_blank");

    const mes = this.utils.replaceAll(
      encodeURIComponent(message),
      "change_line",
      "%0a"
    );
    if (
      TAValues.CLIENT_TYPE_ID == TAConstants.Client_Types.IOS &&
      !isIOSChrome
    ) {
      // window.open("whatsapp://send?text=" + message, "_blank");
      // alert("test");
      w.location.replace("whatsapp://send?text=" + mes);
    } else if (
      isIOSChrome ||
      TAValues.CLIENT_TYPE_ID == TAConstants.Client_Types.ANDROID
    ) {
      window.open("whatsapp://send?text=" + mes, "_blank");
    } else {
      window.open("https://api.whatsapp.com/send?text=" + mes, "_blank");
    }

    // window['protocolCheck']("whatsapp://send?text=" + message + " " + url, function () {
    //     // $rootScope.showInfo(notSupportedMessage);
    // });
    if (eventTypeId) this.shareEvent(eventTypeId, eventProperties);
  }

  /**
   *
   * @param {string} message
   * @param {string} subject
   * @param {string} url
   * @param {number} eventTypeId
   * @param {string} eventProperties
   */
  async shareEmail(
    itemId: string,
    itemTypeId: string,
    message: string,
    subject: string,
    url: string,
    eventTypeId: number,
    eventProperties: string
  ) {
    let w;
    if (TAValues.CLIENT_TYPE_ID == TAConstants.Client_Types.IOS) {
      w = window.open("", "_blank");
      // w.location.protocol = 'mailto:'
    }
    const generateShareToken = await to(
      this.tokenSrv.generateShareToken(
        TAValues.UserId,
        itemId,
        itemTypeId,
        "email"
      )
    );
    if (isNullOrUndefined(generateShareToken.data)) return;
    const tokenId = generateShareToken.data.token;
    const fUrl = this.formatUrl(url, tokenId);
    const bUrl = await to(this.shortifyService.getShortifyLink(fUrl));

    if (isNullOrUndefined(bUrl) || isNullOrUndefined(bUrl.data)) return;

    const urlFinal = bUrl.data;

    this.shareEvent(eventTypeId, eventProperties);
    //window.open("mailto:?subject=" + subject + "&body=" + message + " " + fUrl, "_blank");

    if (TAValues.CLIENT_TYPE_ID == TAConstants.Client_Types.IOS) {
      // window.open("mailto:?subject=" + subject + "&body=" + message + " " + fUrl, "_self");

      // logger.log('href', w.location.href);
      // logger.log('hrefToBe', "mailto:?subject=" + subject + "&body=" + message + " " + fUrl);
      w.location.replace(
        "mailto:?subject=" + subject + "&body=" + message + " " + urlFinal
      );

      // w.location.href = "mailto:?subject=" + subject + "&body=" + message + " " + fUrl, true;
      // logger.log('href', w.location.href);
    } else {
      window.open(
        "mailto:?subject=" + subject + "&body=" + message + " " + urlFinal,
        "_blank"
      );
    }

    //window.location.href = "mailto:?subject=" + subject + "&body=" + message + " " + url;

    // window.open("mailto:?subject=" + "subject" + "&body=" + "message" + " " + "fUrl", "_blank");
  }

  async shareEmailMessage(
    itemId: string,
    itemTypeId: string,
    message: string,
    subject: string,
    eventTypeId: number,
    eventProperties: string
  ) {
    let w;
    if (TAValues.CLIENT_TYPE_ID == TAConstants.Client_Types.IOS) {
      w = window.open("", "_blank");
      // w.location.protocol = 'mailto:'
    }
    // const generateShareToken = await to(this.tokenSrv.generateShareToken(
    //     TAValues.UserId,
    //     itemId,
    //     itemTypeId,
    //     "email"
    // ));
    // if (isNullOrUndefined(generateShareToken.data)) return;
    // const tokenId = generateShareToken.data.token;
    // const fUrl = this.formatUrl(url, tokenId);
    // if (isNullOrUndefined(fUrl))
    //     return;

    this.shareEvent(eventTypeId, eventProperties);
    //window.open("mailto:?subject=" + subject + "&body=" + message + " " + fUrl, "_blank");

    if (TAValues.CLIENT_TYPE_ID == TAConstants.Client_Types.IOS) {
      // window.open("mailto:?subject=" + subject + "&body=" + message + " " + fUrl, "_self");

      // logger.log('href', w.location.href);
      // logger.log('hrefToBe', "mailto:?subject=" + subject + "&body=" + message + " " + fUrl);
      w.location.replace("mailto:?subject=" + subject + "&body=" + message);

      // w.location.href = "mailto:?subject=" + subject + "&body=" + message + " " + fUrl, true;
      // logger.log('href', w.location.href);
    } else {
      window.open("mailto:?subject=" + subject + "&body=" + message, "_blank");
    }

    //window.location.href = "mailto:?subject=" + subject + "&body=" + message + " " + url;

    // window.open("mailto:?subject=" + "subject" + "&body=" + "message" + " " + "fUrl", "_blank");
  }

  /**
   *
   * @param {string} message
   * @param {string} url
   * @param {number} eventTypeId
   * @param {string} eventProperties
   */
  async shareSMS(
    itemId: string,
    itemTypeId: string,
    message: string,
    url: string,
    eventTypeId: number,
    eventProperties: string
  ) {
    let w;
    if (TAValues.CLIENT_TYPE_ID == TAConstants.Client_Types.IOS)
      w = window.open("", "_blank");

    const generateShareToken = await to(
      this.tokenSrv.generateShareToken(
        TAValues.UserId,
        itemId,
        itemTypeId,
        "sms"
      )
    );
    if (isNullOrUndefined(generateShareToken.data)) return;
    const tokenId = generateShareToken.data.token;
    const fUrl = this.formatUrl(url, tokenId);
    const bUrl = await to(this.shortifyService.getShortifyLink(fUrl));

    if (isNullOrUndefined(bUrl) || isNullOrUndefined(bUrl.data)) return;

    const urlFinal = bUrl.data;

    this.shareEvent(eventTypeId, eventProperties);
    if (TAValues.CLIENT_TYPE_ID == TAConstants.Client_Types.IOS) {
      // logger.log(`open sms in ios`);
      // window.location.href = `sms:null&body=${this.linkIqos}, ${this.codeOfUser}`;
      //window.open("sms:?&body=" + message + " " + fUrl, "_blank");
      w.location.replace("sms:?&body=" + message + " " + urlFinal);

      //window.location.href = "sms:?&body=" + message + " " + url;
      return;
    }

    window.open("sms:;?body=" + message + " " + urlFinal, "_blank");
    // w.location.replace("sms:;?body=" + message + " " + fUrl);

    //window.location.href = "sms:;?body=" + message + " " + url;
  }

  /**
   * Shares event
   * @param eventTypeId
   * @param eventProperties
   */
  private shareEvent(eventTypeId, eventProperties) {
    const input: IEventInput = {
      userId: TAValues.UserId,
      applicationId: TAValues.APPLICATION_ID,
      gameTypeId: Settings.GAME_TYPE,
      eventTypeId: eventTypeId,
      eventProperties: eventProperties,
      clientTypeId: TAValues.CLIENT_TYPE_ID,
      withNoRewards: false,
    };
    this.eventSrv
      .addEvent(input)
      .then((result) => {
        this.userSrv
          .updateUserStatus()
          .then((result) => logger.log(result))
          .catch((err) => logger.log(err));
      })
      .catch((err) => {
        logger.log(err);
      });
  }
}
