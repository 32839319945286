/**
 * Created by georgefloros on 16/05/2017.
 */

/*
 Generated class for the AppSettings provider.

 See https://angular.io/docs/ts/latest/guide/dependency-injection.html
 for more info on providers and Angular 2 DI.
 */

import { Injectable } from "@angular/core";
import { AppSettingsDTO } from "../../talosApi/models/AppSettingsDTO";
import { AppSettingsApi } from "../../talosApi/api/AppSettingsApi";
import _ from "lodash";
import { APP_CONSTANTS, TAConstants, TAValues } from "../../talosApi/settings";
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import { AppGlobalsService } from "../appGlobals.service";
import Settings = TAConstants.Settings;
import { AppSettingsInitDTO } from "../../talosApi/models/AppSettingsInitDTO";

@Injectable()
export class AppSettingsService {
  appSettings: { [key: string]: AppSettingsDTO } = {};

  constructor(
    private api: AppSettingsApi,
    private appGlobalsService: AppGlobalsService
  ) {}

  get(
    applicationId: string,
    scopeTypes: number
  ): Promise<Array<AppSettingsDTO>> {
    return new Promise((resolve, reject) => {
      this.api
        .getAppSettings(applicationId, scopeTypes)
        .then((response: Array<AppSettingsDTO>) => {
          response.forEach((appSetting) => {
            this.appSettings[appSetting.key] = appSetting;
          });

          try {
            this.appGlobalsService.config = JSON.parse(
              this.getValue(APPLICATION_SETTING_KEYS.IQOS_COMPONENT_CONFIG)
            );
            if (this.appGlobalsService.config) {
              // const materialPaging =
              //   this.appGlobalsService.config[
              //     APPLICATION_SETTING_KEYS.MATERIAL_PAGING
              //   ];
              // const rewardsPaging =
              //   this.appGlobalsService.config[
              //     APPLICATION_SETTING_KEYS.REWARDS_PAGING
              //   ];
              // if (materialPaging && materialPaging != "") {
              //   try {
              //     const paging = Number(materialPaging);
              //     if (paging > 0)
              //       APP_CONSTANTS.DEFAULT_MATERIAL_PAGING_STEP = paging;
              //   } catch (er) {}
              // }
              // if (rewardsPaging && rewardsPaging != "") {
              //   try {
              //     const paging = Number(rewardsPaging);
              //     if (paging > 0) APP_CONSTANTS.DEFAULT_PAGING_STEP = paging;
              //   } catch (er) {}
              // }

              const userIds: Array<string> = this.appGlobalsService.config[
                APPLICATION_SETTING_KEYS.PREVIEW_USER_ID
              ]
                ? this.appGlobalsService.config[
                    APPLICATION_SETTING_KEYS.PREVIEW_USER_ID
                  ].split(",")
                : [];
              this.appGlobalsService.isPreview =
                userIds &&
                userIds.findIndex(
                  (u) => TAValues.UserId.toLowerCase() == u.toLowerCase()
                ) >= 0;
            }
          } catch (err) {
            logger.log(err);
          }
          resolve(response);
        })
        .catch(reject);
    });
  }

  getInit(applicationId: string): Promise<AppSettingsInitDTO> {
    return new Promise((resolve, reject) => {
      this.api
        .getAppSettingsInit(applicationId)
        .then((response: AppSettingsInitDTO) => {
          if (response) {
            if (response.apiKey) Settings.ENCRYPTION_KEY = response.apiKey;
            if (response.apiSecret) Settings.ENCRYPTION_IV = response.apiSecret;
          }
          resolve(response);
        })
        .catch(reject);
    });
  }

  getValue(key): any {
    let appSetting = this.appSettings[key];
    return _.isNil(appSetting) === true ? undefined : appSetting.value;
  }

  // setValue(key: string, value: any) {
  //     this.appSettings[key] = {key: key, value: value};
  // }

  // getValue(key): string {
  //   let appSetting = this.appSettings[key];
  //   return _.isNil(appSetting) === true ? undefined : appSetting.value;
  // }
}
