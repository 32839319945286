import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../../shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { Ng5SliderModule } from "ng5-slider";
import { IqosFooterComponent } from "./iqos-footer.component";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [IqosFooterComponent],
  imports: [
    CommonModule,
    SharedModule,
    Ng5SliderModule,
    TranslateModule.forChild(),
    RouterModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [IqosFooterComponent],
})
export class IqosFooterModule {}
