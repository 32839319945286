import { IPurchaseInput } from "./../../talosApi/api/BrandApi";
import { Injectable } from "@angular/core";
import { BrandApi } from "../../talosApi/api";
import {
  BrandProductItemDTO,
  DetailedBrandProductDTO,
  ItemsList,
  DetailedBrandProductCouponDTO,
} from "../../talosApi/models";
import { ResourcesServices } from "./resources.services";
import * as _ from "lodash";
import { TAConstants, TAValues } from "../../talosApi/settings";
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import PRODUCT_STATUS = TAConstants.PRODUCT_STATUS;
import {
  IInputStore,
  IStoreUser,
  IListUserBrandProductCoupons,
  ProductGroupByDTO,
  PurchaseResponse,
} from "../../talosApi/api/BrandApi";
import { resolve } from "q";
import { isNullOrUndefined } from "util";
import { ICancelCoupon } from "../../talosApi/api/UserApi";
import { HttpHeaders } from "@angular/common/http";
import { CompileMetadataResolver } from "@angular/compiler";
import { AppGlobalsService } from "../appGlobals.service";
import { GroupByDTO } from "../../talosApi/models/GroupByDTO";

@Injectable()
export class BrandsService {
  private brandProductById: Map<string, DetailedBrandProductDTO> = new Map<
    string,
    DetailedBrandProductDTO
  >();

  constructor(
    private brandApi: BrandApi,
    private resourcesSrv: ResourcesServices,
    private appGlobalsSrv: AppGlobalsService
  ) {}

  /**
   *
   * @param userId
   * @param brandProductIds
   * @param rsourceTypeIds
   * @param productTypeId
   */
  getRewards(
    userId: string,
    brandProductIds: Array<string>,
    rsourceTypeIds: Array<number>,
    productTypeId?: string
  ): Promise<Array<IRewardsItems>> {
    return new Promise<Array<IRewardsItems>>((resolve, reject) => {
      const getResources = (
        items: Array<DetailedBrandProductDTO>,
        itemId: string,
        resourcesIds: Array<number>,
        itemTypeId: string | number
      ) => {
        this.resourcesSrv
          .loadItemResources(items, itemId, resourcesIds, itemTypeId)
          .then((itemsWithResources) => {
            resolve(items);
          })
          .catch(() => {
            resolve(items);
          });
      };

      const input = {
        brandProductIds: brandProductIds,
        rangeFrom: 0,
        rangeTo: -1,
        productTypeId: productTypeId,
      };
      this.brandApi
        .getBrandProducts(userId, input)
        .then((items: ItemsList<DetailedBrandProductDTO>) => {
          if (_.isNil(items)) {
            reject(null);
            return;
          }
          getResources(
            items.items,
            "brandProductId",
            rsourceTypeIds,
            ITEM_TYPES.BRAND_PRODUCT
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Asyncs get brabd products by category id
   * @param userId
   * @param categoryId
   * @returns get brabd products by category id
   */
  async asyncGetBrandProductsByCategoryId(
    userId: string,
    brandProductIds: Array<string>,
    categoryIds: Array<number>,
    bundled: boolean = true,
    withDateValidation: boolean,
    includeDetails: boolean,
    statistics: number | boolean,
    resources: boolean,
    metadatas: boolean,
    includeStatistics: boolean,
    matchingLevel: boolean,
    languageIds?: Array<string>,
    orderType: string = null,
    rangeFrom?: number,
    rangeTo?: number,
    transactionLimitTypeIds?: number,
    brandProductTypeId?: string,
    colorCategoryIds?: Array<number>,
    tipologiaCategoryIds?: Array<number>,
    priceTo?: number
  ): Promise<ItemsList<DetailedBrandProductDTO>> {
    return new Promise<ItemsList<DetailedBrandProductDTO>>(
      async (resolve, reject) => {
        // const stats: number | boolean = (statistics && this.appGlobalsSrv.config.includeStatistics ? 4 : false);
        // const includeStats = includeStatistics && this.appGlobalsSrv.config.includeStatistics;
        let input = {
          rangeFrom: rangeFrom ? rangeFrom : 0,
          rangeTo: rangeTo ? rangeTo : -1,
          categoryIds: categoryIds,
          brandProductIds: brandProductIds,
          statistics: statistics,
          includeDetails: includeDetails,
          bundled: bundled,
          // withDateValidation: this.appGlobalsSrv.isPreview
          //   ? null
          //   : withDateValidation,
          withDateValidation: withDateValidation,
          resources: resources,
          languageIds: languageIds,
          metadatas: metadatas,
          orderType: orderType,
          includeStatistics: includeStatistics,
          // matchingLevel: this.appGlobalsSrv.isPreview ? null : matchingLevel,
          matchingLevel: matchingLevel,
          transactionLimitTypeIds: transactionLimitTypeIds,
          // brandProductTypeId: brandProductTypeId,
          responseItemTypeIds: [111, 121, 225],
          brandProductStatusId: this.appGlobalsSrv.isPreview
            ? PRODUCT_STATUS.IN_REVIEW
            : null,
        };
        if (priceTo != undefined) input["priceTo"] = priceTo;
        if (brandProductTypeId)
          input["brandProductTypeId"] = brandProductTypeId;
        if (tipologiaCategoryIds && tipologiaCategoryIds.length > 0)
          input["filter1CategoryIds"] = tipologiaCategoryIds;
        if (colorCategoryIds && colorCategoryIds.length > 0)
          input["filter2CategoryIds"] = colorCategoryIds;

        await this.brandApi
          .asyncGetBrandProducts(userId.toUpperCase(), input)
          .then((items: ItemsList<BrandProductItemDTO>) => {
            if (isNullOrUndefined(items)) {
              reject(null);
            } else {
              if (!items.length) reject(null);
              resolve(items);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  getBrandProductsGeneric(
    userId: string,
    queryParams: any
  ): Promise<Array<DetailedBrandProductDTO>> {
    return new Promise<Array<DetailedBrandProductDTO>>((resolve, reject) => {
      this.brandApi
        .getBrandProductsGeneric(userId, queryParams)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getBrandProductsGroupBy(
    userId: string,
    queryParams: any
  ): Promise<Array<ProductGroupByDTO>> {
    return new Promise<Array<ProductGroupByDTO>>((resolve, reject) => {
      this.brandApi
        .getBrandProductsGroupBy(userId, queryParams)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Asyncs get brabd products by category id
   * @param userId
   * @param categoryId
   * @returns get brabd products by category id
   */
  GetBrandProductsByInput(
    userId: string,
    brandProductIds: Array<string>,
    categoryIds: Array<number>,
    bundled: boolean = true,
    resources?: Array<number>,
    languageIds?: Array<string>,
    orderType: string = null,
    rangeFrom?: number,
    rangeTo?: number,
    matchingLevel?: boolean,
    resourcesNew: boolean = true,
    metadatas: boolean = true,
    includeDetails: boolean = true,
    statistics: boolean = true
  ): Promise<ItemsList<DetailedBrandProductDTO>> {
    return new Promise<ItemsList<DetailedBrandProductDTO>>(
      (resolve, reject) => {
        const includeStatistics = this.appGlobalsSrv.config.includeStatistics;
        this.brandApi
          .asyncGetBrandProducts(userId.toUpperCase(), {
            rangeFrom: rangeFrom ? rangeFrom : 0,
            rangeTo: rangeTo ? rangeTo : -1,
            categoryIds: categoryIds,
            brandProductIds: brandProductIds,
            includeStatistics: includeStatistics,
            includeDetails: includeDetails,
            bundled: bundled,
            resources: resourcesNew,
            languageIds: languageIds,
            metadatas: metadatas,
            orderType: orderType,
            matchingLevel: matchingLevel,
            statistics: statistics,
          })
          .then((items: ItemsList<BrandProductItemDTO>) => {
            if (isNullOrUndefined(items)) {
              reject(null);
            } else {
              resolve(items);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  /**
   *
   * @param userId
   * @param categoryId
   */
  getBrandProductsByCategoryId(
    userId: string,
    categoryId: number,
    bundled: boolean = true,
    resources?: Array<number>,
    languageIds?: Array<string>
  ): Promise<ItemsList<DetailedBrandProductDTO>> {
    return new Promise<ItemsList<DetailedBrandProductDTO>>(
      (resolve, reject) => {
        const input = {
          rangeFrom: 0,
          rangeTo: -1,

          categoryIds: [categoryId],
          includeStatistics: true,
          includeDetails: true,
          bundled: bundled,
          resources: resources,
          languageIds: languageIds,
        };

        this.brandApi
          .getBrandProducts(userId.toUpperCase(), input)
          .then((items: ItemsList<BrandProductItemDTO>) => {
            if (_.isNil(items)) {
              reject(null);
              return;
            }
            resolve(items);
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  }

  /**
   *
   * @param input_
   */
  getTheBrandStoreOfuser(
    input_: IInputStore
  ): Promise<ItemsList<IStoreUser[]>> {
    return new Promise((resolve, reject) => {
      this.brandApi
        .getTheStoresUser(input_)
        .then((userBrandStore: ItemsList<IStoreUser[]>) => {
          if (userBrandStore.count === 0) {
            reject(null);
          }
          resolve(userBrandStore);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  purchaseDealCoupon(
    userId: string,
    input_: IPurchaseInput
  ): Promise<PurchaseResponse> {
    return new Promise((resolve, reject) => {
      this.brandApi
        .PurchaseCoupon(userId, true, null, input_)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getUserBrandProductCoupons(
    userId: string,
    input_: IListUserBrandProductCoupons,
    headers_: HttpHeaders = null
  ): Promise<Array<DetailedBrandProductCouponDTO>> {
    return new Promise((resolve, reject) => {
      this.brandApi
        .getUserBrandProductCoupons(userId, true, headers_, input_)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getUserBrandProductGroupByCoupons(
    userId: string,
    input_: IListUserBrandProductCoupons,
    headers_: HttpHeaders = null
  ): Promise<Array<GroupByDTO>> {
    return new Promise((resolve, reject) => {
      this.brandApi
        .getUserBrandProductGroupByCoupons(userId, true, headers_, input_)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getBrandProducts(
    userId: string,
    brandProductIds: Array<string>,
    categoryIds?: Array<number>,
    bundled: boolean = true,
    resources?: Array<number>,
    languageIds?: Array<string>,
    orderType?: string,
    metadata?: boolean | Array<number>,
    brandProductTypeId?: string
  ): Promise<ItemsList<DetailedBrandProductDTO>> {
    return new Promise(async (resolve, reject) => {
      await this.brandApi
        .getBrandProducts(
          userId.toUpperCase(),
          {
            rangeFrom: 0,
            rangeTo: -1,
            brandProductIds: brandProductIds,
            includeStatistics: true,
            includeDetails: true,
            categoryIds: categoryIds,
            bundled: bundled,
            resources: resources,
            languageIds: languageIds,
            orderType: orderType,
            metadatas: metadata,
            brandProductTypeId: brandProductTypeId,
          },
          true
        )
        .then((items: ItemsList<BrandProductItemDTO>) => {
          if (_.isNil(items)) reject(null);
          resolve(items);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getBrandProductsBenefit(
    userId: string,
    brandProductIds: Array<string>,
    rangeFrom: number,
    rangeTo: number,
    resourceIds: Array<number>,
    requestResources: boolean = true,
    force: boolean = false
  ): Promise<Array<DetailedBrandProductDTO>> {
    return new Promise((resolve, reject) => {
      let requiresApiCall = false;
      let storedBrandProduct = new Array<DetailedBrandProductDTO>();
      if (brandProductIds) {
        brandProductIds.forEach((id: string) => {
          const item = this.brandProductById[id];
          if (item) {
            storedBrandProduct.push(item);
          } else {
            requiresApiCall = true;
          }
        });
      } else {
        requiresApiCall = true;
      }

      if (requiresApiCall == false && force == false) {
        resolve(storedBrandProduct);
        return;
      }

      this.brandApi
        .getBrandProducts(
          userId.toUpperCase(),
          {
            rangeFrom: rangeFrom,
            rangeTo: rangeTo,
            brandProductIds: brandProductIds,
            includeStatistics: true,
            includeDetails: true,
            metadatas: true,
          },
          true
        )
        .then((response: any) => {
          if (!response || response.length == 0) {
            resolve([]);
            return;
          }

          if (requestResources == false) {
            resolve(response);
            return;
          }

          response.forEach((brandProduct) => {
            this.brandProductById[brandProduct.itemIdForResourceLookup] =
              brandProduct;
          });

          this.resourcesSrv
            .getResources(
              response,
              "itemIdForResourceLookup",
              resourceIds,
              "itemTypeIdForResourceLookup"
            )
            .then((next) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
          // if (_.isNil(items)) reject(null);
          // resolve(items);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param {string} userId
   * @param {string} couponId
   * @param {string} friendId
   * @return {Promise<any>}
   */
  public giftCoupon(
    userId: string,
    couponId: string,
    friendId: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.brandApi
        .giftCoupon(userId, couponId, friendId)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param input
   * @param userId
   */
  public cancelCouponUser(input: ICancelCoupon, userId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.brandApi
        .cancelCoupon(input, userId)
        .then((cancelCoupon_) => {
          resolve(cancelCoupon_);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public getCouponQrCode(
    userId: string,
    couponCode: string,
    requireSession: boolean = true,
    headers: HttpHeaders = null
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.brandApi
        .getCouponQrCode(userId, couponCode, requireSession, headers)
        .then((cancelCoupon_) => {
          resolve(cancelCoupon_);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
} // END CLASS

export interface IRewardsItems extends DetailedBrandProductDTO {
  image?: string;
  name?: string;
  description?: string;
}
