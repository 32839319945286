export const environment = {
  production: false,
  URL: "https://staging.mystartapp.org/talos_api/v3",
  URL_V1: "https://staging.mystartapp.org/talos_api/v1",
  INTEGRATION_SERVER:
    "https://integrationservice2staging.mystartapp.org/talos-integration/api/v1",
  ADMIN_USERNAME: "lead_client_it",
  BASE_URL: "https://stagingboomi.mystartapp.org",
  GAME_TYPE_ID: "A7B385DC-C5A7-4312-D7A3-F1AB91B4B785",
  USERGROUP_ID: "CAF4F6D9-B6A7-41D3-8708-B3152D80D6E1",
  RESOURCE_SERVER: "https://staging.mystartapp.org/talos_api/resource",
  APPLICATION_ID_PORTAL: "40792BC1-A6BB-4257-85D2-4A778091127A",
  APPLICATION_ID_IOS: "30DB544E-7FDE-4F79-9AA3-D20BD542079A",
  APPLICATION_ID_ANDROID: "D386C28E-8469-4570-92CD-C08DE3822B20",
  APPLICATION_ID_REFERRAL: "9B950EEB-2FC9-4512-FE5D-AFE967FB293A",
  APPLICATION_ID_PWA: "32442EA2-4FF1-4BC8-8B61-5BABFF578339",
  APPLICATION_MAGIC_IOS: "pmiiqositalyburnmuyzmc33ntmzluiwodktnde5xxxx",
  APPLICATION_MAGIC_ANDROID: "pmiiqositalyburnmuyzmc33ntmzluiwodktnde5xxxx",
  APPLICATION_MAGIC_PORTAL: "pmiiqositalyburnmuyzmc33ntmzluiwodktnde5xxxx",
  APPLICATION_MAGIC_PWA: "pmiiqositaly77uyzmc33ntmzluiwodktnde5pwa",
  DEFAULT_PASSWORD: "",
  PASSWORD_MIN_LENGTH: "",
  DEFAULT_ISO_CODE: "",
  DEFAULT_LANG_ID: "",
  APPLICATION_VERSION: "",
  VERSION_ID: "0.1v",
  COUPON_REDIRECTION_LINK:
    "https://staging.iqos.it/it/checkout-cart?coupon={couponCode}",
  INACTIVE_REDIRECTION_LINK: "https://preprod.iqos.it/",
  FIND_STORE_LINK: "https://it.iqos.com/store/it/search",
  COUPON_IQOS_REDIR_LINK: "https://preprod.iqos.it/it/carrello?coupon=$code",
  COUPON_IQOS_STORE_REDIR_LINK:
    "https://preprod.iqos.it/it?dispatch=auth.ekey_login&ekey=$authKey&redirect_url=norfi:$encode",
  CART_MAP_LINK: "https://iqos-map-stag.d3inyt16c4mcj0.amplifyapp.com/",
  STORES_MAP_LINK: "https://club_it_iqos_com.yextpages.net/it/search/",
  CONFIGURATION_LINK:
    "https://club-configuratore-test.iqositalia.it/configurator",
  CONFIGURATION_ORIGIN: "https://club-configuratore-test.iqositalia.it",
  firebase: {
    apiKey: "AIzaSyA9yP-CCFUy5JWgO5wkfwvjahL3YhKMQ7w",
    authDomain: "test-app-fbe76.firebaseapp.com",
    databaseURL: "https://test-app-fbe76.firebaseio.com",
    projectId: "test-app-fbe76",
    storageBucket: "test-app-fbe76.appspot.com",
    messagingSenderId: "1053936630497",
  },

  SSO_ENCRYPTION_KEY: "2b7e151628aed2a6abf71589",
  SSO_ENCRYPTION_IV: "IQOS_CLUB_NM_OTP",
};

export const LOGGER = {
  loggerLevel: 1,
};
