import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserService } from "src/services/talos/user.service";

@Component({
  selector: "app-profile-progress",
  templateUrl: "./profile-progress.component.html",
  styleUrls: ["./profile-progress.component.scss"],
})
export class ProfileProgressComponent implements OnInit, OnDestroy {
  @Input() progress: number = 0;
  totalProgress: number = 100;
  @Output() clicked = new EventEmitter<void>();

  destroy: Subject<void> = new Subject();

  constructor(private router: Router, private userSrv: UserService) {}

  ngOnInit() {
    this.userSrv.profileProgressCounts$
      .pipe(
        // complete when component is destroyed
        takeUntil(this.destroy)
      )
      .subscribe((value: number) => {
        this.progress = value;
        let width = this.progress * 25;
        document.getElementById("progress").style.width = width + "%";
      });
  }

  clickAction() {
    if (this.clicked) this.clicked.emit();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
