import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../../shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { Ng5SliderModule } from "ng5-slider";
import { LoginWeeklyComponent } from "./login-weekly.component";

@NgModule({
  declarations: [LoginWeeklyComponent],
  imports: [
    CommonModule,
    SharedModule,
    Ng5SliderModule,
    TranslateModule.forChild(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [LoginWeeklyComponent],
})
export class LoginWeeklyModule {}
