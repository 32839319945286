import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LoggerService } from "../services/logger/logger.service";
import { ConsoleLoggerService } from "src/services/logger/consolelogger.service";
import { AuthService } from "../services/auth.service";
import { AuthGuard } from "./auth.guard";
import { TalosApiModule } from "src/talosApi";
import { AppSettingsService } from "../services/talos/appSettings.service";
import { CategoryService } from "../services/talos/category.service";
import { ResourcesServices } from "../services/talos/resources.services";
import { MaterialService } from "./pages/material/material.service";
import { UploadContentsService } from "../services/talos/uploadContents.service";
import { UserService } from "../services/talos/user.service";
import { InitService } from "../services/talos/init.service";
import { UtilsService } from "../services/utils.service";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { PmiServices } from "../services/talos/pmi.services";
import { AppGlobalsService } from "../services/appGlobals.service";
import { QuizzesService } from "../services/talos/quizzes.service";
import { ProfileService } from "./pages/profile/profile.service";
import { MessagesService } from "../services/talos/messages.service";
import { RewardsService } from "./pages/rewards/rewards.service";
import { ArticleDetailService } from "./pages/article-detail/article-detail.service";
import { SharedModule } from "./shared/shared.module";
import { FormsModule } from "@angular/forms";
import { FavoriteService } from "../services/talos/favorite.service";
import { MaterialObject } from "../models/MaterialObject";
import { QuizDetailService } from "./pages/quizzes/quiz-detail/quiz-detail.service";
import { ItemService } from "../services/talos/item.service";
import { QuizService } from "./pages/quizzes/quiz/quiz.service";
import { BrowserModule } from "@angular/platform-browser";
import { EventService } from "../services/talos/event.service";
// import {TranslateService} from "../services/translate.service";
// import {TranslatePipe} from "../pipes/translate.pipe";
import { AsyncCategoryService } from "../services/asyncTalos/async.category.service";
import { BrandsService } from "../services/talos/brands.service";
import { ReferenceService } from "../services/talos/reference.service";
import { OverviewService } from "./pages/overview/overview.service";
import { SocialSharingService } from "../services/socialSharing.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Ng5SliderModule } from "ng5-slider";
import { LongPressDirective } from "./directives/long-press.directive";
import { environment } from "../environments/environment";
import { AngularFireModule } from "angularfire2";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { AngularFireAuthModule } from "angularfire2/auth";
import { FirebaseService } from "../services/firebase.service";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule, MatExpansionModule, MatInputModule, MatStepperModule } from "@angular/material";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { InviteService } from "./pages/invite/invite.service";
import { IqosMasterService } from "./pages/iqos-master/iqos-master.service";
import { TokenService } from "src/services/talos/token.service";
import { TestimonialDetailService } from "./pages/testimonial-detail/testimonial-detail.service";
import { SharedApiModule } from "../sharedApi/sharedApi.module";
import { ShortifyService } from "../services/shared/shortify.service";
import { UniqueCodeService } from "./pages/unique-code/unique-code.service";
import { NotificationsService } from "./pages/notifications/notifications.service";
import { GalleryService } from "./pages/gallery/gallery.service";
import { IqosFooterModule } from "./components/iqos-footer/iqos-footer.module";
import { QrSurveyService } from "./pages/qr-survey/qr-survey.service";
import { LocationService } from "../services/location.service";
import { QuizViewModule } from "./shared/components/quiz-view/quiz-view.module";
import { CountdownTimerModule } from "./shared/components/countdown-timer/countdown-timer.module";
import { AchievementService } from "../services/talos/achievement.service";
import { CheckInService } from "../services/talos/checkIn.service";
import { SlugService } from "src/services/shared/slug.service";
import { ReferralService } from "../services/talos/referral.service";
import { DonateComponent } from "./pages/donate/donate.component";
import { DonateService } from "./pages/donate/donate.service";
import { FeedbackContainerModule } from "./components/feedback-container/feedback-container.module";
import { FeedbackContainerService } from "./components/feedback-container/feedback-container.service";
import { InAppMessagesService } from "../services/talos/inAppMessages.service";
import { MatRadioModule } from "@angular/material/radio";
import { GoBackService } from "src/services/shared/goBack.service";
import { MotivationComponent } from "./pages/motivation/motivation.component";
import { IndexedDataService } from "../services/talos/indexedData.service";
import { RecommendationsService } from "../services/talos/recommendations.service";
import { InvitePageService } from "./pages/invite-page/invite-page.service";
import { IntegrationApiModule } from "../integrationApi/integrationApi.module";
import { GuadagnaPuntiService } from "./pages/quadagna-punti/guadagna-punti.service";
import { PDFService } from "src/services/pdf.service";
import { ProfileNewService } from "./pages/profile-new/profile-new.service";
import { IndirectDataService } from "src/services/talos/indirectData.service";
import { AlertService } from "src/services/talos/alert.service";
import { UserV2Service } from "src/services/talos/userV2.service";
import { OtpService } from "src/services/otp.service";
import { ProfileProgressModule } from "./components/profile-progress/profile-progress.module";
import { ProfileProgressComponent } from "./components/profile-progress/profile-progress.component";
import { GameService } from "./pages/games/game/game.service";
import { LoginWeeklyModule } from "./components/login-weekly/login-weekly.module";

export function createTranslateLoader(http: HttpClient) {
  const now = Date.now();
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json?" + now);
}

// export function setupTranslateFactory(
//     service: TranslateService): Function {
//     return () => service.use('en');
// }

@NgModule({
  declarations: [AppComponent, LongPressDirective],
  entryComponents: [ProfileProgressComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    TalosApiModule,
    IntegrationApiModule,
    SharedApiModule,
    SharedModule,
    FormsModule,
    BrowserAnimationsModule,
    Ng5SliderModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    ProfileProgressModule,
    LoginWeeklyModule,
    MatExpansionModule,
    MatButtonModule,
    MatStepperModule,
    IqosFooterModule,
    FeedbackContainerModule,
    QuizViewModule,
    CountdownTimerModule,
    // SkeletonLoaderModule,
    NgMultiSelectDropDownModule.forRoot(),
    // TranslateModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AngularFireModule.initializeApp(
      environment.firebase,
      "angular-auth-firebase"
    ),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: LoggerService, useClass: ConsoleLoggerService },
    AuthService,
    AuthGuard,
    AppSettingsService,
    AsyncCategoryService,
    BrandsService,
    CategoryService,
    ResourcesServices,
    MaterialService,
    UploadContentsService,
    ProfileNewService,
    UserService,
    UserV2Service,
    InitService,
    UtilsService,
    PmiServices,
    AppGlobalsService,
    QuizzesService,
    ProfileService,
    MessagesService,
    RewardsService,
    ArticleDetailService,
    TestimonialDetailService,
    QuizDetailService,
    GameService,
    FavoriteService,
    ItemService,
    MaterialObject,
    QuizService,
    EventService,
    ReferenceService,
    OverviewService,
    SocialSharingService,
    FirebaseService,
    InviteService,
    InvitePageService,
    IqosMasterService,
    TokenService,
    ShortifyService,
    GoBackService,
    UniqueCodeService,
    NotificationsService,
    GalleryService,
    ReferenceService,
    QrSurveyService,
    LocationService,
    AchievementService,
    CheckInService,
    SlugService,
    ReferralService,
    DonateService,
    FeedbackContainerService,
    InAppMessagesService,
    IndexedDataService,
    IndirectDataService,
    RecommendationsService,
    PDFService,
    AlertService,
    OtpService,
    // TranslateService,
    // {
    //     provide: APP_INITIALIZER,
    //     useFactory: setupTranslateFactory,
    //     deps: [ TranslateService ],
    //     multi: true
    // }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(logger: LoggerService) {}
}
