import { Injectable } from "@angular/core";
import * as firebase from "firebase/app";
import { Observable } from "rxjs/index";
import { Router } from "@angular/router";
import { AngularFireAuth } from "angularfire2/auth";
import { EventService } from "./talos/event.service";
import { IEventInput } from "../talosApi/api/EventApi";
import { TAConstants, TAValues } from "../talosApi/settings";
import Settings = TAConstants.Settings;
import { UserService } from "./talos/user.service";

@Injectable()
export class FirebaseService {
  // private user: Observable<firebase.User>;
  // private userDetails: firebase.User = null;
  // constructor(private _firebaseAuth: AngularFireAuth, private eventSrv: EventService, private userSrv: UserService) {
  //     this.user = _firebaseAuth.authState;
  //     this.user.subscribe(
  //         (user) => {
  //             if (user) {
  //                 this.userDetails = user;
  //                 logger.log(this.userDetails);
  //             }
  //             else {
  //                 this.userDetails = null;
  //             }
  //         }
  //     );
  // }
  // signInWithFacebook() {
  //     return this._firebaseAuth.auth.signInWithPopup(
  //         new firebase.auth.FacebookAuthProvider()
  //     )
  // }
  // signInWithTwitter() {
  //     return this._firebaseAuth.auth.signInWithPopup(
  //         new firebase.auth.TwitterAuthProvider()
  //     )
  // }
  // registeredEvent(eventTypeId, eventProperties) {
  //     const input: IEventInput = {
  //         userId: TAValues.UserId,
  //         applicationId: TAValues.APPLICATION_ID,
  //         gameTypeId: Settings.GAME_TYPE,
  //         eventTypeId: eventTypeId,
  //         eventProperties: eventProperties,
  //         clientTypeId: TAValues.CLIENT_TYPE_ID,
  //         withNoRewards: false
  //     };
  //     this.eventSrv.addEvent(input).then(result => {
  //         this.userSrv.updateUserStatus().then(result => logger.log(result)).catch(err => logger.log(err));
  //     }).catch(err => {
  //         logger.log(err);
  //     })
  // }
}
