import { Injectable } from "@angular/core";
import { TAConstants } from "../../../../talosApi/settings";
import { QuizDTO } from "../../../../talosApi/models/QuizDTO";
import { QuizzesService } from "../../../../services/talos/quizzes.service";
import Settings = TAConstants.Settings;
import ORDER_TYPE = TAConstants.ORDER_TYPE;
import { ItemService } from "src/services/talos/item.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventService } from "src/services/talos/event.service";
import { IEventInput } from "src/talosApi/api/EventApi";
// import * as io from "socket.io-client";

@Injectable()
export class GameService {
  quizAnswered: boolean = false;

  // socket = io("https://webhook-test.com/9ce624a84b0aab961a80fd355dbc231d");
  constructor(
    private quizzesSrv: QuizzesService,
    private itemSrv: ItemService,
    private eventSrv: EventService,
    private http: HttpClient
  ) {
    // this.socket.client();
  }

  getQuizById(quizId: string, matchingLevel?: boolean): Promise<QuizDTO> {
    return new Promise((resolve, reject) => {
      this.quizzesSrv
        .listQuiz(
          [quizId],
          null,
          null,
          Settings.GAME_TYPE,
          0,
          1,
          false,
          null,
          false,
          matchingLevel,
          true
        )
        .then((result: Array<QuizDTO>) => {
          if (result && result.length > 0) {
            resolve(result[0]);
            return;
          }

          resolve(null);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getRelatedItems(
    quizTypeId: number,
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<QuizDTO>> {
    return new Promise((resolve, reject) => {
      this.quizzesSrv
        .listQuiz(
          null,
          quizTypeId,
          null,
          Settings.GAME_TYPE,
          rangeFrom,
          rangeTo,
          false,
          ORDER_TYPE.BY_RECENT,
          false,
          true
        )
        .then((result: Array<QuizDTO>) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  sendGameEvent(
    itemId: string,
    itemTypeId: string,
    input: any,
    headers: HttpHeaders = null
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.itemSrv
        .submitStatistics(
          itemId,
          Number(itemTypeId),
          TAConstants.STATISTIC_TYPES.GAME_FINISHED,
          1,
          input,
          true,
          headers
        )
        .then((result: any) => {
          resolve("SUCCESS");
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  startGame(input: IEventInput) {
    return new Promise((resolve, reject) => {
      this.eventSrv
        .recordEvent(input)
        .then((result: any) => {
          resolve("SUCCESS");
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  startListeningGame() {
    // this.socket.on("new-message", (data) => {
    //   logger.log(data);
    // });
    // this.connectSocket("message");
    // this.receiveStatus().subscribe((receivedMessage: string) => {
    //   console.log(receivedMessage);
    // });
    // const socket = new WebSocket(
    //   "https://webhook-test.com/9ce624a84b0aab961a80fd355dbc231d"
    // );
    // socket.onopen = () => {
    //   console.log("WebSocket connection established.");
    // };
    // socket.onmessage = (event) => {
    //   console.log("Received message:", event.data);
    // };
    // socket.onclose = (event) => {
    //   console.log("WebSocket connection closed:", event);
    // };
    // socket.onerror = (error) => {
    //   console.error("WebSocket error:", error);
    // };
    // const options = {
    //   body: {},
    //   headers: null,
    //   params: {},
    //   observe: "response" as "body" | "events" | "response",
    //   responseType: "json" as "arraybuffer" | "blob" | "json" | "text",
    //   reportProgress: true,
    // };
    // this.http
    //   // .request(
    //   //   "POST",
    //   //   "https://newman-api.getpostman.com/run/1559645/07cec10b-6ae7-4393-9c99-425e77435447",
    //   //   options
    //   // )
    //   .request(
    //     "POST",
    //     "https://webhook-test.com/9ce624a84b0aab961a80fd355dbc231d",
    //     options
    //   )
    //   .subscribe(
    //     (response) => {
    //       logger.log(response);
    //       try {
    //         // let js = JSON.parse(response.body);
    //         // resolve(js);
    //       } catch (e) {
    //         //TODO string
    //         // resolve(response.body);
    //       }
    //     },
    //     async (error) => {
    //       logger.err(error);
    //       // reject(error || 'Server error');
    //     }
    //   );
  }

  // connectSocket(message) {
  //   this.webSocket.emit("connect", message);
  // }

  // // this method is used to get response from server
  // receiveStatus() {
  //   return this.webSocket.fromEvent("/get-response");
  // }

  // // this method is used to end web socket connection
  // disconnectSocket() {
  //   this.webSocket.disconnect();
  // }
}
