import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { UserService } from "src/services/talos/user.service";

@Component({
  selector: "app-login-weekly",
  templateUrl: "./login-weekly.component.html",
  styleUrls: ["./login-weekly.component.scss"],
})
export class LoginWeeklyComponent implements OnInit {
  @Input() data: any;
  active: boolean;
  constructor(
    public translate: TranslateService,
    private userSrv: UserService
  ) {}

  ngOnInit() {
    const userId = this.userSrv.user.user.userId;
    const closedDate = localStorage.getItem(`closedDate_${userId}`);

    if (closedDate) {
      const isSameWeek = moment(closedDate).isSame(new Date(), "week");
      if (!isSameWeek) {
        this.active = true;
      } else {
        this.active = false;
      }
    } else {
      this.active = true;
    }
  }

  closeModal() {
    const userId = this.userSrv.user.user.userId;
    localStorage.setItem(`closedDate_${userId}`, moment().format("YYYY-MM-DD"));
    this.active = false;
  }
}
