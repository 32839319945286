import { Injectable } from "@angular/core";
import {
  EventApi,
  IEvaluateEventQuery,
  IEventInput,
} from "../../talosApi/api/EventApi";
import { EventRewardRulesDTO } from "../../talosApi/models/EventRewardRulesDTO";
import { TAConstants } from "../../talosApi/settings";
import Resource_Types = TAConstants.Resource_Types;
import { ResourcesServices } from "./resources.services";
import { UserEventCounterDTO } from "../../talosApi/models/UserEventCounterDTO";
import { EventItemRewardsDTO } from "../../talosApi/models/EventItemRewardsDTO";
import { HttpHeaders } from "@angular/common/http";

@Injectable()
export class EventService {
  rewardRuleByEventId: Map<number, EventRewardRulesDTO> = new Map<
    number,
    EventRewardRulesDTO
  >();

  constructor(
    private eventApi: EventApi,
    private resourcesSrv: ResourcesServices
  ) {}

  /**
   * Add event
   * @param {IEventInput} input
   * @param {boolean} requireSession
   * @return {Promise<any>}
   */
  public addEvent(
    input: IEventInput,
    requireSession: boolean = true,
    isV1: boolean = false
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.eventApi
        .addEvent(input, requireSession, isV1)
        .then((eventRes) => {
          resolve(eventRes);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get Event Stats
   * @param {string} userId
   * @param {Array<number>} eventTypeIds
   * @param {number} counterType
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @return {Promise<Array<UserEventCounterDTO>>}
   */
  public getEventStats(
    userId: string,
    eventTypeIds: Array<number>,
    counterType: number,
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<UserEventCounterDTO>> {
    return new Promise<any>((resolve, reject) => {
      this.eventApi
        .getEventStats(userId, eventTypeIds, counterType, rangeFrom, rangeTo)
        .then((eventRes) => {
          resolve(eventRes);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get Event Reward Rules
   * @param {string} gameTypeId
   * @param {Array<number>} eventTypeIds
   * @param resources
   * @param {Array<string>} languageIds
   * @param {Array<string>} metadatas
   * @param {Array<string>} statistics
   * @return {Promise<Array<EventRewardRulesDTO>>}
   */
  public getEventRewardRules(
    gameTypeId: string,
    eventTypeIds: Array<number>,
    resources?: any,
    languageIds?: Array<string>,
    metadatas?: Array<string>,
    statistics?: Array<string>
  ): Promise<Array<EventRewardRulesDTO>> {
    return new Promise<any>((resolve, reject) => {
      let requiresApiCall = false;
      let storedEventRewardRules = new Array<EventRewardRulesDTO>();
      if (eventTypeIds) {
        eventTypeIds.forEach((id: number) => {
          const item = this.rewardRuleByEventId[id];
          if (item) {
            storedEventRewardRules.push(item);
          } else {
            requiresApiCall = true;
          }
        });
      } else {
        requiresApiCall = true;
      }

      if (requiresApiCall == false) {
        resolve(storedEventRewardRules);
        return;
      }

      this.eventApi
        .getEventRewardRules(
          gameTypeId,
          eventTypeIds,
          resources,
          languageIds,
          metadatas,
          statistics
        )
        .then((eventRes) => {
          if (eventRes) {
            eventRes.forEach((e) => {
              let testString =
                e.rewards && e.rewards.length > 0
                  ? this.resourcesSrv.getResourcesBasic(
                      e.rewards[0],
                      Resource_Types.NAME
                    )
                  : "";
              if (testString == "") {
                testString =
                  e.rewards && e.rewards.length > 1
                    ? this.resourcesSrv.getResourcesBasic(
                        e.rewards[1],
                        Resource_Types.NAME
                      )
                    : "";
                if (testString != "") {
                  this.rewardRuleByEventId[e.eventTypeId] = e;
                }
              } else {
                this.rewardRuleByEventId[e.eventTypeId] = e;
              }

              // this.rewardRuleByEventId[e.eventTypeId] = e;
            });
          }
          resolve(eventRes);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Evaluate Event
   * @param {IEvaluateEventQuery} query
   * @param {IEventInput} input
   * @return {Promise<<Array<EventItemRewardsDTO>>}
   */
  public evaluateEvent(
    query?: IEvaluateEventQuery,
    input?: IEventInput
  ): Promise<Array<EventItemRewardsDTO>> {
    return new Promise<Array<EventItemRewardsDTO>>((resolve, reject) => {
      this.eventApi
        .evaluateEvent(query, input)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public recordEvent(
    input: IEventInput,
    requireSession: boolean = true,
    headers: HttpHeaders = null,
    isV1: boolean = false
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.eventApi
        .recordEvent(input, requireSession, headers, isV1)
        .then((eventRes) => {
          resolve(eventRes);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
} // END CLASS
