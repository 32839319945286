import { Http2Talos } from "../http2Talos";
import { Injectable } from "@angular/core";
import { QuizDTO } from "../models/QuizDTO";
import { StartQuizInput } from "../models/StartQuizInput";
import { AnswerQuestionsInput } from "../models/AnswerQuestionsInput";
import { UserAnswerDTO } from "../models/UserAnswerDTO";
import { QuizStatusDTO } from "../models/QuizStatusDTO";
import { AnswerQuestionResponse } from "../models/AnswerQuestionResponse";
import { TAConstants, TAValues } from "../settings";

@Injectable()
export class QuizApi {
  constructor(private http2Talos: Http2Talos) {}

  /**
   * List the available quiz
   * Multiple Quiz ID values can be provided with strings separated by newline
   * @param quizIds Query quiz by quizIds.
   * @param quizTypeId Query quiz type Id
   * @param quizCategoryId Quiz Category Id
   * @param name Query quiz by name.
   * @param currentlyActive Query for currently Active quiz.
   * @param quizTypeId Query quiz by quizTypeId.
   * @param optional Query for optional quiz.
   * @param repeatable Query for repeatable quiz.
   * @param uic Query quiz by uic.
   * @param active Query for selected quiz.
   * @param quizCategoryId Query quiz by quizCategoryId.
   * @param quizLevelId Query quiz by quizLevelId.
   * @param includeQuestions Query quiz and include their questions.
   * @param gameTypeId The Game Type
   * @param rangeFrom Query quiz from.
   * @param rangeTo Query quiz up to.
   * @param xTalosFillResources Inline resources and/or metadata
   */
  listQuiz(
    quizIds?: Array<string>,
    quizTypeId?: number,
    quizCategoryId?: string,
    currentlyActive?: boolean,
    active?: boolean,
    includeQuestions?: boolean,
    gameTypeId?: string,
    rangeFrom?: number,
    rangeTo?: number,
    orderType?: string,
    resources: boolean = true,
    matchingLevel: boolean = true,
    statistics: boolean = null
  ): Promise<Array<QuizDTO>> {
    return this.http2Talos.get({
      path: `/quiz/listQuiz`,
      queryParams: {
        quizIds: quizIds,
        quizTypeId: quizTypeId,
        quizCategoryId: quizCategoryId,
        currentlyActive: currentlyActive,
        active: active,
        includeQuestions: includeQuestions,
        gameTypeId: gameTypeId,
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
        orderType: orderType,
        metadatas: resources,
        resources: resources,
        languageIds: TAConstants.Settings.LANGUAGES,
        matchingLevel: matchingLevel,
        statistics: statistics,
        userId: TAValues.UserId,
      },
    });
  }

  /**
   * List the available quiz
   * Multiple Quiz ID values can be provided with strings separated by newline
   *
   * @param {ListQuizQuery} query
   * @return {Promise<Array<QuizDTO>>}
   */
  listQuizNew(query: ListQuizQuery): Promise<Array<QuizDTO>> {
    return this.http2Talos.get({
      path: `/quiz/listQuiz`,
      queryParams: query,
    });
  }

  /**
   * Start quiz
   *
   * @param input
   */
  startQuiz(input?: StartQuizInput): Promise<any> {
    return this.http2Talos.post({
      path: `/quiz/startQuiz`,
      input: input,
      requireSession: true,
    });
  }

  /**
   * Answer a question
   *
   * @param input
   */
  answerQuestion(
    input?: AnswerQuestionsInput
  ): Promise<AnswerQuestionResponse> {
    return this.http2Talos.post({
      path: `/quiz/answerQuestion`,
      input: input,
      requireSession: true,
    });
  }

  /**
   * Get user quiz status
   * @param {string} userId
   * @param {Array<string>} quizIds
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @return {Promise<Array<QuizStatusDTO>>}
   */
  userQuizStatus(
    userId?: string,
    quizIds?: Array<string>,
    gameTypeId?: string,
    quizStatusId?: number,
    rangeFrom?: number,
    rangeTo?: number
  ): Promise<any> {
    return this.http2Talos.get({
      path: `/quiz/userQuizStatus`,
      queryParams: {
        userId: userId,
        quizIds: quizIds,
        gameTypeId: gameTypeId,
        quizStatusId: quizStatusId,
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
      },
    });
  }

  listUserQuiz(
    userId: String,
    quizIds?: Array<string>,
    quizTypeId?: number,
    quizCategoryId?: string,
    currentlyActive?: boolean,
    active?: boolean,
    includeQuestions?: boolean,
    gameTypeId?: string,
    rangeFrom?: number,
    rangeTo?: number,
    resources?: boolean,
    metadatas?: boolean
  ): Promise<Array<QuizDTO>> {
    return this.http2Talos.get({
      path: `/quiz/${userId}`,
      queryParams: {
        quizIds: quizIds,
        quizTypeId: quizTypeId,
        quizCategoryId: quizCategoryId,
        currentlyActive: currentlyActive,
        active: active,
        includeQuestions: includeQuestions,
        gameTypeId: gameTypeId,
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
        resources: resources,
        metadatas: metadatas,
      },
    });
  }

  listUserQuizNew(query: ListQuizQuery): Promise<Array<QuizDTO>>  {
    return this.http2Talos.get({
      path: `/quiz/${query.userId}`,
      queryParams: query,
    });
  }
}

export interface ListQuizQuery {
  quizIds?: Array<string>;
  quizTypeId?: number;
  quizCategoryId?: string;
  currentlyActive?: boolean;
  active?: boolean;
  includeQuestions?: boolean;
  gameTypeId?: string;
  rangeFrom?: number;
  rangeTo?: number;
  matchingLevel?: boolean;
  userId?: string;
  resources?: boolean;
  metadatas?: boolean;
  languageIds?: Array<string>;
}
