import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  SimpleChanges,
  ComponentFactoryResolver,
} from "@angular/core";
import { random } from "lodash";
import { SkeletonLoaderComponent } from "../shared/components/skeleton-loader/skeleton-loader.component";

@Directive({ selector: "[skeletonLoader]" })
export class SkeletonLoaderDirective {
  @Input("skeletonLoader") isLoading = false;
  @Input("skeletonLoaderRepeat") size = 1;
  @Input("skeletonLoaderWidth") width: string;
  @Input("skeletonLoaderHeight") height: string;
  @Input("skeletonLoaderClassNames") classNames: string;
  @Input("skeletonLoaderShape") shape: "circle" | "line" | "square";

  constructor(
    private tpl: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading) {
      this.vcr.clear();

      if (changes.isLoading.currentValue) {
        Array.from({ length: this.size }).forEach(() => {
          const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(
              SkeletonLoaderComponent
            );
          const ref = this.vcr.createComponent(componentFactory);

          Object.assign(ref.instance, {
            width: this.width === "rand" ? `${random(30, 90)}%` : this.width,
            height: this.height,
            classNames: this.classNames,
            shape: this.shape,
          });
        });
      } else {
        this.vcr.createEmbeddedView(this.tpl);
      }
    }
  }
}
