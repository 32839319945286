import { Injectable } from "@angular/core";
import { DetailedBrandProductCouponDTO } from "../../talosApi/models/DetailedBrandProductCouponDTO";
import { UserApi } from "../../talosApi/api/UserApi";
import { ListQuizQuery, QuizApi } from "../../talosApi/api/QuizApi";
import { QuizDTO } from "../../talosApi/models/QuizDTO";
import { ResourcesServices } from "./resources.services";
import _ from "lodash";
import { TAConstants, TAValues } from "../../talosApi/settings";
import Resource_Types = TAConstants.Resource_Types;
import { ITalosApiModel } from "../../talosApi/models/TalosApiModel.interface";
import { StartQuizInput } from "../../talosApi/models/StartQuizInput";
import { AnswerQuestionInput } from "../../talosApi/models/AnswerQuestionInput";
import { AnswerQuestionsInput } from "../../talosApi/models/AnswerQuestionsInput";
import { UserAnswerDTO } from "../../talosApi/models/UserAnswerDTO";
import { QuizStatusDTO } from "../../talosApi/models/QuizStatusDTO";
import Settings = TAConstants.Settings;
import QUIZ_STATUS = TAConstants.QUIZ_STATUS;
import { AnswerQuestionResponse } from "../../talosApi/models/AnswerQuestionResponse";
import QUIZ_TYPES = TAConstants.QUIZ_TYPES;
import Events_Types = TAConstants.Events_Types;
import Unit_Types = TAConstants.Unit_Types;
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import { GetUserActionStatusInput } from "../../talosApi/models/GetUserActionStatusInput";
import { ItemService } from "./item.service";

@Injectable()
export class QuizzesService {
  quizzesById: { [key: string]: QuizDTO } = {};

  constructor(
    private api: QuizApi,
    private resourcesSrv: ResourcesServices,
    private itemSrv: ItemService
  ) {}

  listQuizNewUserProgression(query: ListQuizQuery): Promise<Array<QuizDTO>> {
    return new Promise((resolve, reject) => {
      this.api
        .listUserQuizNew(query)
        .then((result: Array<QuizDTO>) => {
          if (result && result.length > 0) {
            const qIds = [];
            result.forEach((quiz) => {
              qIds.push(quiz.id);
            });

            this.api
              .userQuizStatus(
                TAValues.UserId,
                qIds,
                Settings.GAME_TYPE,
                null,
                0,
                -1
              )
              .then((res: any) => {
                const quizzes = res || [];
                result.forEach((quiz) => {
                  const status = quizzes.filter((status: QuizStatusDTO) => {
                    return (
                      quiz.id === status.quizId &&
                      status.status != QUIZ_STATUS.STARTED
                    );
                  });

                  quiz.done = status && status.length > 0;
                });

                resolve(result);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get List of quizzes
   * @param {ListQuizQuery} query
   * @return {Promise<Array<QuizDTO>>}
   */
  listQuizNew(
    query: ListQuizQuery,
    onlyCount: boolean
  ): Promise<Array<QuizDTO>> {
    return new Promise((resolve, reject) => {
      this.api
        .listQuizNew(query)
        .then((result: Array<QuizDTO>) => {
          if (result && result.length > 0) {
            const qIds = [];
            if (onlyCount == false) {
              result.forEach((quiz) => {
                // this.quizzesById[quiz.id] = quiz;
                qIds.push(quiz.id);
              });
            }

            this.api
              .userQuizStatus(
                TAValues.UserId,
                qIds,
                Settings.GAME_TYPE,
                null,
                0,
                -1
              )
              .then((res: any) => {
                const quizzes = res || [];
                result.forEach((quiz) => {
                  const status = quizzes.filter((status: QuizStatusDTO) => {
                    return (
                      quiz.id === status.quizId &&
                      status.status != QUIZ_STATUS.STARTED
                    );
                  });

                  quiz.done = status && status.length > 0;
                });

                resolve(result);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get List of quizzes
   * @param {Array<string>} quizIds
   * @param {number} quizTypeId
   * @param {string} quizCategoryId
   * @param {string} gameTypeId
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @param {boolean} onlyCount
   * @return {Promise<Array<QuizDTO>>}
   */
  listQuiz(
    quizIds?: Array<string>,
    quizTypeId?: number,
    quizCategoryId?: string,
    gameTypeId?: string,
    rangeFrom?: number,
    rangeTo?: number,
    onlyCount?: boolean,
    orderType?: string,
    includeQuestions: boolean = true,
    matchingLevel: boolean = true,
    statistics: boolean = null
  ): Promise<Array<QuizDTO>> {
    return new Promise((resolve, reject) => {
      let requiresApiCall = false;
      let storedQuizzes = new Array<QuizDTO>();
      if (quizIds && onlyCount) {
        quizIds.forEach((id: string) => {
          const item = this.quizzesById[id];
          if (item) {
            storedQuizzes.push(item);
          } else {
            requiresApiCall = true;
          }
        });
      } else {
        requiresApiCall = true;
      }

      if (requiresApiCall == false) {
        resolve(storedQuizzes);
        return;
      }

      this.api
        .listQuiz(
          quizIds,
          quizTypeId,
          quizCategoryId,
          true,
          true,
          includeQuestions,
          gameTypeId,
          rangeFrom,
          rangeTo,
          orderType,
          !onlyCount,
          matchingLevel,
          statistics
        )
        .then((result: Array<QuizDTO>) => {
          if (result && result.length > 0) {
            if (onlyCount == true) {
              resolve(result);
              return;
            }

            const qIds = [];
            const quizIds = [];
            const surveyIds = [];
            // if (onlyCount == false) {
            result.forEach((quiz) => {
              // this.quizzesById[quiz.id] = quiz;
              if (quiz.quizType == QUIZ_TYPES.QUIZ) quizIds.push(quiz.id);
              else if (quiz.quizType == QUIZ_TYPES.SURVEY)
                surveyIds.push(quiz.id);
              qIds.push(quiz.id);
            });
            // }

            this.api
              .userQuizStatus(
                TAValues.UserId,
                qIds,
                Settings.GAME_TYPE_ID,
                null,
                0,
                -1
              )
              .then(async (res: any) => {
                if (quizIds && quizIds.length > 0) {
                  const input: GetUserActionStatusInput = {
                    itemIds: quizIds,
                    itemTypeId: ITEM_TYPES.QUIZ,
                    unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
                    includeLimitations: true,
                  };

                  const actions = await this.itemSrv
                    .getUserActions(Events_Types.QUIZ_COMPLETION, null, input)
                    .catch((err) => {});

                  if (actions) {
                    actions.forEach((a) => {
                      result.forEach((i) => {
                        if (
                          a.itemId == i.id &&
                          a.actionsStatus &&
                          a.actionsStatus.length > 0
                        ) {
                          i.actionsStatus = a.actionsStatus[0];
                        }
                      });
                    });
                  }
                }

                if (surveyIds && surveyIds.length > 0) {
                  const input: GetUserActionStatusInput = {
                    itemIds: surveyIds,
                    itemTypeId: ITEM_TYPES.QUIZ,
                    unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
                    includeLimitations: true,
                  };

                  const actions = await this.itemSrv
                    .getUserActions(
                      Events_Types.POLL_SURVEY_COMPLETED,
                      null,
                      input
                    )
                    .catch((err) => {});
                  if (actions) {
                    actions.forEach((a) => {
                      result.forEach((i) => {
                        if (
                          a.itemId == i.id &&
                          a.actionsStatus &&
                          a.actionsStatus.length > 0
                        ) {
                          i.actionsStatus = a.actionsStatus[0];
                        }
                      });
                    });
                  }
                }
                // var itemsArray: Array<ITalosApiModel> = [];
                //
                const quizzes = res || [];
                result.forEach((quiz) => {
                  const status = quizzes.filter((status: QuizStatusDTO) => {
                    return (
                      quiz.id === status.quizId &&
                      status.status != QUIZ_STATUS.STARTED
                    );
                  });

                  quiz.done = status && status.length > 0;
                });
                //
                //     itemsArray.push(quiz);
                //     if (onlyCount == false) {
                //         if (quiz.questions) {
                //             quiz.questions.forEach((question) => {
                //                 itemsArray.push(question);
                //                 if (question.answers) {
                //                     question.answers.forEach((answer) => {
                //                         itemsArray.push(answer);
                //                     });
                //                 }
                //             });
                //         }
                //     }
                // });

                resolve(result);
                return;

                // if (onlyCount == true) {
                //     resolve(result)
                //     return;
                // }

                // this.resourcesSrv.getResources(itemsArray, 'itemIdForResourceLookup', [Resource_Types.NAME, Resource_Types.DESCRIPTION, Resource_Types.TEXT, Resource_Types.USER_QUIZ_RESULTS_TEXTS, Resource_Types.WEB_IMAGE_ARRAY], 'itemTypeIdForResourceLookup')
                //     .then(next => {
                //         resolve(result);
                //     }, error => {
                //         reject(error);
                //     }).catch((error) => {
                //     reject(error);
                // });
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Start a quiz
   * @param {StartQuizInput} input
   * @return {Promise<string>}
   */
  startQuiz(input: StartQuizInput): Promise<string> {
    return new Promise((resolve, reject) => {
      this.api
        .startQuiz(input)
        .then((result) => {
          //result = JSON.parse(this.test);
          if (result) {
            resolve(result);
            return;
          }

          resolve(null);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Answer Quiz
   * @param {AnswerQuestionsInput} input
   * @return {Promise<string>}
   */
  answerQuiz(input: AnswerQuestionsInput): Promise<AnswerQuestionResponse> {
    return new Promise((resolve, reject) => {
      this.api
        .answerQuestion(input)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  listUserQuiz(
    quizIds?: Array<string>,
    quizTypeId?: number,
    quizCategoryId?: string,
    gameTypeId?: string,
    rangeFrom?: number,
    rangeTo?: number
  ): Promise<Array<QuizDTO>> {
    return new Promise((resolve, reject) => {
      this.api
        .listUserQuiz(
          TAValues.UserId,
          quizIds,
          quizTypeId,
          quizCategoryId,
          true,
          true,
          true,
          gameTypeId,
          rangeFrom,
          rangeTo,
          true,
          true
        )
        .then((result: Array<QuizDTO>) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
} // end class
