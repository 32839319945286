import { Component, ElementRef, Input } from "@angular/core";

@Component({
  selector: "skeleton-loader",
  host: {
    class: "shine",
  },
  template: ``,
  styleUrls: ["./skeleton-loader.component.scss"],
})
export class SkeletonLoaderComponent {
  @Input("width") width?: string;
  @Input("height") height?: string;
  @Input("classNames") classNames?: string;
  @Input("shape") shape?: "circle" | "line" | "square";

  constructor(private host: ElementRef<HTMLElement>) {}

  ngOnInit() {
    const host = this.host.nativeElement;
    if (host && host.classList && this.classNames) {
      if (!this.classNames.includes(",")) host.classList.add(this.classNames);
      else
        this.classNames
          .trim()
          .split(",")
          .forEach((className) => host.classList.add(className));
    }
    if (host && host.classList && this.shape) {
      host.classList.add(this.shape);
    }

    host.style.setProperty("--skeleton-loader-width", this.width || "100px");
    host.style.setProperty("--skeleton-loader-height", this.height || "20px");
  }
}
