import { TranslateService } from "@ngx-translate/core";
import { TAConstants, TAValues } from "src/talosApi/settings";
import Resource_Types = TAConstants.Resource_Types;
import METADATA_KEY = TAConstants.METADATA_KEY;
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import Order_Stores = TAConstants.Order_Stores;
import { ResourcesServices } from "src/services/talos/resources.services";
import { UtilsService } from "src/services/utils.service";
import { Injectable } from "@angular/core";
import { FilterESContentDataInput } from "src/talosApi/api/RecommendationsApi";
import { RecommendationsService } from "src/services/talos/recommendations.service";
import { GenericContent } from "../sustainability/sustainability.service";
import { AppGlobalsService } from "src/services/appGlobals.service";
import { GetAchievementsParams } from "src/talosApi/api/AchievementApi";
import { AchievementService } from "src/services/talos/achievement.service";
import { AchievementDTO } from "src/talosApi/models/AchievementDTO";
import {
  Mission,
  ProfileMission,
  ProfileMissionContent,
  ProfileService,
} from "../profile/profile.service";
import { UserService } from "src/services/talos/user.service";
import * as moment from "moment";
import _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class OnBoardingService {
  constructor(
    private translateSrv: TranslateService,
    private resourcesSrv: ResourcesServices,
    private utilsSrv: UtilsService,
    private globals: AppGlobalsService,
    private recommendationsSrv: RecommendationsService,
    private achievemtSrv: AchievementService,
    private profileSrv: ProfileService,
    private userSrv: UserService
  ) {}

  public async getContents(
    categoryIds: Array<string>,
    itemIds?: Array<string>
  ): Promise<Array<GenericContent>> {
    return new Promise<Array<GenericContent>>(async (resolve, reject) => {
      const input: FilterESContentDataInput = {
        itemTypeIds: [ITEM_TYPES.UPLOAD_CONTENT, ITEM_TYPES.QUIZ],
        itemIds: itemIds,
        categoryIds: categoryIds,
        // categoryIds: ['30045'],
        rangeFrom: 0,
        rangeTo: 10000,
        currentlyRewarding: true,
        sortOrderTypes: [Order_Stores.BY_ORDERING_ASC],
        countMode: false,
        userRewarded: null,
        currentlyActive: true,
        matchingRules: true,
        matchingLevel: false,
      };
      const contents = await this.recommendationsSrv
        .getRecommendationContent(TAValues.UserId, input, {
          resources: true,
          languageIds: TAConstants.Settings.LANGUAGES,
          metadatas: true,
        })
        .catch((err) => {});
      if (!contents) {
        resolve(null);
        return;
      }

      contents.forEach((c: GenericContent) => {
        this.setupGenericContent(c);
      });
      resolve(contents);
    });
  }

  private setupGenericContent(item: GenericContent) {
    item.title = this.resourcesSrv.getResourcesBasic(item, Resource_Types.NAME);
    item.description = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.DESCRIPTION
    );
    item.category = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.DETAILS
    );
    item.videoUrl = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.VIDEO_URL
    );
    item.image = this.resourcesSrv.getResourcesBasic(
      item,
      this.globals.isMobile
        ? Resource_Types.IMAGE_FEATURED_WEB
        : Resource_Types.IMAGE
    );
    item.action = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.ACTION_TYPE
    );
    item.imageTitle = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.TEXT
    );
    item.actionLabel = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.ACTION_TYPE_DESCRIPTION
    );
    item.header = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.TITLE
    );
    item.subHeader = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.SUMMARY_TEXT
    );
    item.rewardsImage = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.REWARD_IMAGE
    );
    item.rewardsDescription = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.REWARD_DESCRIPTION
    );
    item.slide1 = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.SLIDE_1
    );
    item.slide2 = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.SLIDE_2
    );
    item.slide3 = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.SLIDE_3
    );
    item.content1 = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.CONTENT_1
    );
    item.content2 = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.CONTENT_2
    );
    item.content3 = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.CONTENT_3
    );

    item.type = Number(
      this.utilsSrv.valueFromKeyValueDTOArray(
        METADATA_KEY.VIEW_MASTER_DISPLAY_TYPE,
        item.metadata
      )
    );
    item.extraClass =
      this.utilsSrv.valueFromKeyValueDTOArray(
        METADATA_KEY.VIEW_MASTER_VERSION,
        item.metadata
      ) == "1"
        ? "manifest"
        : "";
    // item.type = 0;
  }

  getMissionBase(): Promise<ProfileMissionContent> {
    const config =
      this.globals.config[
        TAConstants.APPLICATION_SETTING_KEYS.ONBOARDING_CONFIG
      ];
    if (config && config != "") {
      const achId =
        config[TAConstants.APPLICATION_SETTING_KEYS.ONBOARDING_ACHIEVEMENT_ID];
      // const achId = "15A0CDA9-A69B-4308-A993-E9E5F3FB4D4E";

      if (achId)
        return this.getMission(achId, this.userSrv.user.eligible, null, false);
    }

    return new Promise<ProfileMissionContent>(async (resolve, reject) => {
      resolve(null);
    });
  }

  getMission(
    achId: string,
    isEligible: boolean,
    eligibleCategoryId?: string,
    includeDetails: boolean = true
  ): Promise<ProfileMissionContent> {
    return new Promise<ProfileMissionContent>(async (resolve, reject) => {
      const initContent = async (ids: Array<string>) => {
        const contents = await this.profileSrv
          .getContents(ids, eligibleCategoryId ? [eligibleCategoryId] : null)
          .catch((err) => {});
        logger.log("contents", contents);
        return contents;
      };

      const params: GetAchievementsParams = {
        achievementIds: [achId],
        rangeTo: 1,
        // currentlyActive: true,
        active: true,
        includeDetails: includeDetails,
        resources: true,
        metadatas: true,
        languageIds: TAConstants.Settings.LANGUAGES,
        orderType: TAConstants.ORDER_TYPE.BY_ORDERING_ASC,
      };
      this.achievemtSrv
        .getAchievements(params)
        .then(async (result) => {
          if (!result || result.length == 0) {
            resolve(null);
            return;
          }
          const ids = [];
          // let response: MissionResponse = { contentAchs: [] };
          let a: ProfileMissionContent = result[0];
          this.profileSrv.setupMission(a);

          a.id = a.achievementId;
          a.contents = [];
          const contentIds =
            a.details && a.details != "" ? a.details.split(",") : [];
          if (contentIds && contentIds.length > 0) {
            ids.push(...contentIds);
          }

          if (isEligible && ids && ids.length > 0) {
            let contents = await initContent(ids).catch((err) => {});
            if (!contents) contents = [];
            // if (contents && contents.length > 0) {
            const contentIds = a.details.split(",");
            if (contentIds && contentIds.length > 0) {
              contentIds.forEach((id, index) => {
                if (contents) {
                  if (
                    a &&
                    a.userAchievement &&
                    a.userAchievement.eventAchievementActions &&
                    a.userAchievement.eventAchievementActions.length >
                      a.contents.length
                  ) {
                    const item = contents.find((c) => c.item_id == id);
                    if (item) {
                      const i = this.profileSrv.getProfileMission(
                        item,
                        a,
                        a.contents.length
                      );

                      // const i: ProfileMission = {
                      //   title: item.title,
                      //   image: item.image ? item.image.path : null,
                      //   id: item.item_id,
                      //   published: true,
                      //   completed: checkIfActionCompleted(a, index),
                      //   completedOn: checkCompletedOn(a, index),
                      //   nextActionDays: checkNextActionDays(a, index),
                      //   action: this.resourcesSrv.getResourcesBasic(
                      //     item.reference,
                      //     TAConstants.Resource_Types.ACTION_TYPE
                      //   ),
                      //   actionLabel: this.resourcesSrv.getResourcesBasic(
                      //     item.reference,
                      //     TAConstants.Resource_Types.ACTION_TYPE_DESCRIPTION
                      //   ),
                      //   category: item.category,
                      //   categoryIcon: item.categoryIcon,
                      //   pointsTag: item.pointsTag,
                      //   // action: item.actionButton? .actionButton.action:null
                      // };
                      a.contents.push(i);
                    }
                  }
                }
              });
            }
          }

          this.profileSrv.setupMissionUI(a);
          this.globals.isBoardingEligible = a.isEligible;

          // if (a.contents) {
          //   a.contents.forEach((c) => {
          //     c.active = false;
          //     if (c.completed) {
          //       c.active = true;
          //       a.current = null;
          //       a.finished = c;
          //     } else {
          //       if (!a.current) {
          //         c.active = true;
          //         a.current = c;
          //       }
          //     }
          //   });
          // }

          // if (a.current && a.finished && a.finished.nextActionDays > 0) {
          //   const now = new Date();
          //   const end = new Date(a.finished.completedOn);
          //   end.setDate(end.getDate() + a.finished.nextActionDays);
          //   if (now.getTime() < end.getTime()) a.current.active = false;
          // }

          resolve(a);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
