import { Injectable } from "@angular/core";
import { CategoryService } from "../../../../services/talos/category.service";
import { UploadContentsService } from "../../../../services/talos/uploadContents.service";
import { AppGlobalsService } from "../../../../services/appGlobals.service";
import { CategoryDTO } from "../../../../talosApi/models/CategoryDTO";
import { TAConstants } from "../../../../talosApi/settings";
import Resource_Types = TAConstants.Resource_Types;
import { UploadContentDTO } from "../../../../talosApi/models/UploadContentDTO";
import UPLOAD_CONTENT_TYPE = TAConstants.UPLOAD_CONTENT_TYPE;
import { QuizDTO } from "../../../../talosApi/models/QuizDTO";
import { QuizzesService } from "../../../../services/talos/quizzes.service";
import Settings = TAConstants.Settings;
import ORDER_TYPE = TAConstants.ORDER_TYPE;

@Injectable()
export class QuizDetailService {
  quizAnswered: boolean = false;

  constructor(private quizzesSrv: QuizzesService) {}

  getQuizById(quizId: string, matchingLevel?: boolean): Promise<QuizDTO> {
    return new Promise((resolve, reject) => {
      this.quizzesSrv
        .listQuiz(
          [quizId],
          null,
          null,
          Settings.GAME_TYPE,
          0,
          1,
          false,
          null,
          true,
          matchingLevel,
          true
        )
        .then((result: Array<QuizDTO>) => {
          if (result && result.length > 0) {
            resolve(result[0]);
            return;
          }

          resolve(null);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getRelatedItems(
    quizTypeId: number,
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<QuizDTO>> {
    return new Promise((resolve, reject) => {
      this.quizzesSrv
        .listQuiz(
          null,
          quizTypeId,
          null,
          Settings.GAME_TYPE,
          rangeFrom,
          rangeTo,
          false,
          ORDER_TYPE.BY_RECENT,
          false,
          true
        )
        .then((result: Array<QuizDTO>) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
