import { Http2Talos } from "../http2Talos";
import { Injectable } from "@angular/core";
import { TAConstants, TAValues } from "../settings";
import { UploadContentDTO } from "../models/UploadContentDTO";
import { HttpHeaders } from "@angular/common/http";
import { UploadContentsGroupByResponse } from "../models/UploadContentsGroupByResponse";
import { UploadContentReferenceDTO } from "../models/UploadContentReferenceDTO";
import { MetadataDTO, ResourceDTO } from "../models";

@Injectable()
export class UploadContentsApi {
  constructor(private http2Talos: Http2Talos) {}

  /**
   * @param {Array<string>} uploadContentIds
   * @param {number} uploadContentTypeId
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @param {boolean} includeStatistics
   * @param {Array<string>} refItemIds
   * @param {number} refItemTypeId
   * @param {boolean} sortByDate
   * @param {boolean} descendingOrder
   * @param {boolean} sortByRating
   * @return {Promise<Array<UploadContentDTO>>}
   */
  public getUploadContents(
    uploadContentIds?: Array<string>,
    uploadContentTypeId?: number,
    orderType?: string,
    rangeFrom?: number,
    rangeTo?: number,
    includeStatistics?: boolean,
    refItemIds?: Array<string>,
    refItemTypeId?: number,
    sortByDate?: boolean,
    descendingOrder?: boolean,
    sortByRating?: boolean,
    headers?: HttpHeaders
  ): Promise<Array<UploadContentDTO>> {
    return this.http2Talos.get({
      path: `/uploadContent/${TAValues.UserId}/uploadContent`,
      queryParams: {
        uploadContentIds: uploadContentIds,
        uploadContentTypeId: uploadContentTypeId,
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
        includeStatistics: includeStatistics,
        refItemIds: refItemIds,
        refItemTypeId: refItemTypeId,
        sortByDate: sortByDate,
        descendingOrder: descendingOrder,
        sortByRating: sortByRating,
        orderType: orderType,
        matchingLevel: true,
        metadatas: true,
        resources: true,
        languageIds: TAConstants.Settings.LANGUAGES,
      },
      headers: headers,
    });
  }

  public getUploadContentsByIds(
    uploadContentIds: Array<string>,
    uploadContentTypeId: number,
    resources: boolean,
    languageIds: Array<string>,
    metadatas: boolean,
    active: boolean,
    matchingLevel: boolean,
    orderType: string = "BY_ORDERING_ASC",
    includeStatistics?: boolean,
    rangeFrom?: number,
    rangeTo?: number,
    headers?: HttpHeaders
  ) {
    return this.http2Talos.get({
      path: `/uploadContent/${TAValues.UserId}/uploadContent`,
      queryParams: {
        uploadContentIds: uploadContentIds,
        uploadContentTypeId: uploadContentTypeId,
        resources: resources,
        languageIds: languageIds,
        active: active,
        metadatas: metadatas,
        matchingLevel: matchingLevel,
        orderType: orderType,
        includeStatistics: includeStatistics,
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
      },
      headers: headers,
    });
  }

  /**
   * Gets upload contents by reference
   * @param refItemIds
   * @param uploadContentTypeId
   * @param refItemTypeId
   * @param resources
   * @param languageIds
   * @param active
   * @param matchingLevel
   * @param [includeStatistics]
   * @param [rangeFrom]
   * @param [rangeTo]
   * @param [headers]
   * @returns
   */
  public getUploadContentsByReference(
    refItemIds: Array<string> | Array<number>,
    uploadContentTypeId: number,
    refItemTypeId: number,
    resources: boolean,
    languageIds: Array<string>,
    active: boolean,
    matchingLevel: boolean,
    orderType: string = "BY_ORDERING_ASC",
    includeStatistics?: boolean,
    rangeFrom?: number,
    rangeTo?: number,
    metadatas?: boolean,
    headers?: HttpHeaders
  ) {
    return this.http2Talos.get({
      path: `/uploadContent/${TAValues.UserId}/uploadContent`,
      queryParams: {
        uploadContentTypeId: uploadContentTypeId,
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
        includeStatistics: includeStatistics,
        refItemIds: refItemIds,
        refItemTypeId: refItemTypeId,
        resources: resources,
        languageIds: languageIds,
        active: active,
        matchingLevel: matchingLevel,
        orderType: orderType,
        metadatas: metadatas,
      },
      headers: headers,
    });
  }

  /**
   * Get Group By Upload Contents
   * @param {string} userId
   * @param {GetUploadContentsGroupByQuery} query
   * @return {Promise<Array<UploadContentsGroupByResponse>>}
   */
  groupByUploadContent(
    userId: string,
    query: GetUploadContentsGroupByQuery
  ): Promise<Array<UploadContentsGroupByResponse>> {
    return this.http2Talos.get({
      path: `/uploadContent/${userId}/groupBy`,
      queryParams: query,
      requireSession: true,
    });
  }

  /**
   * Upload Upload Content
   * @param {string} userId
   * @param {UploadContentDataInput} input
   * @param {boolean} requireSession
   * @param {HttpHeaders} headers
   * @return {Promise<any>}
   */
  addUploadContent(
    userId: string,
    input: UploadContentDataInput,
    requireSession: boolean = true,
    headers?: HttpHeaders
  ): Promise<any> {
    return this.http2Talos.post({
      path: `/uploadContent/${userId}/uploadContent/add`,
      input: input,
      requireSession: true,
    });
  }
}

export interface GetUploadContentsGroupByQuery {
  freeTextSearch?: string;
  uploadContentIds?: Array<string>;
  uploadContentTypeId?: number;
  dateFrom?: Date;
  dateTo?: Date;
  active?: boolean;
  refUserIds?: Array<string>;
  latitude?: number;
  longitude?: number;
  radius?: number;
  dateReferencedFrom?: Date;
  dateReferencedTo?: Date;
  status?: number;
  matchingLevel?: boolean;
  refItemIds?: Array<string>;
  refItemTypeId?: number;
  groupByIds?: Array<string>;
  groupByType?: string;
}

export interface UploadContentDataInput {
  uploadContentTypeId?: number;

  title?: string;

  references?: Array<UploadContentReferenceDTO>;

  resources?: Array<ResourceDTO>;

  file?: Array<string>;

  metadatas?: Array<MetadataDTO>;

  dateReferenced?: Date;
}
