import { Injectable } from "@angular/core";
import { TAConstants, TAValues } from "../../../talosApi/settings";
import { Message } from "../../../talosApi/models/Message";
import { MessagesService } from "../../../services/talos/messages.service";
import { AppSettingsService } from "../../../services/talos/appSettings.service";
import * as _ from "lodash";
import { EWalletDTO } from "../../../talosApi/models/EWalletDTO";
import { UserService } from "../../../services/talos/user.service";
import { UserHistoryResponse } from "../../../talosApi/models/userHistoryResponse";
import { TransactionHistoryInput } from "../../../talosApi/models/TransactionHistoryInput";
import * as Moment from "moment";
import { MetadataDTO, User } from "../../../talosApi/models";
import { EventService } from "../../../services/talos/event.service";
import { IEventInput } from "../../../talosApi/api/EventApi";
import { environment } from "../../../environments/environment";
import { UploadContentDTO } from "../../../talosApi/models/UploadContentDTO";
import { FavoriteDTO } from "../../../talosApi/models/FavoriteDTO";
import { InputGetFavorite } from "../../../talosApi/api/FavoriteApi";
import { FavoriteService } from "../../../services/talos/favorite.service";
import { UploadContentsService } from "../../../services/talos/uploadContents.service";
import { CategoryDTO } from "../../../talosApi/models/CategoryDTO";
import { AppGlobalsService } from "../../../services/appGlobals.service";
import { CategoryService } from "../../../services/talos/category.service";
import { FilterTag } from "../../shared/components/icon-item-filter-tag-ang/icon-item-filter-tag-ang.component";
import { EventRewardRulesDTO } from "../../../talosApi/models/EventRewardRulesDTO";
import { ResourcesServices } from "../../../services/talos/resources.services";
import { SortTag } from "../../shared/components/icon-item-shorter-ang/icon-item-shorter-ang.component";
import { UtilsService } from "../../../services/utils.service";
import { DetailedBrandProductDTO } from "../../../talosApi/models/DetailedBrandProductDTO";
import { MiddleApi } from "../../../talosApi/api/middleApi";
import {
  IReferenceLevelInput,
  ReferenceApi,
} from "../../../talosApi/api/referenceApi";
import { IReferenceLevel } from "./profile.component";
import { BrandsService } from "../../../services/talos/brands.service";
import { DetailedBrandProductCouponDTO } from "../../../talosApi/models/DetailedBrandProductCouponDTO";
import { CategoryItemDTO } from "../../../talosApi/models/CategoryItemDTO";
import { IListUserBrandProductCoupons } from "../../../talosApi/api/BrandApi";
import { ItemService } from "../../../services/talos/item.service";
import { ItemMetadataDTO } from "../../../talosApi/models/ItemMetadataDTO";
import { ExtSystemInfoDTO } from "../../../talosApi/models/ExtSystemInfoDTO";
import { TranslateService } from "@ngx-translate/core";
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import Unit_Types = TAConstants.Unit_Types;
import Events_Types = TAConstants.Events_Types;
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import Resource_Types = TAConstants.Resource_Types;
import UPLOAD_CONTENT_TYPE = TAConstants.UPLOAD_CONTENT_TYPE;
import ITEM_TYPE = TAConstants.ITEM_TYPE;
import Settings = TAConstants.Settings;
import LANGUAGES = TAConstants.Settings.LANGUAGES;
import Favorites_Status = TAConstants.Favorites_Status;
import LEVELS_RESOURCES_TYPE_ID = TAConstants.LEVELS_RESOURCES_TYPE_ID;
import COUPON_STATUSES = TAConstants.COUPON_STATUSES;
import ITEM_METADATA = TAConstants.ITEM_METADATA;
import Transactions_Types = TAConstants.Transactions_Types;
import TAHeaders = TAConstants.TAHeaders;
import QUIZ_TYPES = TAConstants.QUIZ_TYPES;
import Order_Stores = TAConstants.Order_Stores;
import { RewardsService } from "../rewards/rewards.service";
import { to } from "../../../../../src/utils/utils";
import { isNullOrUndefined } from "util";
import { IndexedDataContent } from "../../../talosApi/models/IndexedDataContent";
import { RecommendationsService } from "../../../services/talos/recommendations.service";
import { FilterESContentDataInput } from "../../../talosApi/api/RecommendationsApi";
import { KpDictionary } from "../../../../../src/utils/kp_dictionary/kp.dictionary";
import ORDER_TYPE = TAConstants.ORDER_TYPE;
import { AchievementService } from "src/services/talos/achievement.service";
import { GetAchievementsParams } from "src/talosApi/api/AchievementApi";
import { AchievementDTO } from "src/talosApi/models/AchievementDTO";
import { BalanceTypeQ } from "src/talosApi/api/UserApi";
import { EWalletSummaryPerVmTransactionTypeTO } from "src/talosApi/models/EWalletSummaryPerVmTransactionTypeTO";
import { MaterialObject } from "src/models/MaterialObject";
import { MaterialService, MaterialType } from "../material/material.service";
import { OverviewGenericContent } from "../overview/overview.service";
import { ItemActionStatusDTO } from "src/talosApi/models/ItemActionStatusDTO";
import { GetUserActionStatusInput } from "src/talosApi/models/GetUserActionStatusInput";
import { IndexedDataService } from "src/services/talos/indexedData.service";
import { IndirectUserDataDTO } from "src/talosApi/models/IndirectUserDataDTO";
import { IndirectDataService } from "src/services/talos/indirectData.service";
import { GetIndirectUserDataQuery } from "src/talosApi/api/IndirectDataApi";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import METADATA_KEY = TAConstants.METADATA_KEY;

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  materialConfig;
  messageStorage: Map<string, IMessageCategory> = new Map<
    string,
    IMessageCategory
  >();
  // <<<<<<< HEAD
  // =======
  //   private profileProgressCountsSubject: BehaviorSubject<number> =
  //     new BehaviorSubject<number>(0);
  //   public profileProgressCounts$ =
  //     this.profileProgressCountsSubject.asObservable();
  // >>>>>>> 3545339e089be44f5d01bd9474f69da8a013190d

  constructor(
    public messagesSrv: MessagesService,
    public appSetingsSrv: AppSettingsService,
    public eventSrv: EventService,
    public userSrv: UserService,
    public favoriteSrv: FavoriteService,
    public uploadContentsSrv: UploadContentsService,
    public appGlobalsSrv: AppGlobalsService,
    public MiddleApi: MiddleApi,
    public referenceApi: ReferenceApi,
    public itemSrv: ItemService,
    public categoryService: CategoryService,
    public resourcesSrv: ResourcesServices,
    public utilsSrv: UtilsService,
    public brandsService: BrandsService,
    public rewardService: RewardsService,
    public translateSrv: TranslateService,
    private recommendationsSrv: RecommendationsService,
    private achievemtSrv: AchievementService,
    private materialSrv: MaterialService,
    private indirectDataSrv: IndirectDataService
  ) {
    if (appGlobalsSrv.config)
      this.materialConfig =
        appGlobalsSrv.config[APPLICATION_SETTING_KEYS.MATERIAL_CONFIG] || null;
  }

  getMaterialConfig() {
    if (this.materialConfig) return this.materialConfig;

    if (this.appGlobalsSrv.config)
      this.materialConfig =
        this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.MATERIAL_CONFIG] ||
        null;

    return this.materialConfig;
  }

  getMyActions(params: BalanceTypeQ): Promise<Array<any>> {
    return new Promise(async (resolve, reject) => {
      // const actions = [
      //   {
      //     points: `<span style="font-weight: bold; font-size: 24px;margin-right:4px;">200</span> punti guadgnati quest'anno`,
      //     title: `Interagisci con i contenuti`,
      //     progressText: "Ogni settimana nuovi contenuti",
      //     progress: 3,
      //     progressTotal: 5,
      //     progressType: 0,
      //     description: `Per te, fino a 100 punti per articolo, quiz o video.`,
      //     buttonText: `Scopri i contenuti`,
      //     buttonAction: `profile/history`,
      //   },
      //   {
      //     points: `<span style="font-weight: bold; font-size: 24px;margin-right:4px;">100</span> / 125 Punti`,
      //     title: `Interagisci con i contenuti`,
      //     progressText: "",
      //     progress: 3,
      //     progressTotal: 5,
      //     progressType: 0,
      //     description: `Per te, fino a 100 punti per articolo, quiz o video.`,
      //     buttonText: `Scopri i contenuti`,
      //     buttonAction: `https://in.gr`,
      //   },
      //   {
      //     points: `<span style="font-weight: bold; font-size: 24px;margin-right:4px;">200</span> punti guadgnati quest'anno`,
      //     title: `Interagisci con i contenuti`,
      //     progressText: "",
      //     progress: 3,
      //     progressTotal: 5,
      //     progressType: 1,
      //     description: `Per te, fino a 100 punti per articolo, quiz o video.`,
      //     buttonText: `Scopri i contenuti`,
      //     buttonAction: `profile/history`,
      //   },
      //   {
      //     points: `<span style="font-weight: bold; font-size: 24px;margin-right:4px;">200</span> punti guadgnati quest'anno`,
      //     title: `Interagisci con i contenuti`,
      //     progressText: "Ogni settimana nuovi contenuti",
      //     progress: 3,
      //     progressTotal: 5,
      //     progressType: 0,
      //     description: `Per te, fino a 100 punti per articolo, quiz o video.`,
      //     buttonText: `Scopri i contenuti`,
      //     buttonAction: `profile/history`,
      //   },
      //   {
      //     points: `<span style="font-weight: bold; font-size: 24px;margin-right:4px;">100</span> / 125 Punti`,
      //     title: `Interagisci con i contenuti`,
      //     progressText: "",
      //     progress: 3,
      //     progressTotal: 5,
      //     progressType: 0,
      //     description: `Per te, fino a 100 punti per articolo, quiz o video.`,
      //     buttonText: `Scopri i contenuti`,
      //     buttonAction: `https://in.gr`,
      //   },
      //   {
      //     points: `<span style="font-weight: bold; font-size: 24px;margin-right:4px;">200</span> punti guadgnati quest'anno`,
      //     title: `Interagisci con i contenuti`,
      //     progressText: "",
      //     progress: 3,
      //     progressTotal: 5,
      //     progressType: 1,
      //     description: `Per te, fino a 100 punti per articolo, quiz o video.`,
      //     buttonText: `Scopri i contenuti`,
      //     buttonAction: `profile/history`,
      //   },
      // ];

      // const par: BalanceTypeQ = {
      //   gameTypeId: Settings.GAME_TYPE,
      //   unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
      //   vmTransactionTypeIds: [
      //     Transactions_Types.EXTERNAL_ACTIVITY,
      //     Transactions_Types.EXTERNAL_ACTIVITY_NEW,
      //     Transactions_Types.QUIZ_WON,
      //     Transactions_Types.USER_PROFILE_FIELD_UPDATE,
      //     // Transactions_Types.INSTANT_REWARD,
      //     Transactions_Types.USER_ACTIVITY,
      //     81,
      //     82,
      //     83,
      //   ], //vmTransactionTypeIds=30&vmTransactionTypeIds=57&vmTransactionTypeIds=66&vmTransactionTypeIds=3&vmTransactionTypeIds=67&vmTransactionTypeIds=81&vmTransactionTypeIds=82&vmTransactionTypeIds=83
      //   rangeFrom: rangeFrom,
      //   rangeTo: rangeTo,
      // };
      const actions = await this.userSrv
        .getUserBalanceType(TAValues.UserId, params)
        .catch((err) => {});

      if (!actions) {
        resolve([]);
        return;
      }

      // actions[TAHeaders.ITEM_COUNT] = "9";

      resolve(actions);
    });
  }
  /**
   *
   * @param rangeFrom
   * @param rangeTo
   */
  getMessages(rangeFrom: number, rangeTo: number): Promise<Array<Message>> {
    return new Promise((resolve, reject) => {
      const folderId = this.appSetingsSrv.getValue(
        APPLICATION_SETTING_KEYS.USER_MESSAGING_FOLDER
      );
      if (_.isNil(folderId)) {
        resolve([]);
        return;
      }

      const getMessageImage = (message: Message): string => {
        if (message.properties) {
          var image = message.properties.filter((p) => p.type == 0);

          if (image && image.length > 0) {
            return image[0].value;
          }
        }

        return "";
      };
      this.messagesSrv
        .getMessages(folderId, rangeFrom, rangeTo)
        .then((result: Array<Message>) => {
          if (result) {
            result.forEach((m: IMessageCategory) => {
              let title: string;
              let category: string;
              let message: string;

              try {
                const title_: string[] = m.content
                  .match(/<messagetitle>(.*?)<\/messagetitle>/g)
                  .map((val) => val.replace(/<\/?messagetitle>/g, ""));
                title = title_[0];
                const category_: string[] = m.content
                  .match(/<category>(.*?)<\/category>/g)
                  .map((val) => val.replace(/<\/?category>/g, ""));
                category = category_[0];
                const message_: string[] = m.content
                  .match(/<message>(.*?)<\/message>/g)
                  .map((val) => val.replace(/<\/?message>/g, ""));
                message = message_[0];

                // set the storage
                this.messageStorage.set(category, m);
              } catch (e) {
                title = "";
                category = "";
                message = "";
              }
              m.title = title.toUpperCase();
              m.category = category;
              m.message = message;
              m.image = getMessageImage(m);
              m.formatedDate = Moment(m.messageDate)
                .locale(this.translateSrv.currentLang)
                .calendar(); //moment().subtract(1, 'days').calendar();
            });
          }

          resolve(result);
        }, reject);
    });
  }

  /**
   *
   * @param rangeFrom
   * @param rangeTo
   * @param descendingOrder
   */
  getHistoryTransaction(
    rangeFrom: number,
    rangeTo: number,
    descendingOrder: boolean
  ): Promise<Array<EWalletDTO>> {
    return new Promise((resolve, reject) => {
      const input: TransactionHistoryInput = {
        unitTypeId: [Unit_Types.IQOS_CLUB_IT_POINTS],
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
        descendingOrder: descendingOrder,
        sortByDate: true,
      };
      this.userSrv
        .getHistoryTransaction(TAValues.UserId, input)
        .then((result: Array<EWalletDTO>) => {
          resolve(result);
        }, reject);
    });
  }

  /**
   *
   * @param userId
   * @param messageId
   * @param status
   */
  setReadMessage(
    userId: string,
    messageId: string,
    status: number
  ): Promise<Message> {
    return new Promise<Message>((resolve, reject) => {
      this.messagesSrv
        .setStatus(TAValues.UserId, [messageId], status)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   */
  addEventOptout(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const input: IEventInput = {
        userId: TAValues.UserId,
        eventTypeId: Events_Types.OPT_OUT_USER_ID,
        gameTypeId: environment.GAME_TYPE_ID,
        clientTypeId: TAValues.CLIENT_TYPE_ID,
        withNoRewards: false,
        applicationId: TAValues.APPLICATION_ID,
      };

      this.eventSrv
        .addEvent(input)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * @description unregister the user.
   */
  unregisterTheUser(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.userSrv
        .unRegisterUser()
        .then((unregister) => {
          resolve(unregister);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param user
   * @param allowCommunication
   */
  unSubscribeUser(user: User, allowCommunication: boolean): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.userSrv
        .unsubscribeUser(user, allowCommunication, true)
        .then((userUnsub) => {
          resolve(userUnsub);
        })
        .catch((error) => {
          logger.log(error);
          reject(error);
        });
    });
  }

  // private getFavoriteFiltersCounts(filters: Array<CategoryDTO>): Promise<Array<CategoryDTO>> {
  //     return new Promise((resolve, reject) => {
  //
  //         let categoriesToRequest = new Array<CategoryDTO>();
  //
  //         filters.forEach((f: CategoryDTO) => {
  //             const count = this.materialCountById[Number(categoryId + f.itemIdForResourceLookup)];
  //             if (count) {
  //                 f["total-count"] = count;
  //             } else {
  //                 categoriesToRequest.push(f);
  //             }
  //         });
  //
  //         if (categoriesToRequest.length == 0) {
  //             resolve(filters);
  //             return;
  //         }
  //
  //         const promises = categoriesToRequest.map((f: CategoryDTO) => {
  //             return this.getMaterialItems([categoryId, f.itemIdForResourceLookup], 0, 1, false);
  //         })
  //
  //         Promise.all(promises).then(arrayOfResults => {
  //             for (let i = 0; i < categoriesToRequest.length; i++) {
  //                 if (categoriesToRequest[i] && arrayOfResults[i]) {
  //                     categoriesToRequest[i]['total-count'] = arrayOfResults[i][TAConstants.TAHeaders.ITEM_COUNT];
  //                     this.materialCountById[Number(categoryId + categoriesToRequest[i].itemIdForResourceLookup)] = Number(arrayOfResults[i][TAConstants.TAHeaders.ITEM_COUNT]);
  //                 }
  //             }
  //             resolve(filters);
  //         }, (err) => {
  //             logger.log(err);
  //             resolve(filters);
  //         });
  //     });
  // }

  /**
   *
   */
  getFavoritesFilters(): Promise<Array<CategoryDTO>> {
    return new Promise((resolve, reject) => {
      const materialConfig = this.getMaterialConfig();
      var mainCategoryId =
        materialConfig[APPLICATION_SETTING_KEYS.MATERIAL_FILTER_CATEGORY_ID];
      if (!mainCategoryId) {
        reject("No main category found!!");
        return;
      }

      this.categoryService
        .getCategoryById(mainCategoryId, false, false, false, [
          Resource_Types.NAME,
        ])
        .then((category: CategoryDTO) => {
          if (category && category.children) {
            resolve(category.children);
            // this.getFavoriteFiltersCounts(category.children).then((items: Array<CategoryDTO>) => {
            //     resolve(items);
            // }).catch(err => {
            //     reject(err);
            // });
          }

          resolve([]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   *
   * @param {Array<string>} categoryIds
   * @param {string} sort
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @return {Promise<Array<UploadContentDTO>>}
   */
  getFavorites(
    categoryIds: Array<string>,
    sort: string,
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<UploadContentDTO>> {
    return new Promise((resolve, reject) => {
      const input_: InputGetFavorite = {
        rangeFrom: 0,
        rangeTo: -1,
        statusIds: [Favorites_Status.Active],
        itemTypeIds: [ITEM_TYPES.UPLOAD_CONTENT], //FIXME: i am not sour for this item type.
      };

      this.favoriteSrv
        .getFavorite(TAValues.UserId, input_)
        .then((allFavorites: FavoriteDTO[]) => {
          logger.log(allFavorites);
          if (_.isNil(allFavorites) || allFavorites.length == 0) {
            resolve([]);
            return;
          }
          logger.log(allFavorites);
          const itemIds = allFavorites.map((f: FavoriteDTO) => {
            return f.itemId;
          });

          this.uploadContentsSrv
            .getUploadContents(
              itemIds,
              UPLOAD_CONTENT_TYPE.GENERAL,
              sort,
              rangeFrom,
              rangeTo,
              true,
              categoryIds,
              ITEM_TYPE.CATEGORY,
              true,
              false,
              false,
              [
                Resource_Types.NAME,
                Resource_Types.SHORT_DESCRIPTION,
                Resource_Types.DESCRIPTION,
                Resource_Types.URL_PATH,
                Resource_Types.VIDEO_URL,
              ],
              true,
              true
            )
            .then((uploadContents: Array<UploadContentDTO>) => {
              if (_.isNil(uploadContents)) {
                resolve([]);
                return;
              }

              resolve(uploadContents);
            })
            .catch((error) => {
              logger.log(error);
              reject(error);
            });
        })
        .catch((error) => {
          logger.log(error);
          reject(error);
        });
    });
  }

  /**
   *
   * @param {FilterTag} filterType
   * @param {SortTag} sortType
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @return {Promise<Array<UploadContentDTO>>}
   */
  getFavoriteItemsBySelections(
    filterType: FilterTag,
    sortType: SortTag,
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<UploadContentDTO>> {
    const categoryIds: Array<string> = [];
    if (filterType != FilterTag.ALL) {
      const filtercategoryId = this.getCategoryIdByType(
        filterType,
        APPLICATION_SETTING_KEYS.MATERIAL_FILTER_TYPE_IDS
      );
      if (filtercategoryId) categoryIds.push(filtercategoryId);
    }

    const sort = this.utilsSrv.getOrderStringBySortType(sortType);
    return this.getFavorites(categoryIds, sort, rangeFrom, rangeTo);
    //return this.getFavorites(categoryIds, sort, rangeFrom, rangeTo);
  }

  /**
   *
   * @param categoryIds
   * @param rangeFrom
   * @param rangeTo
   * @param requestResources
   */
  getMaterialItems(
    categoryIds: Array<string>,
    rangeFrom: number,
    rangeTo: number,
    requestResources: boolean = true
  ): Promise<Array<UploadContentDTO>> {
    return new Promise((resolve, reject) => {
      this.uploadContentsSrv
        .getUploadContents(
          null,
          UPLOAD_CONTENT_TYPE.GENERAL,
          null,
          rangeFrom,
          rangeTo,
          true,
          categoryIds,
          ITEM_TYPE.CATEGORY,
          true,
          false,
          false,
          [
            Resource_Types.NAME,
            Resource_Types.SHORT_DESCRIPTION,
            Resource_Types.DESCRIPTION,
            Resource_Types.URL_PATH,
          ],
          requestResources
        )
        .then((items: Array<UploadContentDTO>) => {
          if (items) {
            resolve(items);
            return;
          }

          resolve([]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   *
   * @param type
   * @param appSettingKey
   */
  getCategoryIdByType(type: number, appSettingKey: string): string {
    let result = null;
    const materialConfig = this.getMaterialConfig();
    let materialTypeIds: Array<any> = materialConfig[appSettingKey];
    if (materialTypeIds) {
      materialTypeIds.forEach((item) => {
        for (let key in item) {
          if (item[key] == type) result = key;
        }
      });
    }
    return result;
  }

  /**
   *
   * @param categoryId
   * @param appSettingKey
   */
  getTypeByCategoryId(categoryId: string, appSettingKey: string): number {
    let result = -1;
    const materialConfig = this.getMaterialConfig();
    let materialTypeIds: Array<any> = materialConfig[appSettingKey];
    if (materialTypeIds) {
      materialTypeIds.forEach((item) => {
        if (item.hasOwnProperty(categoryId)) result = item[categoryId];
      });
    }
    return result;
  }

  countBenefits(): Promise<number> {
    return new Promise(async (resolve, reject) => {
      const rootCategoryId =
        this.appGlobalsSrv.config[
          APPLICATION_SETTING_KEYS.BENEFITS_CATEGORY_ID
        ];
      if (!rootCategoryId) {
        reject(null);
      } else {
        const _CountBrandProductsByCategories = await to(
          this.rewardService.CountBrandProductCouponsByCategoryAlt(
            rootCategoryId,
            [],
            false
          )
        );
        logger.log(
          "_CountBrandProductsByCategories",
          _CountBrandProductsByCategories
        );
        if (isNullOrUndefined(_CountBrandProductsByCategories.data)) {
          /* If Any Failed. */
          reject(null);
        } else {
          resolve(_CountBrandProductsByCategories.data.count);
        }
      }
    });
  }

  getBenefits(
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<DetailedBrandProductDTO>> {
    return new Promise((resolve, reject) => {
      const rootCategoryId =
        this.appGlobalsSrv.config[
          APPLICATION_SETTING_KEYS.BENEFITS_CATEGORY_ID
        ];
      if (!rootCategoryId) {
        reject("No main category found!!");
        return;
      }

      this.categoryService
        .getCategoryById(rootCategoryId, false, false, true, [])
        .then((category: CategoryDTO) => {
          if (!category || !category.leaves || category.leaves.length == 0) {
            resolve([]);
            return;
          }

          const statusIds = [
            COUPON_STATUSES.AVAILABLE,
            // COUPON_STATUSES.CANCELLED, COUPON_STATUSES.CANCELLED_CHARGED_BACK, COUPON_STATUSES.CANCELLED_CLEARED,
            COUPON_STATUSES.PURCHASED,
            COUPON_STATUSES.PURCHASED_SENT_AS_GIFT_NOT_FOUND,
            COUPON_STATUSES.PURCHASED_SENT_AS_GIFT,
            COUPON_STATUSES.PURCHASED_SENT_AS_GIFT_NOT_NTERESTED,
            COUPON_STATUSES.REDEEMED,
            COUPON_STATUSES.REDEEMED_CLEARED,
            COUPON_STATUSES.REDEEMED_CLEARED_FAILED,
            COUPON_STATUSES.RESERVED,
            COUPON_STATUSES.VERIFIED,
          ];
          const brandProductIds = category.leaves.map((c: CategoryItemDTO) => {
            return c.itemId;
          });

          if (!brandProductIds) {
            resolve([]);
            return;
          }

          const input: IListUserBrandProductCoupons = {
            includeDetails: true,
            includeBom: true,
            categoryIds: [rootCategoryId],
            includeQRCode: true,
            brandProductCouponStatusIds: statusIds,
            rangeFrom: rangeFrom,
            rangeTo: rangeTo,
            brandProductIds: brandProductIds,
            matchingLevel: false,
          };

          this.brandsService
            .getUserBrandProductCoupons(this.userSrv.user.user.userId, input)
            .then((coupons: any) => {
              if (!coupons || coupons.length == 0) {
                resolve([]);
                return;
              }

              const dealIds = coupons.map(
                (coupon: DetailedBrandProductCouponDTO) => {
                  return coupon.brandProductId;
                }
              );

              if (!dealIds || dealIds.length == 0) {
                resolve([]);
                return;
              }

              this.brandsService
                .getBrandProductsBenefit(
                  TAValues.UserId,
                  dealIds,
                  rangeFrom,
                  -1,
                  [
                    Resource_Types.NAME,
                    Resource_Types.SHORT_DESCRIPTION,
                    Resource_Types.DESCRIPTION,
                    Resource_Types.CONTENT_1,
                    Resource_Types.IMAGE,
                  ]
                )
                .then((result: Array<DetailedBrandProductDTO>) => {
                  if (!result) {
                    resolve([]);
                    return;
                  }
                  const deals = result.map((b: DetailedBrandProductDTO) => {
                    const coupon = (
                      coupons as Array<DetailedBrandProductCouponDTO>
                    ).filter((c: DetailedBrandProductCouponDTO) => {
                      return c.brandProductId === b.brandProductId;
                    });

                    if (coupon && coupon.length > 0) {
                      b["couponCode"] = coupon[0].code;
                      b.activeSince = coupon[0].purchaseDate;
                    }

                    return b;
                  });
                  resolve(result);
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });

      // this.brandsService.getUserBrandProductCoupons(
      //     this.userSrv.user.user.userId, {
      //         includeDetails: true,
      //         includeBom: true,
      //         categoryIds: [rootCategoryId],
      //         includeQRCode: true,
      //         brandProductCouponStatusIds: [],
      //         rangeFrom: 0,
      //         rangeTo: -1
      //     })
      //     .then((result: ItemsList<DetailedBrandProductCouponDTO>) => {
      //         if (!result || !result.items || result.items.length == 0) {
      //             resolve([]);
      //             return;
      //         }
      //
      //     }).catch(err => {
      //     reject(err);
      // })
    });
  }

  /**
   *
   * @param code
   * @param size
   */
  async getQrImage(code: string, size: number) {
    // return this.http.post(`https://friendsplus.mystartapp.org/user/qrcode?text=${code}&size=${size}`,{}, {responseType: 'text' }).toPromise();
    return await this.MiddleApi.getQrCode(code, size).catch((err) => {
      logger.log(err);
    });
  }

  /**
   * @description get the ref for the resources images and
   * we need to get from this the percentages.(with the balances.)
   */
  getLevelRef(): Promise<IReferenceLevel> {
    return new Promise((resolve, reject) => {
      const input: IReferenceLevelInput = {
        resources: true,
      };
      this.referenceApi
        .getReferenceLevels(environment.GAME_TYPE_ID, input)
        .then((result: Array<IReferenceLevel>) => {
          try {
            const referencesLevel: IReferenceLevel = result.find(
              (unitType) =>
                unitType.unitType.unitTypeId === Unit_Types.IQOS_CLUB_IT_LEVEL
            );

            //TODO: i need to get the level
            const levelOne: any = referencesLevel.levels.find(
              (index) => index.index === 1
            );
            const levelTwo: any = referencesLevel.levels.find(
              (index) => index.index === 2
            );
            const levelThree: any = referencesLevel.levels.find(
              (index) => index.index === 3
            );
            const levelFour: any = referencesLevel.levels.find(
              (index) => index.index === 4
            );
            const levelOneResources: ILevelImage =
              this.setImagesLevelsActiveInactive(levelOne, 1);
            const levelTwoResources: ILevelImage =
              this.setImagesLevelsActiveInactive(levelTwo, 2);
            const levelThreeResources: ILevelImage =
              this.setImagesLevelsActiveInactive(levelThree, 3);
            const levelFourResources: ILevelImage =
              this.setImagesLevelsActiveInactive(levelFour, 4);
            result.forEach((items) => {
              items.levelResources = [
                levelOneResources,
                levelTwoResources,
                levelThreeResources,
                levelFourResources,
              ];
            });
            const refItemsLevel = result.find(
              (unitType) =>
                unitType.unitType.unitTypeId === Unit_Types.IQOS_CLUB_IT_LEVEL
            );
            resolve(refItemsLevel);
          } catch (e) {
            logger.log(e);
            reject(e);
          }
        })
        .catch((error) => {
          logger.log(error);
          reject(error);
        });
    });
  }

  /**
   *
   * @param item
   * @param level_
   */
  setImagesLevelsActiveInactive(item: any, level_: number): ILevelImage {
    const levelOneActive = item.resources.find(
      (resourcesTypeId) =>
        resourcesTypeId.resourceTypeId === LEVELS_RESOURCES_TYPE_ID.ACTIVE
    );
    const levelOneInactive = item.resources.find(
      (resourcesTypeId) =>
        resourcesTypeId.resourceTypeId === LEVELS_RESOURCES_TYPE_ID.INACTIVE
    );

    return <ILevelImage>{
      levelImageActive: levelOneActive.textResource,
      levelImageInactive: levelOneInactive.textResource,
      level: level_,
    };
  }

  getReferenceMetadata() {}

  /**
   *
   * @param {number[]} transactionTypeId
   * @param {SortTag} sortType
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @return {Promise<UserHistoryResponse>}
   */
  getHistoryItemsBySelectionsNew(
    transactionTypeId: number[],
    sortType: SortTag,
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<EWalletDTO>> {
    return new Promise((resolve, reject) => {
      const orderType = this.utilsSrv.getOrderStringBySortType(sortType);
      const input: TransactionHistoryInput = {
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
        orderType: orderType,
        includeDetails: true,
        resources: true,
        metadatas: true,
        languageIds: LANGUAGES,
        excludeZeroAmounts: true,
        transactionTypeId: transactionTypeId,
        //descendingOrder: sortType == SortTag.RECENT,
        unitTypeId: [Unit_Types.IQOS_CLUB_IT_POINTS],
      };
      this.userSrv
        .getHistoryTransaction(TAValues.UserId, input)
        .then((result: Array<EWalletDTO>) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getTransactionTypeId(filterType: number): Array<number> {
    switch (Number(filterType)) {
      case FilterTag.ALL:
        return [];
        break;
      case FilterTag.TR_WELCOME:
        return [
          Transactions_Types.WELCOME_BONUS,
          Transactions_Types.BENVENUTO_EXTRA,
        ];
        break;
      case FilterTag.TR_READING_WATCHING:
        return [Transactions_Types.USER_ACTIVITY];
        break;
      case FilterTag.TR_PURCHASE:
        return [Transactions_Types.DEAL_PURCHASE];
        break;
      case FilterTag.TR_ANSWERING:
        return [Transactions_Types.QUIZ_WON, Transactions_Types.RAKE];
        break;
      case FilterTag.TR_USER_PROFILE:
        return [Transactions_Types.USER_PROFILE_FIELD_UPDATE];
        break;
      case FilterTag.TR_EXTERNAL_ACTIVITY:
        return [
          Transactions_Types.EXTERNAL_ACTIVITY,
          Transactions_Types.SHARE_IN_SOCIAL_NETWORKS,
        ];
        break;
      case FilterTag.TR_ANNIVERSARIO_E_COMPLEANNO:
        return [
          Transactions_Types.INSTANT_REWARD,
          Transactions_Types.BUON_COMPLEANNO,
        ];
        break;
      case FilterTag.TR_INVITE:
        return [
          Transactions_Types.MGM,
          Transactions_Types.IGI_LAU,
          Transactions_Types.IGI_LAS,
          Transactions_Types.CGC_LAU,
          Transactions_Types.CGC_LSD,
        ];
        break;
      case FilterTag.TR_MISSIONI:
        //Missione Amici ?
        return [
          Transactions_Types.MOBILE_GAME_DOWNLOAD,
          Transactions_Types.USER_CONTENT_ACTIVITY_1,
          Transactions_Types.USER_CONTENT_ACTIVITY_2,
        ];
        break;
      case FilterTag.TR_PUNTI_EXTRA:
        return [
          // Rimborso punti (charge back)​
          // Dona punti (donation)​
          // Acquisti (purchases)​
          // Actions thorugh emails​
          // Scadenza punti (points expiration)

          Transactions_Types.COUPON_CHARGED_BACK,
          Transactions_Types.BALANCE_CONVERSION_TO_TOURNAMENT_PARTICIPATION,
          Transactions_Types.EXTERNAL_ACTIVITY_NEW,
          Transactions_Types.SALESFORCE_CLICK_MARKETING_MATERIAL,
          Transactions_Types.RESET_BALANCE,

          // Transactions_Types.EXTERNAL_ACTIVITY_NEW,
          // Transactions_Types.SALESFORCE_CLICK_MARKETING_MATERIAL,
          // Transactions_Types.PUNTI_EXTRA,
          // Transactions_Types.COUPON_CHARGED_BACK,
          // Transactions_Types.RESET_BALANCE,
          // Transactions_Types.AMOUNT_ADDED_BY_ADMIN,
          // Transactions_Types.E_WALLET_TRANSACTION_ROLLED_BACK,
          // Transactions_Types.BALANCE_CONVERSION_TO_TOURNAMENT_PARTICIPATION,
        ];
        break;
    }
    return [];
  }

  /**
   *
   * @param {FilterTag} filterType
   * @param {SortTag} sortType
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @return {Promise<UserHistoryResponse>}
   */
  getHistoryItemsBySelections(
    filterType: FilterTag,
    sortType: SortTag,
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<EWalletDTO>> {
    return new Promise((resolve, reject) => {
      // const getTransactionTypeId = (): Array<number> => {
      //   switch (Number(filterType)) {
      //     case FilterTag.ALL:
      //       return [];
      //       break;
      //     case FilterTag.TR_WELCOME:
      //       return [Transactions_Types.WELCOME_BONUS];
      //       break;
      //     case FilterTag.TR_READING_WATCHING:
      //       return [Transactions_Types.USER_ACTIVITY];
      //       break;
      //     case FilterTag.TR_PURCHASE:
      //       return [Transactions_Types.DEAL_PURCHASE];
      //       break;
      //     case FilterTag.TR_ANSWERING:
      //       return [Transactions_Types.QUIZ_WON];
      //       break;
      //     case FilterTag.TR_SHARE:
      //       return [Transactions_Types.SHARE_IN_SOCIAL_NETWORKS];
      //       break;
      //     case FilterTag.TR_USER_PROFILE:
      //       return [Transactions_Types.USER_PROFILE_FIELD_UPDATE];
      //       break;
      //     case FilterTag.TR_EXTERNAL_ACTIVITY:
      //       return [Transactions_Types.EXTERNAL_ACTIVITY];
      //       break;
      //     case FilterTag.TR_BENVENUTO_EXTRA:
      //       return [Transactions_Types.BENVENUTO_EXTRA];
      //       break;
      //     case FilterTag.TR_ANNIVERSARIO:
      //       return [Transactions_Types.BUON_COMPLEANNO];
      //       break;
      //     case FilterTag.TR_PUNTI_EXTRA:
      //       return [
      //         Transactions_Types.PUNTI_EXTRA,
      //         Transactions_Types.COUPON_CHARGED_BACK,
      //         Transactions_Types.RESET_BALANCE,
      //         Transactions_Types.EXTERNAL_ACTIVITY_NEW,
      //         Transactions_Types.AMOUNT_ADDED_BY_ADMIN,
      //         Transactions_Types.E_WALLET_TRANSACTION_ROLLED_BACK,
      //         Transactions_Types.INSTANT_REWARD,
      //         Transactions_Types.BALANCE_CONVERSION_TO_TOURNAMENT_PARTICIPATION,
      //       ];
      //       break;
      //   }
      //   return [];
      // };

      const orderType = this.utilsSrv.getOrderStringBySortType(sortType);
      const input: TransactionHistoryInput = {
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
        orderType: orderType,
        includeDetails: true,
        resources: true,
        metadatas: true,
        languageIds: LANGUAGES,
        excludeZeroAmounts: true,
        transactionTypeId: this.getTransactionTypeId(filterType),
        //descendingOrder: sortType == SortTag.RECENT,
        unitTypeId: [Unit_Types.IQOS_CLUB_IT_POINTS],
      };
      this.userSrv
        .getHistoryTransaction(TAValues.UserId, input)
        .then((result: Array<EWalletDTO>) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
    // const categoryIds: Array<string> = [];
    // if (filterType != FilterTag.ALL) {
    //     const filtercategoryId = this.getCategoryIdByType(filterType, APPLICATION_SETTING_KEYS.MATERIAL_FILTER_TYPE_IDS);
    //     if (filtercategoryId)
    //         categoryIds.push(filtercategoryId);
    // }
    //
    // const sort = this.utilsSrv.getOrderStringBySortType(sortType);
    // return this.getFavorites(categoryIds, sort, rangeFrom, rangeTo);
    // //return this.getFavorites(categoryIds, sort, rangeFrom, rangeTo);
  }

  async getMetadataForExprationDay(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.itemSrv
        .getItemMetadata()
        .then((metadata) => {
          logger.log(metadata);
          const metadataItemId: ItemMetadataDTO = metadata.find(
            (itemId) => itemId.itemId === Settings.GAME_TYPE_ID
          );
          const metadataExp: MetadataDTO = metadataItemId.metadata.find(
            (key) => key.key === ITEM_METADATA.KEY_BALANCE_EXPIRATION_POLICY
          );
          const value: IItemExpirationDate = !_.isNil(metadata)
            ? JSON.parse(metadataExp.value)
            : "";

          try {
            const arrayDay: string[] = value.expirationDate.split("T");
            const splitDay: string[] = arrayDay[0].split("-");
            // [0]yyyy [1]mm [2]dd
            const year: string = splitDay[0];
            const month: string = splitDay[1];
            const day: string = splitDay[2];
            resolve(`${day} / ${month} / ${year}`);
          } catch (e) {
            resolve("");
          }
        })
        .catch((error) => {
          logger.log(error);
          reject(error);
        });
    });
  }

  /**
   * Get Event reward rules
   * @param {Array<number>} eventTypeIds
   * @return {Promise<Map<number, string>>}
   */
  getEventRewardsRules(
    eventTypeIds: Array<number>
  ): Promise<Map<number, string>> {
    return new Promise((resolve, reject) => {
      this.eventSrv
        .getEventRewardRules(Settings.GAME_TYPE, eventTypeIds, true, LANGUAGES)
        .then((result: Array<EventRewardRulesDTO>) => {
          const eventRewards = new Map<number, string>();
          if (result) {
            result.forEach((e: EventRewardRulesDTO) => {
              let testString =
                e.rewards && e.rewards.length > 0
                  ? this.resourcesSrv.getResourcesBasic(
                      e.rewards[0],
                      Resource_Types.NAME
                    )
                  : "";
              if (testString == "") {
                testString =
                  e.rewards && e.rewards.length > 1
                    ? this.resourcesSrv.getResourcesBasic(
                        e.rewards[1],
                        Resource_Types.NAME
                      )
                    : "";
              }
              if (testString != "") {
                eventRewards[e.eventTypeId] = testString;
              }
            });
          }
          resolve(eventRewards);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get Event reward rules
   * @param {Array<number>} eventTypeIds
   * @return {Promise<Map<number, string>>}
   */
  saveExternalUser(
    authenticationSource: number,
    accessToken: string,
    accessTokenSecret?: string,
    facebookName?: string,
    facebookImage?: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const input: ExtSystemInfoDTO = {
        authenticationSource: authenticationSource,
        authToken: accessToken,
        authTokenSecret: accessTokenSecret,
        userId: facebookName,
      };

      const success = () => {
        this.userSrv.getUserProfile(TAValues.UserId);
        resolve("OK");
      };

      this.userSrv
        .saveExternalUser(TAValues.UserId, input)
        .then((result: any) => {
          if (!facebookImage) {
            success();
            return;
          }

          success();

          // this.userSrv.updatePicUrl(TAValues.UserId, facebookImage).then((res:any) => {
          //     success();
          // }).catch(err => {
          //     reject(err);
          // });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public async getBenefitsNew(
    rangeFrom: number,
    rangeTo: number,
    categoryId: number
  ): Promise<KpDictionary<IndexedDataContent>> {
    return new Promise<KpDictionary<IndexedDataContent>>(
      async (resolve, reject) => {
        let benefits = new KpDictionary<IndexedDataContent>();
        const input: FilterESContentDataInput = {
          categoryIds: [String(categoryId)],
          rangeFrom: rangeFrom,
          rangeTo: rangeTo,
          itemTypeIds: [107],
          currentlyActive: true,
          matchingRules: false,
          matchingLevel: true,
          sortOrderTypes: [ORDER_TYPE.BY_ORDERING_DESC],
        };
        const result = await this.recommendationsSrv
          .getRecommendationContent(TAValues.UserId, input, {
            resources: true,
            languageIds: TAConstants.Settings.LANGUAGES,
            metadatas: true,
          })
          .catch((err) => {});
        if (!result) {
          reject(null);
          return;
        }
        const benefitContents = result
          .sort((obj1, obj2) => {
            return obj2.minLevel - obj1.minLevel;
          })
          .filter((obj1) => {
            return obj1.minLevel <= this.userSrv.user.level.level;
          });
        benefitContents.map((respItem: IndexedDataContent) => {
          // respItem = this.formatProduct(respItem);
          // if (respItem.bundledProducts && isArray(respItem.bundledProducts)) {
          //     let formattedBundles: Array<LoyaltyBrandProductDTO> = [];
          //     respItem.bundledProducts.forEach(pb => {
          //         formattedBundles.push({
          //             ...this.formatProduct(pb as LoyaltyBrandProductDTO),
          //             isBundle: true,
          //             isListed: false,
          //         });
          //     });
          //     respItem.bundledProducts = formattedBundles;
          // }
          benefits.Add(respItem.itemId, respItem);
        });
        if (result[TAConstants.TAHeaders.ITEM_COUNT])
          benefits[TAConstants.TAHeaders.ITEM_COUNT] =
            result[TAConstants.TAHeaders.ITEM_COUNT];
        resolve(benefits);
      }
    );
  }

  getMissions(): Promise<MissionResponse> {
    return new Promise<MissionResponse>(async (resolve, reject) => {
      const achIds = [];
      let loginAchId: string;
      let loginWeeklyAchId: string;
      let contentAchIds: Array<string>;
      let referralAchId: string;
      let onBoardingAchId: string;
      const missionsConfig =
        this.appGlobalsSrv.config && this.appGlobalsSrv.config.profileConfig
          ? this.appGlobalsSrv.config.profileConfig.missions
          : null;
      if (missionsConfig) {
        if (missionsConfig.loginAchId && missionsConfig.loginAchId != "")
          loginAchId = missionsConfig.loginAchId;
        if (
          missionsConfig.loginWeeklyAchId &&
          missionsConfig.loginWeeklyAchId != ""
        )
          loginWeeklyAchId = missionsConfig.loginWeeklyAchId;
        if (missionsConfig.contentAchIds && missionsConfig.contentAchIds != "")
          contentAchIds = missionsConfig.contentAchIds;
        if (missionsConfig.referralAchId && missionsConfig.referralAchId != "")
          referralAchId = missionsConfig.referralAchId;
        if (
          missionsConfig.onBoardingAchId &&
          missionsConfig.onBoardingAchId != ""
        )
          onBoardingAchId = missionsConfig.onBoardingAchId;
      }
      if (loginAchId) achIds.push(loginAchId);
      if (loginWeeklyAchId) achIds.push(loginWeeklyAchId);
      if (contentAchIds) achIds.push(...contentAchIds);
      if (referralAchId) achIds.push(referralAchId);
      if (onBoardingAchId) achIds.push(onBoardingAchId);
      if (achIds.length == 0) {
        reject("No Ids");
        return;
      }

      const params: GetAchievementsParams = {
        achievementIds: achIds,
        rangeTo: achIds.length,
        // currentlyActive: true,
        active: true,
        includeDetails: true,
        resources: true,
        metadatas: true,
        languageIds: LANGUAGES,
        orderType: TAConstants.ORDER_TYPE.BY_ORDERING_ASC,
      };
      this.achievemtSrv
        .getAchievements(params)
        .then((result) => {
          if (!result || result.length == 0) {
            resolve(null);
            return;
          }
          let response: MissionResponse = { contentAchs: [] };
          result.forEach((r) => {
            this.setupMission(r);
            if (r.achievementId == loginAchId) response.loginAch = r;
            else if (r.achievementId == loginWeeklyAchId)
              response.loginWeeklyAch = r;
            else if (r.achievementId == referralAchId) response.referralAch = r;
            else if (contentAchIds && contentAchIds.includes(r.achievementId))
              response.contentAchs.push(r);
            else if (r.achievementId == onBoardingAchId)
              response.onBoardingAch = r;
          });
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getContents(
    ids: Array<string>,
    categoryIds?: Array<string>
  ): Promise<Array<MaterialObject>> {
    return new Promise((resolve, reject) => {
      const articleCat = this.materialSrv.getCategoryIdByType(
        MaterialType.ARTICLE,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const videoCat = this.materialSrv.getCategoryIdByType(
        MaterialType.VIDEO,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const quizCat = this.materialSrv.getCategoryIdByType(
        MaterialType.QUIZ,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const quickCat = this.materialSrv.getCategoryIdByType(
        MaterialType.QUICK_REPLY,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const gameCat = this.materialSrv.getCategoryIdByType(
        MaterialType.GAME,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const getMaterialType = (item: IndexedDataContent): MaterialType => {
        let result: MaterialType;
        if (item.categoryIds) {
          if (item.categoryIds.find((c) => c == articleCat)) {
            result = MaterialType.ARTICLE;
          } else if (item.categoryIds.find((c) => c == videoCat)) {
            result = MaterialType.VIDEO;
          } else if (item.categoryIds.find((c) => c == quickCat)) {
            result = MaterialType.QUICK_REPLY;
          } else if (item.categoryIds.find((c) => c == gameCat)) {
            result = MaterialType.GAME;
          } else if (item.categoryIds.find((c) => c == quizCat)) {
            if (item.contentReferencedType) {
              if (item.contentReferencedType.typeId == String(QUIZ_TYPES.QUIZ))
                result = MaterialType.QUIZ;
              else if (
                item.contentReferencedType.typeId == String(QUIZ_TYPES.SURVEY)
              )
                result = MaterialType.SURVEY;
            }
          }
        }

        if (!result && item.itemTypeId == ITEM_TYPE.QUIZ) {
          if (item.contentReferencedType) {
            if (item.contentReferencedType.typeId == String(QUIZ_TYPES.QUIZ))
              result = MaterialType.QUIZ;
            else if (
              item.contentReferencedType.typeId == String(QUIZ_TYPES.SURVEY)
            )
              result = MaterialType.SURVEY;
          }
        }

        if (!result && item.metadata) {
          const materialType = this.utilsSrv.valueFromKeyValueDTOArray(
            METADATA_KEY.UPLOAD_CONTENT_UI_COMPONENT_TYPE,
            item.metadata
          );
          if (materialType && materialType != "") {
            result = Number(materialType);
          }
        }

        return result;
      };

      let input: FilterESContentDataInput = {
        categoryIds: categoryIds || null,
        itemIds: ids,
        itemTypeIds: [ITEM_TYPES.UPLOAD_CONTENT, ITEM_TYPES.QUIZ],
        rangeFrom: 0,
        rangeTo: ids.length,
        currentlyRewarding: null,
        sortOrderTypes: [Order_Stores.BY_RECENT],
        countMode: false,
        userRewarded: null,
        currentlyActive: true,
        matchingLevel: true,
        matchingRules: true,
      };
      Promise.all([
        this.materialSrv.getEventRewardsRules(),
        this.recommendationsSrv.getRecommendationContent(
          TAValues.UserId,
          input,
          {
            resources: true,
            languageIds: TAConstants.Settings.LANGUAGES,
            metadatas: true,
          }
        ),
        this.materialSrv.getMaterialFiltersGuadagna(),
      ])
        .then(async (arrayOfResults) => {
          if (arrayOfResults && arrayOfResults.length == 3) {
            const objects: Array<OverviewGenericContent> =
              arrayOfResults[1] || [];
            // if (objects.length < 4) {
            //     input.userRewarded = true;
            //     input.currentlyRewarding = undefined;
            //     input.rangeTo = 4 - objects.length;
            //     await this.recommendationsSrv.getRecommendationContent(TAValues.UserId, input, {
            //         resources: true,
            //         metadatas: true,
            //         languageIds: TAConstants.Settings.LANGUAGES
            //     }).then((res: Array<OverviewGenericContent>) => {
            //         if (res) {
            //             objects.push(...res);
            //         }
            //     }).catch(err => {
            //     });
            // }
            if (objects.length > 0) {
              const promise = (
                item: IndexedDataContent
              ): Promise<Array<ItemActionStatusDTO>> => {
                const input: GetUserActionStatusInput = {
                  itemIds: [item.itemId],
                  itemTypeId: item.itemTypeId,
                  unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
                  includeLimitations: true,
                };
                const type = getMaterialType(item);
                if (type) {
                  let eventTypeId: number;
                  switch (type) {
                    case MaterialType.ARTICLE:
                    case MaterialType.QUICK_REPLY:
                      eventTypeId = Events_Types.ITEM_VIEWED;
                      break;
                    case MaterialType.VIDEO:
                      eventTypeId = Events_Types.VIDEO_WATCHED;
                      break;
                    case MaterialType.QUIZ:
                      eventTypeId = Events_Types.QUIZ_COMPLETION;
                      break;
                    case MaterialType.SURVEY:
                      eventTypeId = Events_Types.POLL_SURVEY_COMPLETED;
                      break;
                    case MaterialType.GAME:
                      eventTypeId = Events_Types.GAME_FINISHED;
                      break;
                  }
                  if (eventTypeId)
                    return this.itemSrv.getUserActions(
                      eventTypeId,
                      null,
                      input
                    );
                }
              };

              const promises = objects.map((o) => {
                return promise(o);
              });
              const results = await Promise.all(promises).catch((err) => {});
              if (results) {
                results.forEach((r) => {
                  if (r) {
                    r.forEach((a) => {
                      objects.forEach((i: OverviewGenericContent) => {
                        if (
                          a.itemId == i.itemId &&
                          a.actionsStatus &&
                          a.actionsStatus.length > 0
                        ) {
                          i.actionsStatus = a.actionsStatus[0];
                        }
                      });
                    });
                  }
                });
              }
            }
            const materialConfig = this.getMaterialConfig();
            const items = objects.map((i) => {
              const type = getMaterialType(i);
              if (type) {
                const o = this.utilsSrv.materialObjectByOverviewContent(
                  i,
                  type,
                  arrayOfResults[2],
                  materialConfig[
                    APPLICATION_SETTING_KEYS.MATERIAL_PERSONALIZED_CATEGORY_IDS
                  ],
                  this.userSrv.user.firstPurchaseDate
                );
                return o;
              } else return null;
            });
            resolve(items);
            return;
          }
          resolve(null);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setupMissionContents(a: ProfileMissionContent) {
    if (a && a.contents) {
      a.contents.forEach((c) => {
        c.active = false;
        if (c.completed) {
          c.active = true;
          a.current = null;
          a.finished = c;
        } else {
          if (!a.current) {
            c.active = true;
            a.current = c;
          }
        }
      });
    }

    if (a.current && a.finished && a.finished.nextActionDays > 0) {
      const now = new Date();
      const end = new Date(a.finished.completedOn);
      end.setDate(end.getDate() + a.finished.nextActionDays);
      end.setHours(0, 0, 0, 0);
      if (now.getTime() < end.getTime()) {
        a.current.active = false;
        a.current.nextActionDaysLabel = this.translateSrv
          .instant("ONBOARDING_NEXT_ACTION")
          .replace("$date", Moment(end).format("DD/MM/YY"));
      }
    }
  }

  setupMissionUI(mission: ProfileMissionContent) {
    this.setupMissionContents(mission);
    this.setupMission(mission);
    this.isEligible(mission);
    this.getCountDown(mission);
    this.getPointsEarned(mission);
  }

  getPointsEarned(mission: ProfileMissionContent) {
    let points: number = 0;
    if (mission && mission.contents && mission.contents.length > 0) {
      mission.contents.forEach((c) => {
        if (c.completed && c.pointsTag && c.pointsTag.variant == "success") {
          if (c.pointsTag.points) points += c.pointsTag.points;
        }
      });
    }
    mission.pointsEarned = points;
  }

  adjustForTimeZone(
    now: Moment.Moment,
    missionDate: Moment.Moment
  ): Moment.Moment {
    // Get local time zone offset in hours
    const localOffset = now.utcOffset() / 60; // GMT offset in hours
    // Get mission time zone offset in hours
    const missionOffset = missionDate.utcOffset() / 60; // GMT offset in hours

    // Calculate the time difference
    const timeDiff = localOffset - missionOffset;

    if (timeDiff) {
      const diff = Math.abs(timeDiff);
      // Adjust the mission date based on the time difference
      return timeDiff > 0
        ? missionDate.subtract(diff, "hours") // If local time is ahead, subtract hours
        : missionDate.add(diff, "hours"); // If local time is behind, add hours
    }

    return missionDate;
  }

  getCountDown(mission: ProfileMissionContent) {
    if (!this.userSrv.user.eligible) return null;
    const now = Moment();
    let result: Date;

    const getDaysToComplete = () => {
      const end = Moment(mission.contents[0].completedOn);
      let daysToComplete = end.add(30, "days");

      return this.adjustForTimeZone(now, daysToComplete).toDate();
    };
    const getDaysToStart = () => {
      let anni =
        mission && mission.userAchievement
          ? Moment(mission.userAchievement.missionStartDate)
          : null;
      if (!anni) return null;

      let daysToStart = anni.add(60, "days"); // IE-619: changed to 60 days. Prev: 30
      return this.adjustForTimeZone(now, daysToStart).toDate();
    };

    result =
      mission.contents &&
      mission.contents.length > 0 &&
      mission.contents[0].completed
        ? getDaysToComplete()
        : getDaysToStart();
    if(!result) return null;
    var eventdate = Moment(result);
    var todaysdate = Moment();
    const date = eventdate.format("DD/MM/YY");
    const days = eventdate.diff(todaysdate, "days");
    if (mission.descriptionShort) {
      mission.descriptionShort = mission.descriptionShort
        .replace("$days", String(days))
        .replace("$date", date);
    }
    mission.countDown = result;
  }

  isEligible(mission: ProfileMissionContent) {
    if (!this.userSrv.user.eligible) return;

    const now = new Date();

    if (!mission.userAchievement) {
      mission.isEligible = false;
      return;
    }

    if (
      !(
        mission.userAchievement.missionStatus == 0 ||
        mission.userAchievement.missionStatus == 1
      ) ||
      mission.userAchievement.missionActiveTo < now.getTime()
    ) {
      mission.isEligible = false;
      return;
    }

    let anniString =
      this.userSrv.user.user.customUserProfileFields[
        METADATA_KEY.SALESFORCE_FIRST_PURCHASE_DATE
      ];
    let anni =
      !_.isNil(anniString) && anniString != ""
        ? Moment(anniString, "YYYY-MM-DD HH:mm:ss").toDate()
        : null;
    if (!anni) {
      mission.isEligible = false;
      return;
    }

    // now.setDate(now.getDate() - 30);

    const daysToStart = new Date();
    daysToStart.setDate(daysToStart.getDate() - 60); // IE-619: changed to 60 days. Prev: 30

    const daysToComplete = new Date();
    daysToComplete.setDate(daysToComplete.getDate() - 30); // IE-619: NO changes to the amount of days given to complete the mission

    if (
      mission.contents &&
      mission.contents.length > 0 &&
      mission.contents[0].completed
    ) {
      try {
        const end = new Date(mission.contents[0].completedOn);
        if (end.getTime() < daysToComplete.getTime()) {
          mission.isEligible = false;
          return;
        }
      } catch (err) {
        mission.isEligible = false;
        return;
      }
    } else {
      if (anni.getTime() < daysToStart.getTime()) {
        mission.isEligible = false;
        return;
      }
    }

    mission.isEligible = true;
  }

  getProfileMission(
    item: MaterialObject,
    a: ProfileMissionContent,
    index: number
  ): ProfileMission {
    const checkNextActionDays = (
      mission: ProfileMissionContent,
      index: number
    ): number => {
      let result = 0;

      if (
        mission &&
        mission.userAchievement &&
        mission.userAchievement.eventAchievementActions &&
        mission.userAchievement.eventAchievementActions.length > index
      ) {
        const days = this.resourcesSrv.getResourcesBasic(
          mission.userAchievement.eventAchievementActions[index],
          Resource_Types.DETAILS
        );
        if (days && days != "") result = Number(days);
      }

      return result;
    };

    const checkIfActionCompleted = (
      mission: ProfileMissionContent,
      index: number
    ): boolean => {
      let result = false;

      if (
        mission &&
        mission.userAchievement &&
        mission.userAchievement.eventAchievementActions &&
        mission.userAchievement.eventAchievementActions.length > index
      ) {
        result =
          mission &&
          mission.userAchievement &&
          mission.userAchievement.eventAchievementActions &&
          mission.userAchievement.eventAchievementActions[index].completed;
      }

      return result;
    };

    const checkCompletedOn = (
      mission: ProfileMissionContent,
      index: number
    ): number => {
      let result = null;

      if (
        mission &&
        mission.userAchievement &&
        mission.userAchievement.eventAchievementActions &&
        mission.userAchievement.eventAchievementActions.length > index
      ) {
        result =
          mission &&
          mission.userAchievement &&
          mission.userAchievement.eventAchievementActions &&
          mission.userAchievement.eventAchievementActions[index].completedOn;
      }

      // const now = new Date();
      // now.setDate(now.getDate() - 30);
      // return now.getTime();
      return result;
    };

    const i: ProfileMission = {
      title: item.title,
      image: item.image ? item.image.path : null,
      id: item.item_id,
      published: true,
      completed: checkIfActionCompleted(a, index),
      completedOn: checkCompletedOn(a, index),
      nextActionDays: checkNextActionDays(a, index),
      action: this.resourcesSrv.getResourcesBasic(
        item.reference,
        TAConstants.Resource_Types.ACTION_TYPE
      ),
      actionLabel: this.resourcesSrv.getResourcesBasic(
        item.reference,
        TAConstants.Resource_Types.ACTION_TYPE_DESCRIPTION
      ),
      category: item.category,
      categoryIcon: item.categoryIcon,
      pointsTag: item.pointsTag,
      // action: item.actionButton? .actionButton.action:null
    };

    return i;
  }

  getIndirectUserData(
    mode: number,
    queryParams?: GetIndirectUserDataQuery,
    requireSession_: boolean = true
  ): Promise<Array<IndirectUserDataDTO>> {
    return new Promise((resolve, reject) => {
      this.indirectDataSrv
        .getIndirectUserData(
          TAValues.UserId,
          mode,
          queryParams,
          requireSession_
        )
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setupMission(item: ProfileMissionContent) {
    item.title = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.TITLE
    );
    item.description = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.DESCRIPTION
    );
    item.descriptionShort = this.resourcesSrv.getResourcesBasic(
      item,
      item.finished
        ? Resource_Types.SHORT_DESCRIPTION
        : Resource_Types.SUMMARY_TEXT
    );
    item.text = this.resourcesSrv.getResourcesBasic(item, Resource_Types.TEXT);
    item.rewardDescription = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.REWARD_DESCRIPTION
    );
    item.details = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.DETAILS
    );
    item.image = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.IMAGE
    );
    const reward = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.CONTENT_1
    );
    if (reward && reward != "") item.reward = Number(reward);
    const totalCount = this.resourcesSrv.getResourcesBasic(
      item,
      Resource_Types.CONTENT_2
    );
    if (totalCount && totalCount != "") item.totalCount = Number(totalCount);
    if (item.completionValue && item.completionValue > 0)
      item.numbers = Array(item.completionValue)
        .fill(0)
        .map((x, i) => i);
  }
} // END CLASS

export interface ILevelImage {
  level: number;
  levelImageActive: string;
  levelImageInactive: string;
}

export interface IItemExpirationDate {
  batchSize?: number;
  expirationDate?: string;
  expiredPointsUnitTypeId?: number;
  tournamentId?: string;
  unitTypeId?: number;
}

export interface IMessageCategory extends Message {
  title?: string;
  category?: string | number;
  message?: string;
}

export interface MissionResponse {
  loginAch?: ProfileMissionContent;
  loginWeeklyAch?: ProfileMissionContent;
  contentAchs?: Array<ProfileMissionContent>;
  referralAch?: ProfileMissionContent;
  onBoardingAch?: ProfileMissionContent;
}

export interface Mission extends AchievementDTO {
  title?: string;
  description?: string;
  descriptionShort?: string;
  text?: string;
  rewardDescription?: string;
  reward?: number;
  totalCount?: number;
  details?: string;
  image?: string;
  numbers?: Array<number>;
}

export interface ActionConfig {
  key?: string;
  vmTransactionTypeIds?: Array<number>;
  action?: string;
  type?: number;
  total?: number;
  progressTotal?: number;
  periodTypeId?: number;
}

export interface ProfileAction extends EWalletSummaryPerVmTransactionTypeTO {
  key?: string;
  title?: string;
  description?: string;
  actionLabel?: string;
  text?: string;
  pointsText?: string;
  action?: string;
  progressValue?: number;
}
export interface ProfileMission {
  title?: string;
  image?: string;
  action?: string;
  actionLabel?: string;
  published?: boolean;
  categoryIcon?: string;
  category?: string;
  id?: string;
  completed?: boolean;
  completedOn?: number;
  nextActionDays?: number;
  nextActionDaysLabel?: string;
  active?: boolean;
  pointsTag?: {
    label: string;
    variant: "info" | "success";
    disabled?: boolean;
    points?: number;
  };
}

export interface ProfileMissionContent extends Mission {
  title?: string;
  description?: string;
  rewardDescription?: string;
  contents?: Array<ProfileMission>;
  completed?: boolean;
  id?: string;
  finished?: ProfileMission;
  current?: ProfileMission;
  isEligible?: boolean;
  countDown?: Date;
  pointsEarned?: number;
}
