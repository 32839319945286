import { Injectable } from "@angular/core";
import { CategoryDTO } from "../talosApi/models/CategoryDTO";
import { QuizFilterItem } from "../app/pages/material/material.service";
import {
  ExtendedBrandProductDTO,
  ExtendedCategoryDTO,
} from "src/app/pages/rewards/rewards.service";
import { isNull } from "lodash";
import { MaterialObject } from "../models/MaterialObject";
import { KpDictionary } from "./kp_dictionary/kp.dictionary";

@Injectable({
  providedIn: "root",
})
export class CachingService {
  private _filterCategoriesById: Map<string, Array<CategoryDTO>> = new Map<
    string,
    Array<CategoryDTO>
  >();
  private _filterProductById: Map<
    number,
    { categoryId: number; count: number }
  > = new Map<number, { categoryId: number; count: number }>();
  private _filterCouponById: Map<
    number,
    { categoryId: number; count: number }
  > = new Map<number, { categoryId: number; count: number }>();
  private _filterQuizzes: Array<QuizFilterItem>;
  private _appExtendedCategories: Map<string, ExtendedCategoryDTO> = new Map<
    string,
    ExtendedCategoryDTO
  >();
  private _appPreviousPage: Map<string, number> = new Map<string, number>();
  private _userHasBought: boolean = null;

  private _loadedContents: {
    items: Array<MaterialObject>;
    itemId: string;
    currentItems?: number;
    totalItems?: number;
    sort?: string;
    tag?: string;
    format?: string;
  };
  private _loadedArticles: {
    items: Array<MaterialObject>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  };
  private _loadedVideos: {
    items: Array<MaterialObject>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  };
  private _loadedQuizzes: {
    items: Array<MaterialObject>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  };
  private _loadedProducts: {
    items: KpDictionary<ExtendedBrandProductDTO>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  };
  private _loadedCoupons: {
    items: KpDictionary<ExtendedBrandProductDTO>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  };

  get loadedContents(): {
    items: Array<MaterialObject>;
    itemId: string;
    currentItems?: number;
    totalItems?: number;
    sort?: string;
    tag?: string;
    format?: string;
  } {
    return this._loadedContents;
  }

  set loadedContents(value: {
    items: Array<MaterialObject>;
    itemId: string;
    currentItems?: number;
    totalItems?: number;
    sort?: string;
    tag?: string;
    format?: string;
  }) {
    this._loadedContents = value;
  }

  get loadedArticles(): {
    items: Array<MaterialObject>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  } {
    return this._loadedArticles;
  }

  set loadedArticles(value: {
    items: Array<MaterialObject>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  }) {
    this._loadedArticles = value;
  }

  get loadedVideos(): {
    items: Array<MaterialObject>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  } {
    return this._loadedVideos;
  }

  set loadedVideos(value: {
    items: Array<MaterialObject>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  }) {
    this._loadedVideos = value;
  }

  get loadedQuizzes(): {
    items: Array<MaterialObject>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  } {
    return this._loadedQuizzes;
  }

  set loadedQuizzes(value: {
    items: Array<MaterialObject>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  }) {
    this._loadedQuizzes = value;
  }

  get loadedProducts(): {
    items: KpDictionary<ExtendedBrandProductDTO>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  } {
    return this._loadedProducts;
  }

  set loadedProducts(value: {
    items: KpDictionary<ExtendedBrandProductDTO>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  }) {
    this._loadedProducts = value;
  }

  get loadedCoupons(): {
    items: KpDictionary<ExtendedBrandProductDTO>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  } {
    return this._loadedCoupons;
  }

  set loadedCoupons(value: {
    items: KpDictionary<ExtendedBrandProductDTO>;
    itemId: string;
    totalItems?: number;
    sort?: string;
    tag?: string;
  }) {
    this._loadedCoupons = value;
  }

  get userHasBought(): boolean | null {
    return this._userHasBought;
  }

  set userHasBought(value: boolean) {
    this._userHasBought = value;
  }

  get appPreviousPage(): Map<string, number> {
    return this._appPreviousPage;
  }

  set appPreviousPage(value: Map<string, number>) {
    this._appPreviousPage = value;
  }

  get appExtendedCategories(): Map<string, ExtendedCategoryDTO> {
    return this._appExtendedCategories;
  }

  set appExtendedCategories(value: Map<string, ExtendedCategoryDTO>) {
    this._appExtendedCategories = value;
  }

  public setAppExtendedCategories(key: string, value: ExtendedCategoryDTO) {
    if (!key || !key.length || isNull(value)) return;
    this._appExtendedCategories.set(key, value);
  }

  get filterCategoriesById(): Map<string, Array<CategoryDTO>> {
    return this._filterCategoriesById;
  }

  set filterCategoriesById(value: Map<string, Array<CategoryDTO>>) {
    this._filterCategoriesById = value;
  }

  get filterProductById(): Map<number, { categoryId: number; count: number }> {
    return this._filterProductById;
  }

  set filterProductById(
    value: Map<number, { categoryId: number; count: number }>
  ) {
    this._filterProductById = value;
  }

  get filterCouponById(): Map<number, { categoryId: number; count: number }> {
    return this._filterCouponById;
  }

  set filterCouponById(
    value: Map<number, { categoryId: number; count: number }>
  ) {
    this._filterCouponById = value;
  }

  get filterQuizzes(): Array<QuizFilterItem> {
    return this._filterQuizzes;
  }

  set filterQuizzes(value: Array<QuizFilterItem>) {
    this._filterQuizzes = value;
  }

  checkIfMustRemoveCache(path: string) {
    logger.log(path);
    if (!(path == "material" || path.indexOf("article") >= 0))
      this.loadedArticles = null;
    if (!(path == "material" || path.indexOf("video") >= 0))
      this.loadedVideos = null;
    if (!(path == "material" || path.indexOf("quiz") >= 0))
      this.loadedQuizzes = null;
    if (!(path == "rewards" || path.indexOf("catalog") >= 0))
      this.loadedProducts = null;
    if (!(path == "rewards" || path.indexOf("your-rewards") >= 0))
      this.loadedCoupons = null;
    if (
      !(
        path == "tutti-contenuti" ||
        path.indexOf("article") >= 0 ||
        path.indexOf("video") >= 0 ||
        path.indexOf("quiz") >= 0 ||
        path.indexOf("quick-reply") >= 0 ||
        path.indexOf("game") >= 0
      )
    )
      this.loadedContents = null;
  }
}
