import { TAConstants, TAValues } from "./settings";
import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import TAHeaders = TAConstants.TAHeaders;
import Moment from "moment";
import { RequestOptionsArgs, ResponseContentType } from "@angular/http";
import { TalosBaseModel } from "./models/TalosBaseModel";
import { HttpObserve } from "@angular/common/http/src/client";
import { TranslateService } from "@ngx-translate/core";
import { NetWork } from "./network";
import Talos_Code = NetWork.Talos_Code;
import * as CryptoJS from "crypto-js";

@Injectable()
export class Http2Talos {
  DEFAULT_ENCRYPTION = true;
  INACTIVE_USER = [
    Talos_Code.USER_SESSION_ID_NOT_VALID,
    Talos_Code.USER_SESSION_PERMISSION_ERROR,
    Talos_Code.INACTIVE_USER,
  ];

  constructor(
    private http: HttpClient,
    private injector: Injector,
    protected translate: TranslateService
  ) {}

  private getHeaders(
    path: string,
    params?: any,
    data_?: any,
    key?: string,
    headers?: HttpHeaders,
    requireSession?: boolean
  ): { headers: HttpHeaders; uRLSearchParams: HttpParams } {
    let result: HttpHeaders = headers;
    if (!result) result = new HttpHeaders();

    const currentTime: string =
      result.get(TAConstants.TAHeaders.TIMESTAMP) ||
      Moment(new Date()).format("YYYY-MM-DTHH:mm:ss.SSSZZ");

    let values: any[] = [];
    values.push("uri=/" + TAConstants.Settings.API_VERSION + path);
    values.push("ts=" + encodeURIComponent(currentTime));
    let getParams = new HttpParams();
    if (params !== undefined) {
      for (let key in params) {
        // logger.log(key);
        if (params[key] === null || params[key] === undefined) continue;

        if (params[key].constructor === Array) {
          for (let value of params[key]) {
            values.push(key + "=" + encodeURIComponent(value));
            getParams = getParams.append(key, value);
          }
        } else {
          values.push(key + "=" + encodeURIComponent(params[key]));
          getParams = getParams.append(key, params[key]);
        }
      }
    }
    if (data_) {
      const json: any =
        typeof data_ === "string" || data_ instanceof String
          ? data_.toString()
          : JSON.stringify(data_);

      // const json: any = JSON.stringify(data_);

      const sha1_ = this.b64_sha1(json);
      // logger.log(sha1_);
      const urlEnc = encodeURIComponent(sha1_);
      values.push("b=" + urlEnc);
      // logger.log(urlEnc);
    }
    values.sort();
    let finalString = "";
    values.forEach((value) => {
      finalString += value + "&";
    });
    finalString += "k=" + key;
    const signature = this.b64_hmac_sha1(key, finalString);
    //NOT TO SET FROM OUTSIDE
    result = result.append(TAConstants.TAHeaders.SIGNATURE, signature);
    result = result.append(TAConstants.TAHeaders.TIMESTAMP, currentTime);
    result = result.append(
      TAConstants.TAHeaders.TALOS_VERSION_ID,
      TAConstants.Settings.VERSION_ID
    );

    try {
      if (result.has(TAConstants.TAHeaders.LANGUAGE) === false)
        result = result.append(
          TAConstants.TAHeaders.LANGUAGE,
          this.translate.currentLang
        );

      if (result.has(TAConstants.TAHeaders.CONTENT_TYPE) === false)
        result = result.append(
          TAConstants.TAHeaders.CONTENT_TYPE,
          "application/json;charset=utf-8"
        );

      if (result.has(TAConstants.TAHeaders.USER_GROUP_ID) === false)
        result = result.append(
          TAConstants.TAHeaders.USER_GROUP_ID,
          TAConstants.Settings.USERGROUP_ID
        );
      if (result.has(TAConstants.TAHeaders.APPLICATION_ID) === false)
        result = result.append(
          TAConstants.TAHeaders.APPLICATION_ID,
          TAValues.APPLICATION_ID
        );
      if (
        result.has(TAConstants.TAHeaders.SESSION_ID) === false &&
        TAValues.UserSessionId != undefined
      )
        result = result.append(
          TAConstants.TAHeaders.SESSION_ID,
          TAValues.UserSessionId
        );
    } catch (eee) {
      logger.log(eee);
    }

    return { headers: result, uRLSearchParams: getParams };
  }

  private request(request: IHttp2TalosReq): Promise<any> {
    const result = this.getHeaders(
      request.path,
      request.queryParams,
      request.input,
      TAValues.MAGIC_KEY,
      request.headers,
      request.requireSession
    );

    const urlToCall: string = request.isV1
      ? TAConstants.Settings.SERVER_V1 + request.path
      : TAConstants.Settings.SERVER + request.path;
    // const reqOptions: RequestOptionsArgs = {
    //   method: RequestOptionsArgs.method,
    //   headers: result.headers,
    //   search: result.uRLSearchParams,
    //   params: request.queryParams,
    //   body: request.input,
    //   responseType: ResponseContentType.Json
    // }

    const options = {
      body: request.input,
      headers: result.headers,
      params: result.uRLSearchParams,
      observe: "response" as HttpObserve,
      responseType: "text" as "arraybuffer" | "blob" | "json" | "text",
    };

    return new Promise((resolve, reject) => {
      this.http.request(request.method, urlToCall, options).subscribe(
        (response) => {
          //logger.log("response", response);

          const sendResult = (res: string) => {
            let js = JSON.parse(res);
            let headers = response.headers;
            if (headers && headers.has(TAHeaders.ITEM_COUNT)) {
              if (!js) js = [];
              js[TAHeaders.ITEM_COUNT] = headers.get(TAHeaders.ITEM_COUNT);
            }
            resolve(js);
          };

          const checkEncryption = (): boolean => {
            let headers = response.headers;
            if (headers && headers.has(TAHeaders.ENCRYPTED)) {
              return headers.get(TAHeaders.ENCRYPTED) == "true";
            }
            return false;
          };
          try {
            if (
              response &&
              response.body &&
              response.body != "" &&
              checkEncryption() &&
              !request.disableEncryption
            ) {
              // if (response && response.body && response.body != "" && true && !request.disableEncryption) {
              // checkEncryption();

              const key =
                request.encryptionKey || TAConstants.Settings.ENCRYPTION_KEY;
              const iv =
                request.encryptionIV || TAConstants.Settings.ENCRYPTION_IV;
              const res = this.decrypt(response.body, key, iv);
              try {
                sendResult(res);
              } catch (err) {
                resolve(res);
              }
            } else {
              sendResult(response.body);
            }
          } catch (e) {
            //TODO string
            resolve(response.body);
          }
        },
        (error) => {
          if (
            error &&
            error.error &&
            error.error.code &&
            this.INACTIVE_USER.indexOf(error.error.code) >= 0
          ) {
            TAValues.UserId = null;
            TAValues.UserSessionId = null;

            window.open(TAValues.INACTIVE_REDIRECTION_LINK, "_parent");
          }
          reject(error || "Server error");
        }
      );

      // this.http.request(request.method, urlToCall, options).subscribe((response: Response) => {
      //   let js = response;
      //   let headers = response.headers;
      //   if (headers && headers.has(TAHeaders.ITEM_COUNT))
      //     js[TAHeaders.ITEM_COUNT] = headers.get(TAHeaders.ITEM_COUNT);
      //   resolve(js);
      // }, error =>{
      //   reject(error || 'Server error');
      // });
    });
  }

  private decrypt(
    encryptedMessage: string,
    encryptionKey: string,
    encryptionIV: string
  ): string {
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const iv = CryptoJS.enc.Utf8.parse(encryptionIV);

    const decrypted = CryptoJS.AES.decrypt(encryptedMessage, key, {
      keySize: encryptionKey.length,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  public get(request: IHttp2TalosReq): Promise<any> {
    request.method = "GET";
    return this.request(request);
    // return this.checkRequireSession(request);
  }

  public post(request: IHttp2TalosReq): Promise<any> {
    request.method = "POST";
    return this.request(request);
    // return this.checkRequireSession(request);
  }

  public put(request: IHttp2TalosReq): Promise<any> {
    request.method = "PUT";
    return this.request(request);
    // return this.checkRequireSession(request);
  }

  public options(request: IHttp2TalosReq): Promise<any> {
    request.method = "OPTIONS";
    return this.request(request);
    // return this.checkRequireSession(request);
  }

  public delete(request: IHttp2TalosReq): Promise<any> {
    request.method = "DELETE";
    return this.request(request);
    // return this.checkRequireSession(request);
  }

  public head(request: IHttp2TalosReq): Promise<any> {
    request.method = "HEAD";
    return this.request(request);
    // return this.checkRequireSession(request);
  }

  public patch(request: IHttp2TalosReq): Promise<any> {
    request.method = "PATCH";
    return this.request(request);
    // return this.checkRequireSession(request);
  }

  b64_sha1(s) {
    return this.rstr2b64(this.rstr_sha1(this.str2rstr_utf8(s)));
  }

  rstr_sha1(s) {
    return this.binb2rstr(this.binb_sha1(this.rstr2binb(s), s.length * 8));
  }

  rstr2b64(input) {
    let tab =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    let output: string = "";
    let len = input.length;
    for (let i = 0; i < len; i += 3) {
      let triplet =
        (input.charCodeAt(i) << 16) |
        (i + 1 < len ? input.charCodeAt(i + 1) << 8 : 0) |
        (i + 2 < len ? input.charCodeAt(i + 2) : 0);
      for (let j = 0; j < 4; j++) {
        if (i * 8 + j * 6 > input.length * 8) output += "=";
        else output += tab.charAt((triplet >>> (6 * (3 - j))) & 0x3f);
      }
    }
    return output;
  }

  binb2rstr(input) {
    let output: string = "";
    for (let i = 0; i < input.length * 32; i += 8)
      output += String.fromCharCode((input[i >> 5] >>> (24 - (i % 32))) & 0xff);
    return output;
  }

  str2rstr_utf8(input) {
    let output: string = "";
    let i: number = -1;
    let x, y;

    while (++i < input.length) {
      /* Decode utf-16 surrogate pairs */
      x = input.charCodeAt(i);
      y = i + 1 < input.length ? input.charCodeAt(i + 1) : 0;
      if (0xd800 <= x && x <= 0xdbff && 0xdc00 <= y && y <= 0xdfff) {
        x = 0x10000 + ((x & 0x03ff) << 10) + (y & 0x03ff);
        i++;
      }

      /* Encode output as utf-8 */
      if (x <= 0x7f) output += String.fromCharCode(x);
      else if (x <= 0x7ff)
        output += String.fromCharCode(
          0xc0 | ((x >>> 6) & 0x1f),
          0x80 | (x & 0x3f)
        );
      else if (x <= 0xffff)
        output += String.fromCharCode(
          0xe0 | ((x >>> 12) & 0x0f),
          0x80 | ((x >>> 6) & 0x3f),
          0x80 | (x & 0x3f)
        );
      else if (x <= 0x1fffff)
        output += String.fromCharCode(
          0xf0 | ((x >>> 18) & 0x07),
          0x80 | ((x >>> 12) & 0x3f),
          0x80 | ((x >>> 6) & 0x3f),
          0x80 | (x & 0x3f)
        );
    }
    return output;
  }

  binb_sha1(x, len) {
    /* append padding */
    x[len >> 5] |= 0x80 << (24 - (len % 32));
    x[(((len + 64) >> 9) << 4) + 15] = len;

    let w = Array(80);
    let a = 1732584193;
    let b = -271733879;
    let c = -1732584194;
    let d = 271733878;
    let e = -1009589776;

    for (let i = 0; i < x.length; i += 16) {
      let olda = a;
      let oldb = b;
      let oldc = c;
      let oldd = d;
      let olde = e;

      for (let j = 0; j < 80; j++) {
        if (j < 16) w[j] = x[i + j];
        else
          w[j] = this.bit_rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1);
        let t = this.safe_add(
          this.safe_add(this.bit_rol(a, 5), this.sha1_ft(j, b, c, d)),
          this.safe_add(this.safe_add(e, w[j]), this.sha1_kt(j))
        );
        e = d;
        d = c;
        c = this.bit_rol(b, 30);
        b = a;
        a = t;
      }

      a = this.safe_add(a, olda);
      b = this.safe_add(b, oldb);
      c = this.safe_add(c, oldc);
      d = this.safe_add(d, oldd);
      e = this.safe_add(e, olde);
    }
    return Array(a, b, c, d, e);
  }

  rstr2binb(input) {
    let output: any = Array(input.length >> 2);
    for (let i = 0; i < output.length; i++) output[i] = 0;
    for (let i = 0; i < input.length * 8; i += 8)
      output[i >> 5] |= (input.charCodeAt(i / 8) & 0xff) << (24 - (i % 32));
    return output;
  }

  bit_rol(num, cnt) {
    return (num << cnt) | (num >>> (32 - cnt));
  }

  safe_add(x, y) {
    let lsw = (x & 0xffff) + (y & 0xffff);
    let msw = (x >> 16) + (y >> 16) + (lsw >> 16);
    return (msw << 16) | (lsw & 0xffff);
  }

  sha1_ft(t, b, c, d) {
    if (t < 20) return (b & c) | (~b & d);
    if (t < 40) return b ^ c ^ d;
    if (t < 60) return (b & c) | (b & d) | (c & d);
    return b ^ c ^ d;
  }

  sha1_kt(t) {
    return t < 20
      ? 1518500249
      : t < 40
      ? 1859775393
      : t < 60
      ? -1894007588
      : -899497514;
  }

  b64_hmac_sha1(key, message) {
    return this.rstr2b64(
      this.rstr_hmac_sha1(this.str2rstr_utf8(key), this.str2rstr_utf8(message))
    );
  }

  rstr_hmac_sha1(key, data) {
    let bkey = this.rstr2binb(key);
    if (bkey.length > 16) bkey = this.binb_sha1(bkey, key.length * 8);

    let ipad = Array(16),
      opad = Array(16);
    for (let i = 0; i < 16; i++) {
      ipad[i] = bkey[i] ^ 0x36363636;
      opad[i] = bkey[i] ^ 0x5c5c5c5c;
    }

    let hash = this.binb_sha1(
      ipad.concat(this.rstr2binb(data)),
      512 + data.length * 8
    );
    return this.binb2rstr(this.binb_sha1(opad.concat(hash), 512 + 160));
  }
}

export interface IHttp2TalosReq {
  method?: string;
  path: string;
  queryParams?: any;
  input?: any;
  headers?: HttpHeaders;
  requireSession?: boolean;
  timeStamp?: string;
  isV1?: boolean;
  disableEncryption?: boolean;
  encryptionKey?: string;
  encryptionIV?: string;
}

// import * as Moment from 'moment';
// import {Injectable, Injector} from '@angular/core';
// // import {Storage} from '@ionic/storage';
// import {TAConstants, TAValues} from './settings';
// import {AuthenticateKeyInput} from './models/';
// // import {UsersApi} from './api/UsersApi';
// import {AuthenticateUserResponse} from './models/AuthenticateUserResponse';
// import * as _ from 'lodash';
// import {TranslateService} from '@ngx-translate/core';
// import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
// import {NetWork} from './network';
// import HttpMethods = TAConstants.HttpMethods;
// import Status = NetWork.Status;
// import {Observable} from "rxjs/index";
//
//
// /*
//  Generated class for the Http2Talos provider.
//
//  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
//  for more info on providers and Angular 2 DI.
//  */
// @Injectable()
// export class Http2Talos {
//
//   constructor(private http: HttpClient, private injector: Injector, protected translate: TranslateService) {
//   }
//
//   // tslint:disable-next-line:max-line-length
//   private getHeaders(path: string, params?: any, data_?: any, key?: string, headers?: HttpHeaders): {headers: HttpHeaders, httpParams: HttpParams} {
//     let headers_: HttpHeaders = _.isNil(headers) ? new HttpHeaders() : _.cloneDeep(headers);
//
//     logger.log(headers_);
//     const currentTime: string = headers_.get(TAConstants.TAHeaders.TIMESTAMP) || Moment(new Date()).format('YYYY-MM-DTHH:mm:ss.SSSZZ');
//
//     const values: any[] = [];
//     values.push('uri=/' + TAConstants.Settings.API_VERSION + path);
//     values.push('ts=' + encodeURIComponent(currentTime));
//     let getParams: HttpParams;
//     if (params !== undefined) {
//       getParams = new HttpParams();
//       // tslint:disable-next-line:forin
//       for (const key in params) {
//
//         logger.log(key);
//         const paramValue = params[key];
//         if (paramValue === undefined || paramValue === null) {continue; }
//
//         if (paramValue.constructor === Array) {
//           for (const value of paramValue) {
//             values.push(key + '=' + encodeURIComponent(value));
//             getParams = getParams.append(key, value);
//           }
//         } else {
//           values.push(key + '=' + encodeURIComponent(paramValue));
//           getParams = getParams.set(key, paramValue);
//         }
//       }
//     }
//     if (data_) {
//       const json: any = JSON.stringify(data_);
//
//       const sha1_ = this.b64_sha1(json);
//       logger.log(sha1_);
//       const urlEnc = encodeURIComponent(sha1_);
//       values.push('b=' + urlEnc);
//       logger.log(urlEnc);
//     }
//     values.sort();
//     let finalString = '';
//     values.forEach(value => {
//       finalString += value + '&';
//     });
//     finalString += 'k=' + key;
//     const signature = this.b64_hmac_sha1(key, finalString);
//     // NOT TO SET FROM OUTSIDE
//     headers_ = headers_.set('Accept-Language', this.translate.currentLang);
//     headers_ = headers_.set('Content-Type', 'application/json;charset=utf-8');
//     headers_ = headers_.set(TAConstants.TAHeaders.SIGNATURE, signature);
//     headers_ = headers_.set(TAConstants.TAHeaders.TIMESTAMP, currentTime);
//
//     if (headers_.has(TAConstants.TAHeaders.USER_GROUP_ID) === false) {
//       headers_ = headers_.set(TAConstants.TAHeaders.USER_GROUP_ID, TAConstants.Settings.USERGROUP_ID);
//     }
//     if (headers_.has(TAConstants.TAHeaders.APPLICATION_ID) === false) {
//       headers_ = headers_.set(TAConstants.TAHeaders.APPLICATION_ID, TAValues.APPLICATION_ID);
//     }
//     if (headers_.has(TAConstants.TAHeaders.SESSION_ID) === false && _.isNil(TAValues.UserSessionId) === false) {
//       headers_ = headers_.set(TAConstants.TAHeaders.SESSION_ID, TAValues.UserSessionId);
//     }
//     // if(headers_.has(TAConstants.TAHeaders.GAME_TYPE_ID) === false)
//     //   headers_=headers_.set(TAConstants.TAHeaders.GAME_TYPE_ID, TAConstants.Settings.GAME_TYPE_ID);
//
//
//     return {headers: headers_, httpParams: getParams};
//   }
//
//   private request<I, T , R >(request: IHttp2TalosReq): Promise<R> {
//
//     const result = this.getHeaders(request.path, request.queryParams, request.input, TAConstants.Settings.MAGIC_KEY, request.headers);
//
//     // const finalReq = reqOptions.clone({headers:result.headers,params:result.httpParams});
//     const urlToCall: string = TAConstants.Settings.SERVER + request.path;
//     const reqOptions: HttpRequest<I> = new HttpRequest<I>(request.method, urlToCall , request.input, {
//       headers: result.headers,
//       reportProgress: false,
//       params: result.httpParams
//     });
//
//     return new Promise((resolve, reject) => {
//         resolve('foo');
//     });
//     // return Observable.create(observer => {
//     //   this.http.request<T>(reqOptions).subscribe((response: HttpEvent<T>) => {
//     //     if (response instanceof HttpResponse) {
//     //       const responseBody = (<HttpResponse<T>>response).body;
//     //       const headers = response.headers;
//     //       if (headers.has(TAConstants.TAHeaders.ITEM_COUNT)) {
//     //         const response_: any = {
//     //           items: responseBody,
//     //           count: +headers.get(TAConstants.TAHeaders.ITEM_COUNT)
//     //         };
//     //         observer.next(response_);
//     //         return;
//     //       }
//     //
//     //       // if(request.youGiveRestABadName === true){
//     //       //   observer.next(response.status === Status.OK);
//     //       //   return;
//     //       // }
//     //       observer.next(responseBody);
//     //
//     //
//     //
//     //     }
//     //   }, (error) => {
//     //
//     //     // ANGULAR PRODUCE ERROR , IF STATUS IS OK ON POST/PUT/PATCH AND BODY IS NOT DEFINED
//     //     if (error.status === Status.OK && error.error.text === '') {
//     //       observer.next();
//     //       return;
//     //     }
//     //     observer.error(error);
//     //   });
//     // });
//
//   }
//
//   public get<I, T, R>(request: IHttp2TalosReq): Observable<R> {
//
//     request.method = request.method || HttpMethods.GET;
//     return this.request(request)<I, T, R>();
//     // return this.checkRequireSession<I, T, R>(request);
//   }
//
//   public post<I, T, R>(request: IHttp2TalosReq): Observable<R>  {
//     request.method = request.method || HttpMethods.POST;
//     return this.request(request)<I, T, R>();
//     // return this.checkRequireSession<I, T, R>(request);
//   }
//
//   public put<I, T, R>(request: IHttp2TalosReq): Observable<R> {
//     request.method = request.method || HttpMethods.PUT;
//
//     return this.request(request)<I, T, R>();
//     // return this.checkRequireSession<I, T, R>(request);
//   }
//
//   public options<I, T, R>(request: IHttp2TalosReq): Observable<R> {
//     request.method = request.method || HttpMethods.OPTIONS;
//
//     return this.request(request)<I, T, R>();
//     // return this.checkRequireSession<I, T, R>(request);
//   }
//
//   public delete<I, T, R>(request: IHttp2TalosReq): Observable<R> {
//     request.method = request.method || HttpMethods.DELETE;
//     return this.request(request)<I, T, R>();
//     // return this.checkRequireSession<I, T, R>(request);
//   }
//
//   public head<I, T, R>(request: IHttp2TalosReq): Observable<R> {
//     request.method = request.method || HttpMethods.HEAD;
//     return this.request(request)<I, T, R>();
//     // return this.checkRequireSession<I, T, R>(request);
//   }
//
//   public patch<I, T, R>(request: IHttp2TalosReq): Observable<R> {
//     request.method = request.method || HttpMethods.PATCH;
//     return this.request(request)<I, T, R>();
//     // return this.checkRequireSession<I, T, R>(request);
//   }
//
//   // public getHttpParametersFromObject(obj:any):HttpParams{
//   //   if(_.isNil(obj)){
//   //     return null;
//   //   }
//   //
//   //   let params:HttpParams = new HttpParams();
//   //
//   //   const setParam = (key, value)=>{
//   //     if( Array.isArray(value)){
//   //       value.forEach(item=>{
//   //         setParam(key, item);
//   //       })
//   //     }else if(typeof value != "string"){
//   //       params=params.set(key, String(value));
//   //     }else{
//   //       params=params.set(key,value);
//   //     }
//   //   }
//   //   Object.keys(obj).forEach(key => {
//   //     const value = obj[key] ;
//   //     setParam(key, value);
//   //   });
//   //
//   //   return params;
//   // }
//
//   // private  checkRequireSession<I, T, R>(request: IHttp2TalosReq): Observable<R> {
//   //   if (request.requireSession !== true) { return  this.request<I, T, R>(request); }
//   //   if (TAValues.UserSessionId !== undefined && TAValues.UserSessionId.length > 0) {return  this.request<I, T, R>(request); }
//   //
//   //   return Observable.create(observer => {
//   //     this.storage.get('AuthKey').then((result: string) => {
//   //       const input: AuthenticateKeyInput = {authenticatedKey: result};
//   //
//   //       const api = this.injector.get(UsersApi);
//   //       const subscription = api.authenticateKey(input).subscribe((data) => {
//   //         // tslint:disable-next-line:no-unused-expression
//   //         subscription && subscription.unsubscribe();
//   //         this.handleSession(data);
//   //         // tslint:disable-next-line:no-shadowed-variable
//   //         const subscriber =  this.request<I, T, R>(request).subscribe(result => {
//   //           subscriber.unsubscribe();
//   //           observer.next(result);
//   //         }, error => {
//   //           subscriber.unsubscribe();
//   //           observer.error(error);
//   //         });
//   //       }, (error: any) => {
//   //         observer.error(error);
//   //       });
//   //
//   //     }, (error: any) => {
//   //       observer.error(error);
//   //     });
//   //   });
//   // }
//   private handleSession(data: AuthenticateUserResponse) {
//     TAValues.UserId = data.userId;
//     TAValues.UserSessionId = data.userSessionId;
//   }
//
//
//
//
//   // requireSession() {
//   //   return new Promise((resolve, reject) => {
//   //     TAValues.UserSessionId === '' ?
//   //       (this.storage.get('AuthKey').then((result: string) => {
//   //         this.authenticateKey(result).then((result: any) => {
//   //           resolve(result);
//   //         }, (error: any) => {
//   //           reject(error);
//   //         });
//   //       }, (error: any) => {
//   //         reject(error);
//   //       })) : resolve();
//   //   });
//
//
//
//   rstr2b64(input) {
//     const tab = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
//     let output = '';
//     const len = input.length;
//     for (let i = 0; i < len; i += 3) {
//       // tslint:disable-next-line:no-bitwise
//       const triplet = (input.charCodeAt(i) << 16)
//         // tslint:disable-next-line:no-bitwise
//         | (i + 1 < len ? input.charCodeAt(i + 1) << 8 : 0)
//         | (i + 2 < len ? input.charCodeAt(i + 2) : 0);
//       for (let j = 0; j < 4; j++) {
//         // tslint:disable-next-line:no-bitwise
//         if (i * 8 + j * 6 > input.length * 8) { output += '='; } else { output += tab.charAt((triplet >>> 6 * (3 - j)) & 0x3F); }
//       }
//     }
//     return output;
//   }
//
//   binb2rstr(input) {
//     let output = '';
//     for (let i = 0; i < input.length * 32; i += 8) {
//       // tslint:disable-next-line:no-bitwise
//       output += String.fromCharCode((input[i >> 5] >>> (24 - i % 32)) & 0xFF);
//     }
//     return output;
//   }
//
//   bit_rol(num, cnt) {
//     return (num << cnt) | (num >>> (32 - cnt));
//   }
//
//   safe_add(x, y) {
//     const lsw = (x & 0xFFFF) + (y & 0xFFFF);
//     const msw = (x >> 16) + (y >> 16) + (lsw >> 16);
//     return (msw << 16) | (lsw & 0xFFFF);
//   }
//
//   sha1_ft(t, b, c, d) {
//     if (t < 20) { return (b & c) | ((~b) & d); }
//     if (t < 40) { return b ^ c ^ d; }
//     if (t < 60) { return (b & c) | (b & d) | (c & d); }
//     return b ^ c ^ d;
//   }
//
//   sha1_kt(t) {
//     return (t < 20) ? 1518500249 : (t < 40) ? 1859775393 :
//       (t < 60) ? -1894007588 : -899497514;
//   }
//
//   rstr2binb(input) {
//     const output: any = Array(input.length >> 2);
//     for (let i = 0; i < output.length; i++) {
//       output[i] = 0;
//     }
//     for (let i = 0; i < input.length * 8; i += 8) {
//       output[i >> 5] |= (input.charCodeAt(i / 8) & 0xFF) << (24 - i % 32);
//     }
//     return output;
//   }
//
//   str2rstr_utf8(input) {
//     let output = '';
//     let i = -1;
//     let x, y;
//
//     while (++i < input.length) {
//       /* Decode utf-16 surrogate pairs */
//       x = input.charCodeAt(i);
//       y = i + 1 < input.length ? input.charCodeAt(i + 1) : 0;
//       if (0xD800 <= x && x <= 0xDBFF && 0xDC00 <= y && y <= 0xDFFF) {
//         x = 0x10000 + ((x & 0x03FF) << 10) + (y & 0x03FF);
//         i++;
//       }
//
//       /* Encode output as utf-8 */
//       if (x <= 0x7F) {
//         output += String.fromCharCode(x);
//       } else if (x <= 0x7FF) {
//         output += String.fromCharCode(0xC0 | ((x >>> 6 ) & 0x1F),
//           0x80 | ( x & 0x3F));
//            } else if (x <= 0xFFFF) {
//         output += String.fromCharCode(0xE0 | ((x >>> 12) & 0x0F),
//           0x80 | ((x >>> 6 ) & 0x3F),
//           0x80 | ( x & 0x3F));
//            } else if (x <= 0x1FFFFF) {
//         output += String.fromCharCode(0xF0 | ((x >>> 18) & 0x07),
//           0x80 | ((x >>> 12) & 0x3F),
//           0x80 | ((x >>> 6 ) & 0x3F),
//           0x80 | ( x & 0x3F));
//            }
//     }
//     return output;
//   }
//
//   binb_sha1(x, len) {
//     /* append padding */
//     x[len >> 5] |= 0x80 << (24 - len % 32);
//     x[((len + 64 >> 9) << 4) + 15] = len;
//
//     const w = Array(80);
//     let a = 1732584193;
//     let b = -271733879;
//     let c = -1732584194;
//     let d = 271733878;
//     let e = -1009589776;
//
//     for (let i = 0; i < x.length; i += 16) {
//       const olda = a;
//       const oldb = b;
//       const oldc = c;
//       const oldd = d;
//       const olde = e;
//
//       for (let j = 0; j < 80; j++) {
//         if (j < 16) { w[j] = x[i + j]; } else { w[j] = this.bit_rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1); }
//         const t = this.safe_add(this.safe_add(this.bit_rol(a, 5), this.sha1_ft(j, b, c, d)),
//           this.safe_add(this.safe_add(e, w[j]), this.sha1_kt(j)));
//         e = d;
//         d = c;
//         c = this.bit_rol(b, 30);
//         b = a;
//         a = t;
//       }
//
//       a = this.safe_add(a, olda);
//       b = this.safe_add(b, oldb);
//       c = this.safe_add(c, oldc);
//       d = this.safe_add(d, oldd);
//       e = this.safe_add(e, olde);
//     }
//     return Array(a, b, c, d, e);
//
//   }
//
//   rstr_sha1(s) {
//     return this.binb2rstr(this.binb_sha1(this.rstr2binb(s), s.length * 8));
//   }
//
//   b64_sha1(s) {
//     return this.rstr2b64(this.rstr_sha1(this.str2rstr_utf8(s)));
//   }
//
//   rstr_hmac_sha1(key, data) {
//     let bkey = this.rstr2binb(key);
//     if (bkey.length > 16) { bkey = this.binb_sha1(bkey, key.length * 8); }
//
//     const ipad = Array(16), opad = Array(16);
//     for (let i = 0; i < 16; i++) {
//       ipad[i] = bkey[i] ^ 0x36363636;
//       opad[i] = bkey[i] ^ 0x5C5C5C5C;
//     }
//
//     const hash = this.binb_sha1(ipad.concat(this.rstr2binb(data)), 512 + data.length * 8);
//     return this.binb2rstr(this.binb_sha1(opad.concat(hash), 512 + 160));
//   }
//
//   b64_hmac_sha1(key, message) {
//     return this.rstr2b64(this.rstr_hmac_sha1(this.str2rstr_utf8(key), this.str2rstr_utf8(message)));
//   }
//
//
// }
//
// export interface IHttp2TalosReq {
//   method?: string;
//   path: string;
//   queryParams?: any;
//   input?: any;
//   headers?: HttpHeaders;
//   requireSession?: boolean;
//   timeStamp?: string;
//
// }
//
//
