import { Injectable } from "@angular/core";
import { Message } from "../../../talosApi/models/Message";
import { TAConstants, TAValues } from "../../../talosApi/settings";
import { AppSettingsService } from "../../../services/talos/appSettings.service";
import { MessagesService } from "../../../services/talos/messages.service";
import * as Moment from "moment";
import * as _ from "lodash";
import { IMessageCategory } from "../profile/profile.service";
import {
  CatalogPageData,
  ExtendedBrandProductDTO,
  ExtendedCategoryDTO,
  LoyaltyBrandProductDTO,
  RewardsService,
} from "../rewards/rewards.service";
import { AppGlobalsService } from "src/services/appGlobals.service";
import { isArray, isNull, isNullOrUndefined } from "util";
import { propValue, to } from "../../../../../src/utils/utils";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "src/services/talos/user.service";
import { ItemsList } from "src/talosApi";
import { userAchievement } from "src/talosApi/models/userAchievementDTO";
import {
  EventAchievementAction,
  ExtendedEventAchievementAction,
} from "src/talosApi/models/eventAchievementActionDTO";
import { UtilsService } from "src/services/utils.service";
import { CATALOG_SHORTER_FILTER_TYPES } from "../rewards/catalog/catalog.component";
import { KpDictionary } from "src/services/kp_dictionary/kp.dictionary";
import { PendingPointsQ } from "../../../talosApi/api/UserApi";
import { PendingPointDTO } from "../../../talosApi/models/PendingPointDTO";
import { ResourcesServices } from "../../../services/talos/resources.services";
import { SendVirtualAmountInput } from "../../../talosApi/models/SendVirtualAmountInput";
import { IndexedDataContent } from "../../../talosApi/models/IndexedDataContent";
import { ActionStatusDTO } from "../../../talosApi/models/ActionStatusDTO";
import { MaterialService, MaterialType } from "../material/material.service";
import { IndexedDataService } from "../../../services/talos/indexedData.service";
import { MaterialObject } from "../../../models/MaterialObject";
import { GetUserActionStatusInput } from "../../../talosApi/models/GetUserActionStatusInput";
import { ItemActionStatusDTO } from "../../../talosApi/models/ItemActionStatusDTO";
import { ItemService } from "../../../services/talos/item.service";
import { FilterESContentDataInput } from "../../../talosApi/api/RecommendationsApi";
import { RecommendationsService } from "../../../services/talos/recommendations.service";
import { ListReferralInput } from "../../../talosApi/models/ListReferralInput";
import { QuizDTO } from "../../../talosApi/models/QuizDTO";
import { ListQuizQuery } from "../../../talosApi/api/QuizApi";
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import Resource_Types = TAConstants.Resource_Types;
import METADATA_KEY = TAConstants.METADATA_KEY;
import Coupon_Types = TAConstants.COUPON_STATUSES;
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import Order_Stores = TAConstants.Order_Stores;
import QUIZ_TYPES = TAConstants.QUIZ_TYPES;
import Unit_Types = TAConstants.Unit_Types;
import Events_Types = TAConstants.Events_Types;
import REFERRAL_TYPES = TAConstants.REFERRAL_TYPES;
import Settings = TAConstants.Settings;
import { QuizService } from "../quizzes/quiz/quiz.service";
import { AnswerQuestionResponse } from "../../../talosApi/models/AnswerQuestionResponse";
import { UserMaxObtainablePointsDTO } from "src/talosApi/models/UserPendingPointsDTO";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class OverviewService {
  /**
   * Reward config of overview service
   */
  private rewardConfig = null;

  profileShown = false;

  private maxObtainablePendingPtsSubject = new BehaviorSubject<number>(
    undefined
  );
  public maxObtainablePendingPts$ =
    this.maxObtainablePendingPtsSubject.asObservable();

  /**
   * Creates an instance of overview service.
   * @param appSettingsSrv
   * @param messagesSrv
   * @param rewardsSrv
   * @param translateSrv
   */
  constructor(
    private userSrv: UserService,
    private appSettingsSrv: AppSettingsService,
    private messagesSrv: MessagesService,
    private rewardsSrv: RewardsService,
    private translateSrv: TranslateService,
    private userService: UserService,
    private utilService: UtilsService,
    public appGlobalsSrv: AppGlobalsService,
    private resourcesSrv: ResourcesServices,
    private materialSrv: MaterialService,
    private indexedDataSrv: IndexedDataService,
    private itemSrv: ItemService,
    private recommendationsSrv: RecommendationsService,
    public quizSrv: QuizService
  ) {}

  getOverviewContents(): Promise<Array<MaterialObject>> {
    return new Promise((resolve, reject) => {
      const articleCat = this.materialSrv.getCategoryIdByType(
        MaterialType.ARTICLE,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const videoCat = this.materialSrv.getCategoryIdByType(
        MaterialType.VIDEO,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const quizCat = this.materialSrv.getCategoryIdByType(
        MaterialType.QUIZ,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const quickCat = this.materialSrv.getCategoryIdByType(
        MaterialType.QUICK_REPLY,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const gameCat = this.materialSrv.getCategoryIdByType(
        MaterialType.GAME,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const getMaterialType = (item: IndexedDataContent): MaterialType => {
        let result: MaterialType;
        if (item.categoryIds) {
          if (item.categoryIds.find((c) => c == articleCat)) {
            result = MaterialType.ARTICLE;
          } else if (item.categoryIds.find((c) => c == videoCat)) {
            result = MaterialType.VIDEO;
          } else if (item.categoryIds.find((c) => c == quickCat)) {
            result = MaterialType.QUICK_REPLY;
          } else if (item.categoryIds.find((c) => c == gameCat)) {
            result = MaterialType.GAME;
          } else if (item.categoryIds.find((c) => c == quizCat)) {
            if (item.contentReferencedType) {
              if (item.contentReferencedType.typeId == String(QUIZ_TYPES.QUIZ))
                result = MaterialType.QUIZ;
              else if (
                item.contentReferencedType.typeId == String(QUIZ_TYPES.SURVEY)
              )
                result = MaterialType.SURVEY;
            }
          }
        }
        return result;
      };

      const categories = [articleCat, videoCat, quizCat, quickCat];
      let input: FilterESContentDataInput = {
        itemTypeIds: [ITEM_TYPES.UPLOAD_CONTENT, ITEM_TYPES.QUIZ],
        dataFilters: [],
        categoryIds: categories,
        rangeFrom: 0,
        rangeTo: 3,
        currentlyRewarding: true,
        sortOrderTypes: [Order_Stores.BY_RECENT],
        countMode: false,
        userRewarded: false,
        currentlyActive: true,
        matchingLevel: true,
        matchingRules: true,
      };
      Promise.all([
        this.materialSrv.getEventRewardsRules(),
        this.recommendationsSrv.getRecommendationContent(
          TAValues.UserId,
          input,
          {
            resources: true,
            languageIds: TAConstants.Settings.LANGUAGES,
            metadatas: true,
          }
        ),
        this.materialSrv.getMaterialFiltersGuadagna(),
      ])
        .then(async (arrayOfResults) => {
          if (arrayOfResults && arrayOfResults.length == 3) {
            const objects: Array<OverviewGenericContent> =
              arrayOfResults[1] || [];
            if (objects.length < 3) {
              input.userRewarded = true;
              input.currentlyRewarding = undefined;
              input.rangeTo = 3 - objects.length;
              await this.recommendationsSrv
                .getRecommendationContent(TAValues.UserId, input, {
                  resources: true,
                  metadatas: true,
                  languageIds: TAConstants.Settings.LANGUAGES,
                })
                .then((res: Array<OverviewGenericContent>) => {
                  if (res) {
                    objects.push(...res);
                  }
                })
                .catch((err) => {});
            }
            if (objects.length > 0) {
              const aIds = [];
              const vIds = [];
              const qIds = [];
              const sIds = [];
              const gIds = [];
              const cqIds = [];

              objects.forEach((i) => {
                const cqId = this.resourcesSrv.getResourcesBasic(
                  i,
                  TAConstants.Resource_Types.REFERENCE_TEXT
                );
                if (cqId && cqId != "") cqIds.push(cqId);

                const type = getMaterialType(i);
                switch (type) {
                  case MaterialType.ARTICLE:
                  case MaterialType.QUICK_REPLY:
                    aIds.push(i.itemId);
                    break;
                  case MaterialType.VIDEO:
                    vIds.push(i.itemId);
                    break;
                  case MaterialType.QUIZ:
                    qIds.push(i.itemId);
                    break;
                  case MaterialType.SURVEY:
                    sIds.push(i.itemId);
                    break;
                  case MaterialType.GAME:
                    gIds.push(i.itemId);
                    break;
                }
              });

              const promiseNew = (
                ids: Array<string>,
                itemTypeId: number,
                eventTypeId: number
              ): Promise<Array<ItemActionStatusDTO>> => {
                const input: GetUserActionStatusInput = {
                  itemIds: ids,
                  itemTypeId: itemTypeId,
                  unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
                  includeLimitations: true,
                };

                return this.itemSrv.getUserActions(eventTypeId, null, input);
              };

              const promise = (
                item: IndexedDataContent
              ): Promise<Array<ItemActionStatusDTO>> => {
                const input: GetUserActionStatusInput = {
                  itemIds: [item.itemId],
                  itemTypeId: item.itemTypeId,
                  unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
                  includeLimitations: true,
                };
                const type = getMaterialType(item);
                if (type) {
                  let eventTypeId: number;
                  switch (type) {
                    case MaterialType.ARTICLE:
                    case MaterialType.QUICK_REPLY:
                      eventTypeId = Events_Types.ITEM_VIEWED;
                      break;
                    case MaterialType.VIDEO:
                      eventTypeId = Events_Types.VIDEO_WATCHED;
                      break;
                    case MaterialType.QUIZ:
                      eventTypeId = Events_Types.QUIZ_COMPLETION;
                      break;
                    case MaterialType.SURVEY:
                      eventTypeId = Events_Types.POLL_SURVEY_COMPLETED;
                      break;
                    case MaterialType.GAME:
                      eventTypeId = Events_Types.GAME_FINISHED;
                      break;
                  }
                  if (eventTypeId)
                    return this.itemSrv.getUserActions(
                      eventTypeId,
                      null,
                      input
                    );
                }
              };

              // const promises = objects.map((o) => {
              //   return promise(o);
              // });

              const promises = [];

              if (aIds.length > 0) {
                promises.push(
                  promiseNew(
                    aIds,
                    ITEM_TYPES.UPLOAD_CONTENT,
                    Events_Types.ITEM_VIEWED
                  )
                );
              }
              if (vIds.length > 0) {
                promises.push(
                  promiseNew(
                    vIds,
                    ITEM_TYPES.UPLOAD_CONTENT,
                    Events_Types.VIDEO_WATCHED
                  )
                );
              }
              if (qIds.length > 0) {
                promises.push(
                  promiseNew(
                    qIds,
                    ITEM_TYPES.QUIZ,
                    Events_Types.QUIZ_COMPLETION
                  )
                );
              }
              if (sIds.length > 0) {
                promises.push(
                  promiseNew(
                    sIds,
                    ITEM_TYPES.QUIZ,
                    Events_Types.POLL_SURVEY_COMPLETED
                  )
                );
              }
              if (gIds.length > 0) {
                promises.push(
                  promiseNew(
                    gIds,
                    ITEM_TYPES.UPLOAD_CONTENT,
                    // ITEM_TYPES.ITEM_TYPE_CONTENT,
                    Events_Types.GAME_FINISHED
                  )
                );
              }
              if (cqIds.length > 0) {
                promises.push(
                  promiseNew(
                    cqIds,
                    ITEM_TYPES.QUIZ,
                    Events_Types.CONTENT_QUIZ_COMPLETION
                  )
                );
              }

              if (promises.length > 0) {
                const results = await Promise.all(promises).catch((err) => {});
                if (results) {
                  results.forEach((r) => {
                    if (r) {
                      r.forEach((a) => {
                        objects.forEach((i: OverviewGenericContent) => {
                          const cqId = this.resourcesSrv.getResourcesBasic(
                            i,
                            TAConstants.Resource_Types.REFERENCE_TEXT
                          );

                          if (
                            a.itemId == i.itemId &&
                            a.itemTypeId == i.itemTypeId &&
                            a.actionsStatus &&
                            a.actionsStatus.length > 0
                          ) {
                            i.actionsStatus = a.actionsStatus[0];
                          } else if (cqId && cqId != "" && cqId == a.itemId) {
                            i.quizActionsStatus = a.actionsStatus[0];
                          }
                        });
                      });
                    }
                  });
                }
              }

              const items = objects.map((i) => {
                const type = getMaterialType(i);
                if (type) {
                  let quizPointsTag;
                  if (i.quizActionsStatus) {
                    quizPointsTag = this.utilService.getQuizReward(
                      i.quizActionsStatus
                    );
                  }
                  const o = this.utilService.materialObjectByOverviewContent(
                    i,
                    type,
                    arrayOfResults[2],
                    this.materialSrv.materialConfig[
                      APPLICATION_SETTING_KEYS
                        .MATERIAL_PERSONALIZED_CATEGORY_IDS
                    ],
                    this.userSrv.user.firstPurchaseDate,
                    quizPointsTag
                  );
                  return o;
                }
              });
              resolve(items);
              return;
            }
          }
          resolve(null);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOverviewQuiz(quizCategoryId: string): Promise<QuizDTO> {
    return new Promise(async (resolve, reject) => {
      const query: ListQuizQuery = {
        quizCategoryId: quizCategoryId,
        gameTypeId: Settings.GAME_TYPE,
        includeQuestions: true,
        rangeFrom: 0,
        rangeTo: 1,
        matchingLevel: true,
        userId: TAValues.UserId,
        metadatas: true,
        resources: true,
        languageIds: TAConstants.Settings.LANGUAGES,
      };
      const quiz = await this.quizSrv.getQuizById(query).catch((err) => {});
      if (!quiz) {
        resolve(null);
        return;
      }

      resolve(quiz);
    });
  }

  answerOverviewQuiz(quiz: QuizDTO): Promise<AnswerQuestionResponse> {
    return new Promise(async (resolve, reject) => {
      this.quizSrv
        .answerQuizNew(quiz.id, quiz.questions)
        .then((result: AnswerQuestionResponse) => {
          logger.log(result);
          this.userService
            .updateUserStatus()
            .then((result) => logger.log(result))
            .catch((err) => logger.log(err));
          resolve(result);
        })
        .then((err) => {
          logger.log(err);
          reject(err);
        });
    });
  }

  /**
   *
   * @param rangeFrom
   * @param rangeTo
   */
  getMessages(
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<IMessageCategory>> {
    return new Promise((resolve, reject) => {
      const folderId = this.appSettingsSrv.getValue(
        APPLICATION_SETTING_KEYS.USER_MESSAGING_FOLDER
      );
      if (_.isNil(folderId)) {
        resolve([]);
        return;
      }

      const getMessageImage = (message: Message): string => {
        if (message.properties) {
          var image = message.properties.filter((p) => p.type == 0);

          if (image && image.length > 0) {
            return image[0].value;
          }
        }

        return "";
      };
      this.messagesSrv
        .getMessages(folderId, rangeFrom, rangeTo)
        .then((result: Array<Message>) => {
          if (result) {
            result.forEach((m: Message) => {
              let title: string;
              let category: string;
              let message: string;

              try {
                const title_: string[] = m.content
                  .match(/<messagetitle>(.*?)<\/messagetitle>/g)
                  .map((val) => val.replace(/<\/?messagetitle>/g, ""));
                title = title_[0];
                const category_: string[] = m.content
                  .match(/<category>(.*?)<\/category>/g)
                  .map((val) => val.replace(/<\/?category>/g, ""));
                category = category_[0];
                const message_: string[] = m.content
                  .match(/<message>(.*?)<\/message>/g)
                  .map((val) => val.replace(/<\/?message>/g, ""));
                message = message_[0];
              } catch (e) {
                title = "";
                category = "";
                message = "";
              }
              m.title = title.toUpperCase();
              m.category = category;
              m.message = message;
              m.image = getMessageImage(m);
              m.formatedDate = Moment(m.messageDate)
                .locale(this.translateSrv.currentLang)
                .calendar();
            });
          }
          resolve(result);
        }, reject);
    });
  }

  /**
   *
   * @param userId
   * @param messageId
   * @param status
   */
  public setReadMessage(messageId: string, status: number): Promise<Message> {
    return new Promise<Message>((resolve, reject) => {
      this.messagesSrv
        .setStatus(TAValues.UserId, [messageId], status)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Gets top rewards
   * @returns Reward data on Dictionary Format.
   */
  public async getTopRewards(
    rangeTo: number,
    stats: number | boolean,
    hasBought?: boolean
  ): Promise<CatalogPageData> {
    return new Promise<CatalogPageData>(async (resolve, reject) => {
      let _rootCategoryId = null;
      let callCenterCategoryId = null;

      let promoBought = true;
      let referralPromoBought = true;
      let hasReferral = true;
      let hasBought = true;

      let _rewardConfig = {};
      /* Refresh Page Category Tree. */
      if (this.appGlobalsSrv.config) {
        _rewardConfig =
          this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.REWARDS_CONFIG] ||
          {};
        if (_rewardConfig) {
          _rootCategoryId = _rewardConfig[
            APPLICATION_SETTING_KEYS.REWARDS_FILTER_CATEGORY_ID
          ] as number;
          callCenterCategoryId = !isNaN(
            parseInt(
              _rewardConfig[
                APPLICATION_SETTING_KEYS.REWARDS_CALL_CENTER_CATEGORY_ID
              ]
            )
          )
            ? parseInt(
                _rewardConfig[
                  APPLICATION_SETTING_KEYS.REWARDS_CALL_CENTER_CATEGORY_ID
                ]
              )
            : null;
        }
      }

      const promoCategoryId = !isNaN(
        parseInt(
          _rewardConfig[APPLICATION_SETTING_KEYS.REWARDS_PROMO_CATEGORY_ID]
        )
      )
        ? parseInt(
            _rewardConfig[APPLICATION_SETTING_KEYS.REWARDS_PROMO_CATEGORY_ID]
          )
        : -1;
      const promoStoreCouponCategoryId = !isNaN(
        parseInt(
          _rewardConfig[
            APPLICATION_SETTING_KEYS.REWARDS_STORE_PROMO_CATEGORY_ID
          ]
        )
      )
        ? parseInt(
            _rewardConfig[
              APPLICATION_SETTING_KEYS.REWARDS_STORE_PROMO_CATEGORY_ID
            ]
          )
        : -1;
      const referralPromoCategoryId = !isNaN(
        parseInt(
          _rewardConfig[
            APPLICATION_SETTING_KEYS.REWARDS_REFERRAL_PROMO_CATEGORY_ID
          ]
        )
      )
        ? parseInt(
            _rewardConfig[
              APPLICATION_SETTING_KEYS.REWARDS_REFERRAL_PROMO_CATEGORY_ID
            ]
          )
        : -1;
      const query: ListReferralInput = {
        referredUserId: TAValues.UserId,
        rangeFrom: 0,
        rangeTo: 1,
        referralTypeIds: [
          REFERRAL_TYPES.MGM_UNICODE,
          REFERRAL_TYPES.LENDING_REFERRAL,
          REFERRAL_TYPES.LIL,
        ],
        referralStatusIds: [2, 3, 4],
        includeDetails: false,
        dateFrom: "2022-02-04T00:00:00.000Z",
      };
      const prResult = await Promise.all([
        this.rewardsSrv.checkIfHasBoughtByCategoryId(promoCategoryId),
        this.rewardsSrv.checkIfHasBoughtByCategoryId(referralPromoCategoryId),
        this.rewardsSrv.checkIfReferral(query),
        this.rewardsSrv.checkIfHasBoughtByDate(
          Moment("14-02-2022", "DD-MM-YYYY").toDate().getTime()
        ),
        this.rewardsSrv.checkIfHasBoughtByCategoryId(
          promoStoreCouponCategoryId
        ),
      ]).catch((err) => {});
      if (prResult) {
        // this.hasBought = prResult[0];
        promoBought = prResult[0] || prResult[4];
        referralPromoBought = prResult[1];
        hasReferral = prResult[2];
        hasBought = prResult[3];
      }

      if (_rootCategoryId) {
        let _rootCategoryData;
        let _childCategoryData;
        let _categoriesTree;
        const _getCategoriesTree = await to(
          this.rewardsSrv.getProductCategoriesTree(_rootCategoryId)
        );
        if (!isNullOrUndefined(_getCategoriesTree.data)) {
          _categoriesTree =
            _getCategoriesTree.data as KpDictionary<LoyaltyBrandProductDTO>;
        }
        const _getCategoriesTreeFilter = await to(
          this.utilService.getRootCategoryTree(
            _rootCategoryId,
            false,
            true,
            false
          )
        );
        if (isNullOrUndefined(_getCategoriesTreeFilter.data))
          logger.log("NO CATEGORIES");
        else {
          if (!isNullOrUndefined(_getCategoriesTreeFilter)) {
            _rootCategoryData =
              _getCategoriesTreeFilter.data as ExtendedCategoryDTO;
            if (
              !isNullOrUndefined(_rootCategoryData.childrenMapped) &&
              _rootCategoryData.childrenMapped.Count()
            ) {
              _childCategoryData =
                _rootCategoryData.childrenMapped as KpDictionary<ExtendedCategoryDTO>;
              if (callCenterCategoryId) {
                const category = _rootCategoryData.children.find((c) => {
                  return c.id == callCenterCategoryId;
                });
                if (category) {
                  _childCategoryData.Remove(category.id.toString());
                }
              }

              const removeCategory = (value: string) => {
                const category = _rootCategoryData.children.find((c) => {
                  var result = false;
                  if (c.metadata && c.metadata.length > 0) {
                    const metadata = this.utilService.valueFromKeyValueDTOArray(
                      METADATA_KEY.DISPLAY_BUTTON,
                      c.metadata
                    );
                    result = metadata && metadata == value;
                  }
                  return result;
                });
                if (category) {
                  _childCategoryData.Remove(category.id.toString());
                }
              };

              const checkIfMustShowPromo = (force?: boolean) => {
                if (hasBought || promoBought || force) {
                  removeCategory("true");
                }
              };

              const checkIfMustShowReferralPromo = (force?: boolean) => {
                if (promoBought || referralPromoBought || force) {
                  removeCategory("false");
                }
              };

              _rootCategoryData.children.forEach((c) => {
                if (c.metadata && c.metadata.length > 0) {
                  const levelEnabled =
                    this.utilService.valueFromKeyValueDTOArray(
                      METADATA_KEY.CATEGORY_MIN_LEVEL,
                      c.metadata
                    );
                  if (levelEnabled && levelEnabled != "") {
                    const levels = levelEnabled.split(",");
                    if (levels.length > 0) {
                      if (
                        levelEnabled.indexOf(
                          String(this.userService.user.badgeLevel)
                        ) >= 0
                      ) {
                        // if (this.userService.user.badgeLevel == 2) {
                        //   if (!this.userService.user.isCasting)
                        //     _childCategoryData.Remove(c.id.toString());
                        // }
                      } else {
                        _childCategoryData.Remove(c.id.toString());
                      }
                    }
                  }

                  //   const nonLevelEnabled =
                  //     this.utilService.valueFromKeyValueDTOArray(
                  //       METADATA_KEY.CATEGORY_MAX_LEVEL,
                  //       c.metadata
                  //     );
                  //   if (nonLevelEnabled && nonLevelEnabled != "") {
                  //     const levels = nonLevelEnabled.split(",");
                  //     if (levels.length > 0) {
                  //       if (
                  //         nonLevelEnabled.indexOf(
                  //           String(this.userService.user.badgeLevel)
                  //         ) >= 0
                  //       ) {
                  //         if (this.userService.user.badgeLevel == 2) {
                  //           if (this.userService.user.isCasting)
                  //             _childCategoryData.Remove(c.id.toString());
                  //         }
                  //       } else {
                  //         _childCategoryData.Remove(c.id.toString());
                  //       }
                  //     }
                  //   }
                }
              });

              if (hasReferral) {
                checkIfMustShowPromo(true);
                checkIfMustShowReferralPromo();
              } else {
                checkIfMustShowReferralPromo(true);
                checkIfMustShowPromo();
              }

              // if (hasBought) {
              //     const category = _rootCategoryData.children.find(c => {
              //         var result = false;
              //         if (c.metadata && c.metadata.length > 0) {
              //             const metadata = this.utilService.valueFromKeyValueDTOArray(METADATA_KEY.DISPLAY_BUTTON, c.metadata);
              //             result = (metadata && metadata == 'true');
              //         }
              //         return result;
              //     })
              //     if (category) {
              //         _childCategoryData.Remove(category.id.toString());
              //     }
              // }
              const categorySpecial = _rootCategoryData.children.find((c) => {
                var result = false;
                if (c.metadata && c.metadata.length > 0) {
                  const metadata = this.utilService.valueFromKeyValueDTOArray(
                    METADATA_KEY.DISPLAY_SPECIAL_DEAL_BUTTON,
                    c.metadata
                  );
                  result = metadata && metadata == "true";
                }
                return result;
              });
              let mustRemove = true;
              if (categorySpecial) {
                const userMetadata =
                  this.userService.user.user.customUserProfileFields[
                    METADATA_KEY.PERSONAL_ELIGIBLE_FOR_SPECIAL_CATALOGUE
                  ];
                if (userMetadata && userMetadata == "true") {
                  const _getProductCouponSelection = await to(
                    this.rewardsSrv.getProductCouponSelection(
                      categorySpecial,
                      null,
                      false,
                      false,
                      false,
                      true,
                      [
                        Coupon_Types.PURCHASED,
                        Coupon_Types.REDEEMED,
                        Coupon_Types.EXPIRED,
                        Coupon_Types.EXPIRED_REQUESTED_CHARGE_BACK,
                        Coupon_Types.EXPIRED_CLEARED,
                        Coupon_Types.EXPIRED_YELLOWS_CHARGE_BACK_FAILED,
                        Coupon_Types.EXPIRED_CLEARED_FAILED,
                        Coupon_Types.EXPIRED_GIFTCARD,
                        Coupon_Types.EXPIRED_YELLOWS_CHARGED_BACK,
                      ],
                      null,
                      0,
                      1,
                      [107]
                    )
                  );

                  if (_getProductCouponSelection) {
                    const bought =
                      _getProductCouponSelection.data &&
                      _getProductCouponSelection.data.Count() > 0;
                    mustRemove = bought;
                  }
                }

                if (mustRemove) {
                  _childCategoryData.Remove(categorySpecial.id.toString());
                }
              }
            }
          }
        }

        if (!isNull(_childCategoryData) && !isNull(_rootCategoryData)) {
          const _getProductSelection = to(
            this.rewardsSrv.getProductSelection(
              _childCategoryData,
              [-1],
              CATALOG_SHORTER_FILTER_TYPES.BY_ORDERING_ASC.NAME,
              0,
              rangeTo,
              stats,
              true
            )
          );
          await Promise.all([_getProductSelection]).then((arrayOfResults) => {
            logger.log(arrayOfResults);
            if (!arrayOfResults) reject(null);
            else if (!isNullOrUndefined(arrayOfResults[0].data)) {
              let _data = {
                categories: _categoriesTree,
                counters: null,
                brandProducts: arrayOfResults[0]
                  .data as KpDictionary<ExtendedBrandProductDTO>,
              };
              resolve(_data);
            } else reject(null);
          });
        } else reject(null);
      }
      // if (_rootCategoryId) {
      //     const _getCategoriesTree = await to(this.rewardsSrv.getProductCategoriesTree(_rootCategoryId));
      //     if (!isNullOrUndefined(_getCategoriesTree.data)) {
      //         const _categoriesTree = _getCategoriesTree.data as KpDictionary<LoyaltyBrandProductDTO>;
      //         /* Get Products. */
      //         const _rootCategoryData = _getCategoriesTree.data as ExtendedCategoryDTO;
      //         if (!isNullOrUndefined(_rootCategoryData.childrenMapped) && _rootCategoryData.childrenMapped.Count()) {
      //             const _childCategoryData = _rootCategoryData.childrenMapped as KpDictionary<ExtendedCategoryDTO>;
      //             if (hasBought) {
      //                 const category = _rootCategoryData.children.find(c => {
      //                     var result = false;
      //                     if (c.metadata && c.metadata.length > 0) {
      //                         const metadata = this.utilService.valueFromKeyValueDTOArray(METADATA_KEY.DISPLAY_BUTTON, c.metadata);
      //                         result = (metadata && metadata == 'true');
      //                     }
      //                     return result;
      //                 })
      //                 if (category) {
      //                     _childCategoryData.Remove(category.id.toString());
      //                 }
      //             }
      //         }
      //
      //         const _getProductSelection = to(this.rewardsSrv.getProductSelection(
      //             _categoriesTree,
      //             -1,
      //             CATALOG_SHORTER_FILTER_TYPES.BY_ORDERING_ASC.NAME,
      //             0,
      //             rangeTo,
      //             stats,
      //             true
      //         ));
      //         await Promise.all([_getProductSelection]).then(arrayOfResults => {
      //             logger.log(arrayOfResults);
      //             if (!arrayOfResults) reject(null);
      //             else if (!isNullOrUndefined(arrayOfResults[0].data)) {
      //                 let _data = {
      //                     categories: _categoriesTree,
      //                     counters: null,
      //                     brandProducts: arrayOfResults[0].data as KpDictionary<ExtendedBrandProductDTO>
      //                 }
      //                 resolve(_data);
      //             } else reject(null);
      //         });
      //     } else reject(null);
      // }
    });
  }

  public async getUserAchievemnts(): Promise<
    KpDictionary<EventAchievementAction>
  > {
    return new Promise<KpDictionary<EventAchievementAction>>(
      async (resolve, reject) => {
        const __id = propValue(
          this.appGlobalsSrv.config,
          APPLICATION_SETTING_KEYS.USER_EVENT_ACHIEVEMENTS
        );
        const _data = await to(
          this.userService.getUserAchievements(
            TAValues.UserId,
            [__id],
            true,
            true,
            true,
            true,
            TAConstants.Settings.LANGUAGES,
            true,
            0,
            -1
          )
        );
        if (isNullOrUndefined(_data.data)) reject(null);
        else {
          const data = _data.data as ItemsList<userAchievement>;
          let _resp: KpDictionary<ExtendedEventAchievementAction> =
            new KpDictionary<ExtendedEventAchievementAction>();
          await data.map(async (achievement: userAchievement) => {
            if (isNullOrUndefined(achievement.userAchievement)) {
              reject(null);
            } else {
              const userAchievement = achievement.userAchievement;
              if (!isNullOrUndefined(userAchievement.eventAchievementActions)) {
                if (isArray(userAchievement.eventAchievementActions)) {
                  await userAchievement.eventAchievementActions.map(
                    (eventAchievementAction: EventAchievementAction) => {
                      if (!_resp.ContainsKey(eventAchievementAction.recId)) {
                        _resp.Add(eventAchievementAction.recId, {
                          ...eventAchievementAction,
                          resourcesMapped: this.utilService.formatItemResources(
                            eventAchievementAction
                          ),
                        });
                      }
                    }
                  );
                }
              }
            }
          });
          if (_resp.Count()) resolve(_resp);
          else reject(null);
        }
      }
    );
  }

  public loadMaxObtainablePts() {
    return this.maxObtainablePendingPtsSubject.getValue();
  }

  public saveMaxObtainablePts(value: number) {
    this.maxObtainablePendingPtsSubject.next(value);
  }

  private calculateWithMaxPendingPts(
    pendingPoints,
    maxPendingPoints
  ): MyPendingPoints[] {
    const totalPendingPoints = pendingPoints.reduce(
      (sum, obj) => parseInt(obj.amount, 10) + sum,
      0
    );
    if (totalPendingPoints > maxPendingPoints) {
      if (pendingPoints.length === 1)
        pendingPoints = [
          {
            ...pendingPoints[0],
            amount: maxPendingPoints.toString(),
            points: `${maxPendingPoints.toString()} punti`,
          },
        ];
      else if (pendingPoints.length > 1)
        pendingPoints = this.adjustPendingPtListValues(
          pendingPoints,
          maxPendingPoints
        );
    }
    return pendingPoints;
  }

  private adjustPendingPtListValues(array, max): MyPendingPoints[] {
    // listPendingPoints case when rsp.balance > maxPendingPoints:
    // show rsp.pendingPoints until reaching maxPendingPoints
    const adjustedArray: MyPendingPoints[] = [];
    let remainingPoints = max;
    for (const element of array) {
      const amount = parseInt(element.amount, 10);
      if (amount <= remainingPoints) {
        adjustedArray.push(element);
        remainingPoints -= amount;
      } else {
        element.amount = remainingPoints.toString();
        element.points = `${element.amount} punti`;
        adjustedArray.push(element);
        remainingPoints = 0;
      }
    }
    return adjustedArray;
  }

  public async getPendingPointsNew(
    pPQ: PendingPointsQ,
    noMaxObtainablePts: boolean = false
  ): Promise<Array<MyPendingPoints>> {
    return new Promise<Array<MyPendingPoints>>(async (resolve, reject) => {
      let maxPendingPoints: number;
      if (!noMaxObtainablePts) {
        maxPendingPoints = !this.loadMaxObtainablePts()
          ? undefined
          : this.loadMaxObtainablePts();
        if (!maxPendingPoints) {
          await this.getMaxObtainablePts()
            .then(async (res: number) => {
              /* This request gives the Yearly Maximum points
            a user can earn from Asset Purchases (count)
            and also the current points user reached (userCounter) */
              maxPendingPoints = !!res ? res : undefined;
              this.saveMaxObtainablePts(maxPendingPoints);
            })
            .catch((err) => console.error(err));
        }
      }

      let pendingPoints = await this.userService
        .getPendingPoints(TAValues.UserId, pPQ)
        .catch((err) => {});
      if (!pendingPoints) {
        resolve([]);
        return;
      }

      if (pendingPoints.pendingPoints) {
        if (!noMaxObtainablePts && maxPendingPoints) {
          pendingPoints.pendingPoints = this.calculateWithMaxPendingPts(
            pendingPoints.pendingPoints,
            maxPendingPoints
          );
        }
        resolve(this.getPendingPtsListData(pendingPoints.pendingPoints));
        return;
      }

      resolve([]);
    });
  }

  public getMaxObtainablePts(): Promise<number> {
    return this.userSrv.getMaxObtainablePoints();
  }

  private getPendingPtsListData(pendingPoints): MyPendingPoints[] {
    pendingPoints.forEach((p: MyPendingPoints) => {
      if (
        p.eventTypeId == 803 &&
        Number(p.amount) < 0 &&
        this.resourcesSrv.getResourcesBasic(
          p,
          Resource_Types.RESPONSIVE_TEXT
        ) &&
        this.resourcesSrv.getResourcesBasic(
          p,
          Resource_Types.RESPONSIVE_TEXT
        ) != ""
      ) {
        p.title = this.resourcesSrv.getResourcesBasic(
          p,
          Resource_Types.RESPONSIVE_TEXT
        );
      } else {
        p.title = this.resourcesSrv.getResourcesBasic(p, Resource_Types.NAME);
      }
      p.points = p.amount + " " + this.translateSrv.instant("POINTS");
      const d = Moment(new Date(p.pendingUntil))
        .locale(this.translateSrv.currentLang)
        .format("DD/MM");
      p.dateLabel = this.translateSrv
        .instant(
          Number(p.amount) < 0
            ? "PROFILE_PENDING_DATE_NEGATIVE"
            : "PROFILE_PENDING_DATE"
        )
        .replace("$date", d);
    });
    return pendingPoints;
  }

  /**
   * Get Pending Points
   * @param {PendingPointsQ} pPQ
   * @return {Promise<PendingPoints>}
   */
  public async getPendingPoints(pPQ: PendingPointsQ): Promise<PendingPoints> {
    return new Promise<PendingPoints>(async (resolve, reject) => {
      const sameDay = (d1, d2) => {
        return (
          d1.getFullYear() === d2.getFullYear() &&
          d1.getMonth() === d2.getMonth() &&
          d1.getDate() === d2.getDate()
        );
      };

      const pendingPoints = await this.userService
        .getPendingPoints(TAValues.UserId, pPQ)
        .catch((err) => {});

      if (!pendingPoints) {
        resolve({});
        return;
      }

      let previousDate;
      const result: PendingPoints = { sections: [] };

      if (pendingPoints.pendingPoints) {
        pendingPoints.pendingPoints.forEach((p) => {
          if (
            p.eventTypeId == 803 &&
            Number(p.amount) < 0 &&
            this.resourcesSrv.getResourcesBasic(
              p,
              Resource_Types.RESPONSIVE_TEXT
            ) &&
            this.resourcesSrv.getResourcesBasic(
              p,
              Resource_Types.RESPONSIVE_TEXT
            ) != ""
          ) {
            p.title = this.resourcesSrv.getResourcesBasic(
              p,
              Resource_Types.RESPONSIVE_TEXT
            );
          } else {
            p.title = this.resourcesSrv.getResourcesBasic(
              p,
              Resource_Types.NAME
            );
          }
          p.points = p.amount + " " + this.translateSrv.instant("POINTS");
          let section: PendingPointsSection = { pendingPoints: [] };
          let areSameDay = false;
          if (previousDate) {
            areSameDay = sameDay(
              new Date(previousDate),
              new Date(p.pendingUntil)
            );
          }
          // const date = Moment(p.pendingUntil, 'YYYY-MM-DD HH:mm:ss').toDate();

          if (!areSameDay) {
            previousDate = p.pendingUntil;
            const d = Moment(new Date(p.pendingUntil))
              .locale(this.translateSrv.currentLang)
              .format("DD MMMM");
            section.dateLabel = this.translateSrv
              .instant(
                Number(p.amount) < 0
                  ? "OVERVIEW_PENDING_DATE_NEGATIVE"
                  : "OVERVIEW_PENDING_DATE"
              )
              .replace("$date", d);
            section.pendingPoints.push(p);
            result.sections.push(section);
          } else {
            section = result.sections[result.sections.length - 1];
            section.pendingPoints.push(p);
          }
        });
      }

      resolve(result);
    });
  }

  public async tranferCurrency(
    amount: number,
    unitTypeId: number
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const input: SendVirtualAmountInput = {
        amount: amount,
        unitTypeId: unitTypeId,
        toUnitTypeId: unitTypeId,
      };
      this.userService
        .tranferCurrency(TAValues.UserId, input)
        .then((res) => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export interface PendingPoints {
  sections?: Array<PendingPointsSection>;
}

export interface PendingPointsSection {
  dateLabel?: string;
  pendingPoints?: Array<PendingPointDTO>;
}

export interface MyPendingPoints extends PendingPointDTO {
  dateLabel?: string;
}

export interface OverviewGenericContent extends IndexedDataContent {
  actionsStatus?: ActionStatusDTO;
}
