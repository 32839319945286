import { FavoriteService } from "../services/talos/favorite.service";
import { Injectable } from "@angular/core";
import { QuizDTO } from "../talosApi/models/QuizDTO";
import { TAConstants, TAValues } from "../talosApi/settings";
import Resource_Types = TAConstants.Resource_Types;
import { ResourcesServices } from "../services/talos/resources.services";
import { UploadContentDTO } from "../talosApi/models/UploadContentDTO";
import { ItemStatisticsDTO } from "../talosApi/models/ItemStatisticsDTO";
import STATISTIC_TYPES = TAConstants.STATISTIC_TYPES;
import moment from "moment";
import { ItemService } from "../services/talos/item.service";
import { IACtionButton } from "../../../src/shared/interfaces/IActionButton";
import { MaterialType } from "../app/pages/material/material.service";

@Injectable()
export class MaterialObject {
  constructor(private favoriteSrv?: FavoriteService) {}

  item_id?: string;
  item_type_id?: string;
  image?: {
    path: string;
    altText: string;
    default: string;
  };
  quizImage?: {
    path: string;
    altText: string;
    default: string;
  };
  quizSvgImage?: string;
  imageLarge?: {
    path: string;
    altText: string;
    default: string;
  };
  imageLargeMob?: {
    path: string;
    altText: string;
    default: string;
  };
  filterCategoryId?: string;
  filterCategory?: string;
  category?: string;
  categoryId?: string;
  title?: string;
  shortContent?: string;
  content?: string;
  date?: string;
  dateFull?: string;
  points?: string;
  pointsTag?: {
    label: string;
    variant: "info" | "success";
    disabled?: boolean;
    points?: number;
  };
  quizPointsTag?: {
    label: string;
    variant: "info" | "success";
    disabled?: boolean;
    points?: number;
  };
  categoryIcon?: string;
  pointsRef?: string;
  liked?: boolean;
  button?: string;
  url?: string;
  videoUrl?: string;
  reference?: any;
  duration?: number;
  shareOptions?: { enabled: boolean; type: Array<number> };
  redeemable?: boolean;
  couponCode?: string;
  selected?: boolean;
  active?: boolean;
  order?: number;
  materialType?: number;
  authorName?: string;
  overviewButton?: string;
  footerNote?: string;
  actionButton?: IACtionButton | null;
  isGolden?: boolean;
  done?: boolean;
  isPinned?: boolean;
  timeRemaining?: {
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
  };
  like(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.favoriteSrv
        .addFavorite(TAValues.UserId, this.item_id, Number(this.item_type_id))
        .then((result) => {
          this.liked = true;
          resolve(result);
        }, reject);
    });
  }

  undolike(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.favoriteSrv
        .removeFavorite(
          TAValues.UserId,
          this.item_id,
          Number(this.item_type_id)
        )
        .then((result) => {
          this.liked = false;
          resolve(result);
        }, reject);
    });
  }
}
