import { Injectable } from "@angular/core";
import { CategoryService } from "../../../services/talos/category.service";
import { UploadContentsService } from "../../../services/talos/uploadContents.service";
import { TAConstants, TAValues } from "../../../talosApi/settings";
import { CategoryDTO } from "../../../talosApi/models/CategoryDTO";
import { UploadContentDTO } from "../../../talosApi/models/UploadContentDTO";
import { AppGlobalsService } from "../../../services/appGlobals.service";
import _ from "lodash";
import { QuizDTO } from "../../../talosApi/models/QuizDTO";
import { QuizzesService } from "../../../services/talos/quizzes.service";
import { FilterTag } from "../../shared/components/icon-item-filter-tag-ang/icon-item-filter-tag-ang.component";
import { EventRewardRulesDTO } from "../../../talosApi/models/EventRewardRulesDTO";
import { EventService } from "../../../services/talos/event.service";
import { ResourcesServices } from "../../../services/talos/resources.services";
import { SortTag } from "../../shared/components/icon-item-shorter-ang/icon-item-shorter-ang.component";
import { ItemService } from "../../../services/talos/item.service";
import { isNullOrUndefined } from "util";
import { UploadContentReferenceDTO } from "src/talosApi/models/UploadContentReferenceDTO";
import { UserApi } from "../../../talosApi/api/UserApi";
import { IEventInput } from "../../../talosApi/api/EventApi";
import { GetUserActionStatusInput } from "../../../talosApi/models/GetUserActionStatusInput";
import { GetUploadContentsGroupByQuery } from "../../../talosApi/api/UploadContentsApi";
import { CachingService } from "../../../services/caching.service";
import { EventItemRewardsDTO } from "../../../talosApi/models/EventItemRewardsDTO";
import {
  CardHolderItem,
  CardItem,
} from "../sustainability/sustainability.service";
import { AsyncCategoryService } from "src/services/asyncTalos/async.category.service";
import Resource_Types = TAConstants.Resource_Types;
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import ITEM_TYPE = TAConstants.ITEM_TYPE;
import UPLOAD_CONTENT_TYPE = TAConstants.UPLOAD_CONTENT_TYPE;
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import Settings = TAConstants.Settings;
import QUIZ_TYPES = TAConstants.QUIZ_TYPES;
import Events_Types = TAConstants.Events_Types;
import LANGUAGES = TAConstants.Settings.LANGUAGES;
import ORDER_TYPE = TAConstants.ORDER_TYPE;
import Unit_Types = TAConstants.Unit_Types;

@Injectable({
  providedIn: "root",
})
export class MaterialService {
  private materialCountById: Map<number, number> = new Map<number, number>();
  materialConfig;
  materialItemCountById: Map<string, UploadContentDTO> = new Map<
    string,
    UploadContentDTO
  >();
  eventRewards: Map<number, string>;
  categoryIdByMaterialId: Map<string, string> = new Map<string, string>();
  eventRewardEvaluate: Map<number, number> = new Map<number, number>();

  constructor(
    private categoryService: CategoryService,
    private uploadContentsService: UploadContentsService,
    private quizzesSrv: QuizzesService,
    private appGlobalsSrv: AppGlobalsService,
    private eventSrv: EventService,
    private resourcesSrv: ResourcesServices,
    private itemSrv: ItemService,
    private userApi: UserApi,
    private cachingSrv: CachingService,
    private categorySrv: AsyncCategoryService
  ) {
    if (appGlobalsSrv.config) {
      this.materialConfig =
        appGlobalsSrv.config[APPLICATION_SETTING_KEYS.MATERIAL_CONFIG] || null;
      // this.getCategoryIdByType(MaterialType.ARTICLE, APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS);
      // this.getCategoryIdByType(MaterialType.VIDEO, APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS);
      // this.getCategoryIdByType(MaterialType.QUIZ, APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS);
    }
  }

  getMaterialConfig() {
    if (this.materialConfig) return this.materialConfig;

    if (this.appGlobalsSrv.config)
      this.materialConfig =
        this.appGlobalsSrv.config[APPLICATION_SETTING_KEYS.MATERIAL_CONFIG] ||
        null;

    return this.materialConfig;
  }

  getTypeByCategoryId(categoryId: string, appSettingKey: string): number {
    let result = -1;
    const materialConfig = this.getMaterialConfig();
    let materialTypeIds: Array<any> = materialConfig[appSettingKey];
    if (materialTypeIds) {
      materialTypeIds.forEach((item) => {
        if (item.hasOwnProperty(categoryId)) result = item[categoryId];
      });
    }
    return result;
  }

  getCategoryIdByType(type: number, appSettingKey: string): string {
    // if (this.categoryIdByMaterialId[type + "_" + appSettingKey]) {
    //     return this.categoryIdByMaterialId[type + "_" + appSettingKey];
    // }
    let result = null;
    const materialConfig = this.getMaterialConfig();
    let materialTypeIds: Array<any> = materialConfig[appSettingKey];
    if (materialTypeIds) {
      materialTypeIds.forEach((item) => {
        for (let key in item) {
          if (item[key] == type) result = key;
        }
      });
    }
    this.categoryIdByMaterialId[type + "_" + appSettingKey] = result;
    return result;
  }

  getMaterialHeaders(): Promise<Array<CategoryDTO>> {
    return new Promise((resolve, reject) => {
      const materialConfig = this.getMaterialConfig();
      var mainCategoryId =
        materialConfig[APPLICATION_SETTING_KEYS.MATERIAL_CATEGORY_ID];
      if (!mainCategoryId) {
        reject("No main category found!!");
        return;
      }

      this.categoryService
        .getCategoryById(mainCategoryId, false, false, false, [
          Resource_Types.NAME,
          Resource_Types.HREF,
        ])
        .then((category: CategoryDTO) => {
          if (category && category.children) {
            resolve(category.children);
            return;
          }

          resolve([]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getQuizzesHeaders(
    quizFilters: Array<QuizFilterItem>
  ): Promise<Array<QuizFilterItem>> {
    return new Promise((resolve, reject) => {
      const cachedFilters = this.cachingSrv.filterQuizzes;
      if (false && cachedFilters) {
        resolve(cachedFilters);
        return;
      }
      const filtersToRequest = [];

      quizFilters.forEach((filter: QuizFilterItem) => {
        const count = this.materialCountById[filter.id];
        if (count) {
          filter.count = count;
        } else {
          filtersToRequest.push(filter);
        }
      });

      if (filtersToRequest.length == 0) {
        resolve(quizFilters);
        return;
      }

      const materialConfig = this.getMaterialConfig();
      const categoryId =
        materialConfig[APPLICATION_SETTING_KEYS.MATERIAL_QUIZ_MAIN_CATEGORY_ID];

      const promises = filtersToRequest.map((f: QuizFilterItem) => {
        return this.getQuizItems(f.id, categoryId, 0, 1, true, null, false);
      });

      Promise.all(promises).then(
        (arrayOfResults) => {
          for (let i = 0; i < filtersToRequest.length; i++) {
            if (filtersToRequest[i] && arrayOfResults[i]) {
              filtersToRequest[i].count =
                arrayOfResults[i][TAConstants.TAHeaders.ITEM_COUNT];
              this.materialCountById[filtersToRequest[i].id] = Number(
                arrayOfResults[i][TAConstants.TAHeaders.ITEM_COUNT]
              );
            }
          }
          this.cachingSrv.filterQuizzes = quizFilters;
          resolve(quizFilters);
        },
        (err) => {
          logger.log(err);
          resolve(quizFilters);
        }
      );
    });
  }

  private getMaterialFiltersCounts(
    type: MaterialType,
    filters: Array<CategoryDTO>
  ): Promise<Array<CategoryDTO>> {
    return new Promise((resolve, reject) => {
      const categoryId = this.getCategoryIdByType(
        type,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      if (!categoryId) {
        resolve(filters);
        return;
      }

      let categoriesToRequest = new Array<CategoryDTO>();

      filters.forEach((f: CategoryDTO) => {
        const count =
          this.materialCountById[
            Number(categoryId + f.itemIdForResourceLookup)
          ];
        if (count) {
          f["total-count"] = count;
        } else {
          categoriesToRequest.push(f);
        }
      });

      if (categoriesToRequest.length == 0) {
        resolve(filters);
        return;
      }

      const promises = categoriesToRequest.map((f: CategoryDTO) => {
        return this.getMaterialItems(
          [categoryId, f.itemIdForResourceLookup],
          null,
          0,
          1,
          false,
          null,
          false
        );
      });

      Promise.all(promises).then(
        (arrayOfResults) => {
          for (let i = 0; i < categoriesToRequest.length; i++) {
            if (categoriesToRequest[i] && arrayOfResults[i]) {
              categoriesToRequest[i]["total-count"] =
                arrayOfResults[i][TAConstants.TAHeaders.ITEM_COUNT];
              this.materialCountById[
                Number(
                  categoryId + categoriesToRequest[i].itemIdForResourceLookup
                )
              ] = Number(arrayOfResults[i][TAConstants.TAHeaders.ITEM_COUNT]);
            }
          }
          resolve(filters);
        },
        (err) => {
          logger.log(err);
          resolve(filters);
        }
      );
    });
  }

  getMaterialFiltersGuadagna(): Promise<Array<CategoryDTO>> {
    return new Promise((resolve, reject) => {
      const materialConfig = this.getMaterialConfig();

      var mainCategoryId =
        materialConfig[APPLICATION_SETTING_KEYS.MATERIAL_FILTER_CATEGORY_ID];
      if (!mainCategoryId) {
        resolve([]);
        return;
      }

      var cachedCategories =
        this.cachingSrv.filterCategoriesById.get(mainCategoryId);
      if (cachedCategories) {
        resolve(cachedCategories);
        return;
      }

      this.categorySrv
        .getCategoryById(mainCategoryId, false, false, false, [
          Resource_Types.NAME,
          Resource_Types.IMAGE,
          Resource_Types.DESCRIPTION,
          Resource_Types.ACTION_TYPE,
          Resource_Types.ACTION_TYPE_DESCRIPTION,
        ])
        .then(async (cat: CategoryDTO) => {
          // var cat = (JSON.parse(JSON.stringify(category)));
          this.cachingSrv.filterCategoriesById.set(
            mainCategoryId,
            cat.children
          );
          resolve(cat.children);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getMaterialFilters(
    type: MaterialType,
    getCounters: boolean = true
  ): Promise<Array<CategoryDTO>> {
    return new Promise((resolve, reject) => {
      const materialConfig = this.getMaterialConfig();
      const categoryId = this.getCategoryIdByType(
        type,
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      if (!categoryId) {
        resolve([]);
        return;
      }

      var mainCategoryId =
        materialConfig[APPLICATION_SETTING_KEYS.MATERIAL_FILTER_CATEGORY_ID];
      if (!mainCategoryId) {
        resolve([]);
        return;
      }

      var cachedCategories =
        this.cachingSrv.filterCategoriesById.get(categoryId);
      if (cachedCategories) {
        resolve(cachedCategories);
        return;
      }

      this.categoryService
        .getCategoryById(mainCategoryId, false, false, false, [
          Resource_Types.NAME,
        ])
        .then(async (category: CategoryDTO) => {
          if (getCounters == false) {
            resolve(category.children);
            return;
          }

          var cat = JSON.parse(JSON.stringify(category));

          if (cat && cat.children && cat.children.length > 0) {
            const ids = cat.children.map((c) => {
              return String(c.itemIdForResourceLookup);
            });
            const query: GetUploadContentsGroupByQuery = {
              groupByIds: ids,
              groupByType: "BY_CATEGORY",
              status: 2,
              refItemIds: [String(categoryId)],
              refItemTypeId: ITEM_TYPE.CATEGORY,
              matchingLevel: true,
            };
            const counters = await this.uploadContentsService
              .groupByUploadContent(query)
              .catch((err) => {});
            if (!counters) {
              resolve([]);
              return;
            }

            for (let i = 0; i < cat.children.length; i++) {
              cat.children[i]["total-count"] = 0;
              const counter = counters.find((c) => {
                return c.itemId == String(cat.children[i].id);
              });
              if (counter) {
                cat.children[i]["total-count"] = counter.noOfRecords;
                // this.materialCountById[Number(categoryId + categoriesToRequest[i].itemIdForResourceLookup)] = Number(arrayOfResults[i][TAConstants.TAHeaders.ITEM_COUNT]);
              }
            }

            this.cachingSrv.filterCategoriesById.set(categoryId, cat.children);
            resolve(cat.children);
            // if (getCounters == false) {
            //     resolve(category.children);
            //     return;
            // }
            // this.getMaterialFiltersCounts(type, category.children).then((items: Array<CategoryDTO>) => {
            //     resolve(items);
            // }).catch(err => {
            //     reject(err);
            // });
          } else resolve([]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public getQuizItemsBySelections(
    type: MaterialType,
    filterType: FilterTag,
    sortType: SortTag,
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<QuizDTO>> {
    // const categoryId = this.getCategoryIdByType(type, APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS);
    const materialConfig = this.getMaterialConfig();
    const categoryId =
      materialConfig[APPLICATION_SETTING_KEYS.MATERIAL_QUIZ_MAIN_CATEGORY_ID];
    const quizTypeId =
      filterType == FilterTag.MAT_QUIZ
        ? QUIZ_TYPES.QUIZ
        : filterType == FilterTag.MAT_SURVEY
        ? QUIZ_TYPES.SURVEY
        : null;
    const sort = this.getOrderStringBySortType(Number(sortType));

    return this.getQuizItems(
      quizTypeId,
      categoryId,
      rangeFrom,
      rangeTo,
      false,
      sort,
      false
    );
  }

  private getQuizItems(
    quizTypeId: number,
    categoryId: string,
    rangeFrom: number,
    rangeTo: number,
    onlyCount: boolean,
    sort: string,
    includeQuestions: boolean = true
  ): Promise<Array<QuizDTO>> {
    return new Promise((resolve, reject) => {
      this.quizzesSrv
        .listQuiz(
          null,
          quizTypeId,
          categoryId,
          TAConstants.Settings.GAME_TYPE,
          rangeFrom,
          rangeTo,
          onlyCount,
          sort,
          includeQuestions
        )
        .then(async (items: Array<QuizDTO>) => {
          if (items) {
            if (rangeTo != 1) {
              const quizIds = [];
              const surveyIds = [];
              items.forEach((i) => {
                if (i.quizType == QUIZ_TYPES.QUIZ) quizIds.push(i.id);
                else if (i.quizType == QUIZ_TYPES.SURVEY) surveyIds.push(i.id);
              });

              if (quizIds && quizIds.length > 0) {
                const input: GetUserActionStatusInput = {
                  itemIds: quizIds,
                  itemTypeId: ITEM_TYPES.QUIZ,
                  unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
                  includeLimitations: true,
                };

                const actions = await this.itemSrv
                  .getUserActions(Events_Types.QUIZ_COMPLETION, null, input)
                  .catch((err) => {});

                if (actions) {
                  actions.forEach((a) => {
                    items.forEach((i) => {
                      if (
                        a.itemId == i.id &&
                        a.actionsStatus &&
                        a.actionsStatus.length > 0
                      ) {
                        i.actionsStatus = a.actionsStatus[0];
                      }
                    });
                  });
                }
              }

              if (surveyIds && surveyIds.length > 0) {
                const input: GetUserActionStatusInput = {
                  itemIds: surveyIds,
                  itemTypeId: ITEM_TYPES.QUIZ,
                  unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
                  includeLimitations: true,
                };

                const actions = await this.itemSrv
                  .getUserActions(
                    Events_Types.POLL_SURVEY_COMPLETED,
                    null,
                    input
                  )
                  .catch((err) => {});
                if (actions) {
                  actions.forEach((a) => {
                    items.forEach((i) => {
                      if (
                        a.itemId == i.id &&
                        a.actionsStatus &&
                        a.actionsStatus.length > 0
                      ) {
                        i.actionsStatus = a.actionsStatus[0];
                      }
                    });
                  });
                }
              }

              // const events = [Events_Types.QUIZ_COMPLETION, Events_Types.POLL_SURVEY_COMPLETED];

              // const input: TransactionHistoryInput = {
              //     unitTypeId: [Unit_Types.IQOS_CLUB_IT_POINTS],
              //     itemTypeId: ITEM_TYPES.QUIZ,
              //     itemIds: ids,
              //     includeDetails: true,
              //     resources: true,
              //     metadatas: true,
              //     languageIds: TAConstants.Settings.LANGUAGES,
              //     excludeZeroAmounts:true,
              //     eventTypeIds: events
              // };
              //
              // const transactions = await this.userApi.transactionsHistory(TAValues.UserId, input).catch(err => {
              // });
              // if (transactions) {
              //     transactions.forEach(t => {
              //         items.forEach(i => {
              //             if (t.transactionDetails && t.transactionDetails.referencedItem) {
              //                 if (i.id == t.transactionDetails.referencedItem.itemId) {
              //                     i.amountEarned = t.amount;
              //                 }
              //
              //             }
              //         })
              //     })
              // }

              // const query: IEvaluateEventQuery= {
              //     itemIds: ids,
              //     itemTypeId: ITEM_TYPES.QUIZ,
              //     resources: true,
              //     metadatas: true,
              //     languageIds: TAConstants.Settings.LANGUAGES};
              //
              // const promises = events.map(event=>{
              //     const input: IEventInput = {
              //         userId: TAValues.UserId,
              //         eventTypeId: event,
              //         gameTypeId: Settings.GAME_TYPE,
              //         clientTypeId: TAValues.CLIENT_TYPE_ID,
              //         withNoRewards: false,
              //         applicationId: TAValues.APPLICATION_ID
              //     }
              //     return this.eventSrv.evaluateEvent(query, input);
              // })

              // const input: GetUserActionStatusInput = {
              //     itemIds: ids,
              //     itemTypeId: ITEM_TYPES.QUIZ,
              //     unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
              //     includeLimitations: true
              // };
              //
              // const promises = events.map(event=>{
              //     return this.itemSrv.getUserActions(event, null, input);
              // })
              //
              // // const actions = await this.itemSrv.getUserActions(event, null, input).catch(err=>{});
              // // if(actions) {
              // //     actions.forEach(a => {
              // //         items.forEach(i => {
              // //             if (a.itemId == i.uploadContentId && a.actionsStatus && a.actionsStatus.length > 0) {
              // //                 i.actionsStatus = a.actionsStatus[0];
              // //             }
              // //         })
              // //     })
              // // }
              // await Promise.all(promises).then(arrayOfResults => {
              //     if(arrayOfResults){
              //         arrayOfResults.forEach(r=>{
              //             r.forEach(e => {
              //                 items.forEach(i => {
              //                     if (e.itemId == i.id && e.rewards && e.rewards.length > 0) {
              //                         i.reward = e.rewards[0];
              //                     }
              //                 })
              //             })
              //         })
              //     }
              // }, (err) => {
              //
              // });
            }
            resolve(items);
            return;
          }

          resolve([]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public getOrderStringBySortType(sortType: SortTag): string {
    let result = undefined;
    switch (sortType) {
      // case SortTag.NOT_DONE:
      //     result = '';
      //     break;
      case SortTag.RECENT:
        result = ORDER_TYPE.BY_RECENT;
        break;
      case SortTag.OLDER:
        result = ORDER_TYPE.BY_OLDER;
        break;
      case SortTag.NOT_VIEWED:
        result = ORDER_TYPE.BY_NOT_VIEWED;
        break;
      case SortTag.BY_ORDERING_DESC:
        result = ORDER_TYPE.BY_ORDERING_DESC;
        break;
      case SortTag.BY_ORDERING_ASC:
        result = ORDER_TYPE.BY_ORDERING_ASC;
        break;
      default:
        result = null;
        break;
    }
    return result;
  }

  public getMaterialItemsBySelections(
    type: MaterialType,
    filterType: FilterTag,
    sortType: SortTag,
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<UploadContentDTO>> {
    const categoryIds: Array<string> = [];
    const categoryId = this.getCategoryIdByType(
      type,
      APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
    );
    if (categoryId) categoryIds.push(categoryId);
    if (filterType != FilterTag.ALL) {
      const filtercategoryId = this.getCategoryIdByType(
        filterType,
        APPLICATION_SETTING_KEYS.MATERIAL_FILTER_TYPE_IDS
      );
      if (filtercategoryId) categoryIds.push(filtercategoryId);
    }

    const sort = this.getOrderStringBySortType(Number(sortType));
    return this.getMaterialItems(
      categoryIds,
      sort,
      rangeFrom,
      rangeTo,
      true,
      type
    );
  }

  private getMaterialItems(
    categoryIds: Array<string>,
    sort: string,
    rangeFrom: number,
    rangeTo: number,
    requestResources: boolean = true,
    type: MaterialType = null,
    includeStatistics: boolean = true
  ): Promise<Array<UploadContentDTO>> {
    return new Promise((resolve, reject) => {
      // const stats = includeStatistics && this.appGlobalsSrv.config.includeStatistics;
      const stats = sort == "BY_NOT_VIEWED";
      this.uploadContentsService
        .getUploadContents(
          null,
          UPLOAD_CONTENT_TYPE.GENERAL,
          sort,
          rangeFrom,
          rangeTo,
          stats,
          categoryIds,
          ITEM_TYPE.CATEGORY,
          true,
          false,
          false,
          [
            Resource_Types.NAME,
            Resource_Types.SHORT_DESCRIPTION,
            Resource_Types.DESCRIPTION,
            Resource_Types.URL_PATH,
            Resource_Types.VIDEO_URL,
          ],
          requestResources
        )
        .then(async (items: Array<UploadContentDTO>) => {
          if (items && items.length > 0) {
            if (type) {
              // if (type == MaterialType.VIDEO || type == MaterialType.ARTICLE) {
              //     const ids = items.map(i => {
              //         return i.uploadContentId
              //     });
              //     const input: TransactionHistoryInput = {
              //         unitTypeId: [Unit_Types.IQOS_CLUB_IT_POINTS],
              //         itemTypeId: ITEM_TYPES.UPLOAD_CONTENT,
              //         itemIds: ids,
              //         includeDetails: true,
              //         resources: true,
              //         metadatas: true,
              //         languageIds: TAConstants.Settings.LANGUAGES,
              //         excludeZeroAmounts: true,
              //         eventTypeIds: [type == MaterialType.ARTICLE ? Events_Types.ITEM_VIEWED : Events_Types.VIDEO_WATCHED]
              //     };
              //     const transactions = await this.userApi.transactionsHistory(TAValues.UserId, input).catch(err => {
              //     });
              //     if (transactions) {
              //         transactions.forEach(t => {
              //             items.forEach(i => {
              //                 if (t.transactionDetails && t.transactionDetails.referencedItem) {
              //                     if (i.uploadContentId == t.transactionDetails.referencedItem.itemId) {
              //                         i.amountEarned = t.amount;
              //                     }
              //
              //                 }
              //             })
              //         })
              //     }
              // } else if(type == MaterialType.SHARE) {
              // const events = [Events_Types.IQOS_CLUB_IT_CLICKED_SHARE_WA_SMS_EMAIL];
              const ids = items.map((i) => {
                return i.uploadContentId;
              });

              const input: GetUserActionStatusInput = {
                itemIds: ids,
                itemTypeId: ITEM_TYPES.UPLOAD_CONTENT,
                unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
                includeLimitations: true,
              };
              const event =
                type == MaterialType.ARTICLE
                  ? Events_Types.ITEM_VIEWED
                  : type == MaterialType.VIDEO
                  ? Events_Types.VIDEO_WATCHED
                  : Events_Types.IQOS_CLUB_IT_CLICKED_SHARE_WA_SMS_EMAIL;

              const actions = await this.itemSrv
                .getUserActions(event, null, input)
                .catch((err) => {});
              if (actions) {
                actions.forEach((a) => {
                  items.forEach((i) => {
                    if (
                      a.itemId == i.uploadContentId &&
                      a.actionsStatus &&
                      a.actionsStatus.length > 0
                    ) {
                      i.actionsStatus = a.actionsStatus[0];
                    }
                  });
                });
              }

              // const input: TransactionHistoryInput = {
              //     unitTypeId: [Unit_Types.IQOS_CLUB_IT_POINTS],
              //     itemTypeId: ITEM_TYPES.UPLOAD_CONTENT,
              //     itemIds: ids,
              //     includeDetails: true,
              //     resources: true,
              //     metadatas: true,
              //     languageIds: TAConstants.Settings.LANGUAGES,
              //     excludeZeroAmounts: true,
              //     eventTypeIds: [type == MaterialType.ARTICLE ? Events_Types.ITEM_VIEWED : (type == MaterialType.VIDEO ? Events_Types.VIDEO_WATCHED : Events_Types.IQOS_CLUB_IT_CLICKED_SHARE_WA_SMS_EMAIL)]
              // };
              //
              //
              // const transactions = await this.userApi.transactionsHistory(TAValues.UserId, input).catch(err => {
              // });
              // if (transactions) {
              //     transactions.forEach(t => {
              //         items.forEach(i => {
              //             if (t.transactionDetails && t.transactionDetails.referencedItem) {
              //                 if (i.uploadContentId == t.transactionDetails.referencedItem.itemId) {
              //                     i.amountEarned = t.amount;
              //                 }
              //             }
              //         })
              //     })
              // }
              // const query: IEvaluateEventQuery= {
              //     itemIds: ids,
              //     itemTypeId: ITEM_TYPES.UPLOAD_CONTENT,
              //     resources: true,
              //     metadatas: true,
              //     languageIds: TAConstants.Settings.LANGUAGES};
              //
              // const inputEvaluate: IEventInput = {
              //     userId: TAValues.UserId,
              //     eventTypeId: type == MaterialType.ARTICLE ? Events_Types.ITEM_VIEWED : (type == MaterialType.VIDEO ? Events_Types.VIDEO_WATCHED : Events_Types.IQOS_CLUB_IT_CLICKED_SHARE_WA_SMS_EMAIL),
              //     gameTypeId: Settings.GAME_TYPE,
              //     clientTypeId: TAValues.CLIENT_TYPE_ID,
              //     withNoRewards: false,
              //     applicationId: TAValues.APPLICATION_ID
              // };
              //
              // const events = await this.eventSrv.evaluateEvent(query, inputEvaluate).catch(err=>{
              // });
              // if (events){
              //     events.forEach(e => {
              //         items.forEach(i => {
              //             if (e.itemId == i.uploadContentId && e.rewards && e.rewards.length > 0) {
              //                     i.reward = e.rewards[0];
              //             }
              //         })
              //     })
              // }
              // }
            }
            resolve(items);
            return;
          }

          resolve([]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getEventRewardsRules(): Promise<Map<number, string>> {
    return new Promise((resolve, reject) => {
      this.eventSrv
        .getEventRewardRules(
          Settings.GAME_TYPE,
          [
            Events_Types.ITEM_VIEWED,
            Events_Types.VIDEO_WATCHED,
            Events_Types.QUIZ_COMPLETION,
            Events_Types.POLL_SURVEY_COMPLETED,
          ],
          true,
          LANGUAGES
        )
        .then((result: Array<EventRewardRulesDTO>) => {
          this.eventRewards = new Map<number, string>();
          if (result) {
            result.forEach((e: EventRewardRulesDTO) => {
              let testString =
                e.rewards && e.rewards.length > 0
                  ? this.resourcesSrv.getResourcesBasic(
                      e.rewards[0],
                      Resource_Types.NAME
                    )
                  : "";
              if (testString == "") {
                testString =
                  e.rewards && e.rewards.length > 1
                    ? this.resourcesSrv.getResourcesBasic(
                        e.rewards[1],
                        Resource_Types.NAME
                      )
                    : "";
              }
              if (testString != "") {
                this.eventRewards[e.eventTypeId] = testString;
              }
            });
          }
          resolve(this.eventRewards);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  sendWatchedEvent(
    itemId: string,
    itemTypeId: string,
    statisticType: number
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.itemSrv
        .submitStatistics(itemId, Number(itemTypeId), statisticType, 1)
        .then((result: any) => {
          resolve("SUCCESS");
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Gets assoc category
   * @param item
   * @returns
   */
  public getAssocCategory(
    item: UploadContentDTO,
    categories: Array<CategoryDTO>
  ): UploadContentDTO {
    if (isNullOrUndefined(item) || isNullOrUndefined(categories)) return;
    let _ret: UploadContentDTO = null;
    logger.log(item, categories);
    item.references.map((ref: UploadContentReferenceDTO) => {
      categories.map((cat: CategoryDTO) => {
        if (ref.itemId === cat.id.toString()) {
          _ret = cat;
        }
      });
    });
    return _ret;
  }

  public getEventRewards(): Promise<any> {
    return new Promise((resolve, reject) => {
      // const query: IEvaluateEventQuery= {
      //     itemIds: ids,
      //     itemTypeId: ITEM_TYPES.QUIZ,
      //     resources: true,
      //     metadatas: true,
      //     languageIds: TAConstants.Settings.LANGUAGES
      // };
      const events = [
        Events_Types.ITEM_VIEWED,
        Events_Types.VIDEO_WATCHED,
        Events_Types.QUIZ_COMPLETION,
        Events_Types.POLL_SURVEY_COMPLETED,
        Events_Types.CONTENT_QUIZ_COMPLETION,
      ];
      const promises = events.map((event) => {
        const input: IEventInput = {
          userId: TAValues.UserId,
          eventTypeId: event,
          eventProperties: "description=default\n",
          gameTypeId: Settings.GAME_TYPE,
          clientTypeId: TAValues.CLIENT_TYPE_ID,
          withNoRewards: false,
          applicationId: TAValues.APPLICATION_ID,
        };
        return this.eventSrv.evaluateEvent(null, input);
      });

      Promise.all(promises)
        .then((res) => {
          if (res) {
            res.forEach((r: Array<EventItemRewardsDTO>) => {
              if (
                r &&
                r.length > 0 &&
                r[0].rewards &&
                r[0].rewards.length > 0 &&
                r[0].rewards[0].amount
              ) {
                this.eventRewardEvaluate.set(
                  r[0].eventTypeId,
                  r[0].rewards[0].amount.amount
                );
              }
            });
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public async getCardHolders(): Promise<Array<CardHolderItem>> {
    return new Promise<Array<CardHolderItem>>(async (resolve, reject) => {
      const config =
        this.appGlobalsSrv.config[
          APPLICATION_SETTING_KEYS.SUSTAINABILITY_CONFIG
        ];
      if (config && config != "") {
        const categoryPersoneId =
          config[APPLICATION_SETTING_KEYS.SUSTAINABILITY_PERSONE_CATEGORY_ID];
        const categoryAmbienteId =
          config[APPLICATION_SETTING_KEYS.SUSTAINABILITY_AMBIENTE_CATEGORY_ID];
        const categoryIds = [];
        if (categoryPersoneId && categoryPersoneId != "")
          categoryIds.push(categoryPersoneId);
        if (categoryAmbienteId && categoryAmbienteId != "")
          categoryIds.push(categoryAmbienteId);

        if (categoryIds.length > 0) {
          const promise = (categoryId) => {
            return this.getCardHolderItem(categoryId);
          };

          const promises = categoryIds.map((c) => {
            return promise(c);
          });

          const cardHolders = await Promise.all(promises).catch((err) => {});
          if (!cardHolders) {
            resolve(null);
            return;
          }

          resolve(cardHolders);
        } else resolve(null);
      }
    });
  }

  public async getCardHolderItem(categoryId: string): Promise<CardHolderItem> {
    return new Promise<CardHolderItem>(async (resolve, reject) => {
      const category = await this.categorySrv
        .getCategoriesByRootId(
          categoryId,
          true,
          true,
          true,
          TAConstants.Settings.LANGUAGES
        )
        .catch((err) => {});
      if (!category) {
        resolve(null);
        return;
      }

      (category as CardHolderItem).title = this.resourcesSrv.getResourcesBasic(
        category,
        Resource_Types.NAME
      );
      (category as CardHolderItem).description =
        this.resourcesSrv.getResourcesBasic(
          category,
          Resource_Types.DESCRIPTION
        );
      (category as CardHolderItem).content1 =
        this.resourcesSrv.getResourcesBasic(category, Resource_Types.CONTENT_1);
      (category as CardHolderItem).content2 =
        this.resourcesSrv.getResourcesBasic(category, Resource_Types.CONTENT_2);
      if (category.children && category.children.length > 0) {
        category.children.forEach((l: CardItem) => {
          l.title = this.resourcesSrv.getResourcesBasic(l, Resource_Types.NAME);
          l.description = this.resourcesSrv.getResourcesBasic(
            l,
            Resource_Types.DESCRIPTION
          );
          l.action = this.resourcesSrv.getResourcesBasic(
            l,
            Resource_Types.ACTION_TYPE
          );
          l.actionLabel = this.resourcesSrv.getResourcesBasic(
            l,
            Resource_Types.ACTION_TYPE_DESCRIPTION
          );
          l.image = this.resourcesSrv.getResourcesBasic(
            l,
            this.appGlobalsSrv.isMobile
              ? Resource_Types.IMAGE_FEATURED_WEB
              : Resource_Types.IMAGE
          );
        });
      }
      resolve(category as CardHolderItem);
    });
  }
}

export enum MaterialType {
  ARTICLE = 1,
  VIDEO = 2,
  QUIZ = 3,
  SHARE = 4,
  TESTIMONIALS = 5,
  SURVEY = 6,
  QUICK_REPLY = 7,
  GAME = 8,
}

export enum MaterialFilterType {
  ALL = 10,
  ART_DESIGN = 11,
  SCIENCE = 12,
  INNOVATION = 13,
  QUIZ = 14,
  SURVEY = 15,
}

export enum MaterialSortType {
  RECENT = 21,
  OLDER = 22,
  NOT_VIEWED = 23,
  NOT_DONE = 24,
}

export interface QuizFilterItem {
  id?: number;
  name?: string;
  count?: number;
}
