import { Http2Talos } from "../http2Talos";
import { Injectable } from "@angular/core";
import {
  GetMultipleMetadataResponse,
  GetVItemsInput,
  ItemsList,
  ItemStatistics,
  ItemStatisticsDTO,
  ResourceDTO,
  VirtualItemsDTOS,
} from "../models";
import { HttpHeaders } from "@angular/common/http";
import { ResourceRequestDTO } from "../models/ResourceRequestDTO";
import { ItemMetadataDTO } from "../models/ItemMetadataDTO";
import { AchievementDTO } from "../models/AchievementDTO";

@Injectable()
export class AchievementApi {
  constructor(private http2Talos: Http2Talos) {}

  /**
   * Ge Achievements
   * @param {string} userId
   * @param {Array<number>} categoryIds
   * @param {number} rangeFrom
   * @param {number} rangeTo
   * @param {boolean} resources
   * @param {boolean} requireSession
   * @return {Promise<Array<AchievementDTO>>}
   */
  public getAchievements(
    userId: string,
    params: GetAchievementsParams,
    requireSession: boolean = true
  ): Promise<Array<AchievementDTO>> {
    return this.http2Talos.get({
      requireSession: requireSession,
      path: `/achievements/${userId}`,
      queryParams: params,
    });
  }
} // END CLASS

export interface GetAchievementsParams {
  achievementIds?: Array<string>;
  categoryIds?: Array<number>;
  rangeFrom?: number;
  rangeTo?: number;
  resources?: boolean;
  metadatas?: boolean;
  filterOverlappingAchievements?: boolean;
  achievementTypeIds?: Array<number>;
  active?: boolean;
  currentlyActive?: boolean;
  matchingLevel?: boolean;
  includeDetails?: boolean;
  languageIds?: Array<string>;
  orderType?: string;
}
