import { Injectable } from "@angular/core";
import { Http2Integration } from "../http2Integration";
import { Content } from "../models/Content";

@Injectable()
export class IntegratedApi {
  constructor(private http2Integration: Http2Integration) {}

  saveUploadContent(input: SaveUploadContentInput): Promise<any> {
    return this.http2Integration.post({
      path: `/integrated/service/save/uploadcontent`,
      input: input,
    });
  }

  searchUploadContent(
    input: SearchUploadContentInput
  ): Promise<Array<Content>> {
    return this.http2Integration.post({
      path: `/integrated/service/search/uploadcontent`,
      input: input,
    });
  }

  deletUploadContent(
    uploadContentId: string,
    input: DeleteUploadContentInput
  ): Promise<any> {
    return this.http2Integration.post({
      path: `/integrated/service/update/removeuploadcontent/${uploadContentId}`,
      input: input,
    });
  }
}

export interface SaveUploadContentInput {
  UserId?: string;
  UserGroupId?: string;
  ContentTypeId?: number;
  Title?: string;
  ParentUploadContentId?: string;
  Metadata?: Array<{ key?: string; value?: string }>;
}

export interface SearchUploadContentInput {
  ParentUploadContentId?: string;
  UserId?: string;
  UserGroupId?: string;
  ContentTypeId?: number;
}

export interface DeleteUploadContentInput {
  UploadContentId: string;
}
