import { Injectable } from "@angular/core";
import {
  GetUploadContentsGroupByQuery,
  UploadContentDataInput,
  UploadContentsApi,
} from "../../talosApi/api/UploadContentsApi";
import { UploadContentDTO } from "../../talosApi/models/UploadContentDTO";
import { ResourcesServices } from "./resources.services";
import { HttpHeaders } from "@angular/common/http";
import { TAConstants, TAValues } from "../../talosApi/settings";
import TAHeaders = TAConstants.TAHeaders;
import Item_type = TAConstants.ITEM_TYPE;
import { KpDictionary } from "../kp_dictionary/kp.dictionary";
import { ResourceDTO, ItemsList } from "src/talosApi";
import { to } from "../../../../src/utils/utils";
import { isNullOrUndefined } from "util";
import { resource } from "selenium-webdriver/http";
import {
  UploadContentReferenceDTO,
  ExtendedUploadContentReferenceDTO,
} from "src/talosApi/models/UploadContentReferenceDTO";
import { UploadContentsGroupByResponse } from "../../talosApi/models/UploadContentsGroupByResponse";
import { ItemActionStatusDTO } from "src/talosApi/models/ItemActionStatusDTO";

@Injectable()
export class UploadContentsService {
  private uploadContentById: Map<string, UploadContentDTO> = new Map<
    string,
    UploadContentDTO
  >();

  constructor(
    private api: UploadContentsApi,
    private resourcesSrv: ResourcesServices
  ) {}

  public getUploadContentByReferenceAndUploadContentType(
    refItemIds: Array<string>,
    refItemTypeIds: number,
    uploadContentTypeId: number,
    resources: boolean = true,
    languageIds: Array<string>,
    active: boolean = true,
    matchingLevel: boolean = true,
    orderType: string = null,
    includeStatistics?: boolean,
    rangeFrom?: number,
    rangeTo?: number
  ): Promise<ItemsList<UploadContentDTO>> {
    return new Promise<ItemsList<UploadContentDTO>>(async (resolve, reject) => {
      if (!languageIds.length) resources = false;
      const _getUploadContentsByReference = await to(
        this.api.getUploadContentsByReference(
          refItemIds,
          uploadContentTypeId,
          refItemTypeIds,
          resources,
          languageIds,
          active,
          matchingLevel,
          orderType,
          includeStatistics,
          rangeFrom ? rangeFrom : 0,
          rangeTo ? rangeTo : -1
        )
      );
      /* Check And Format Data. */
      if (isNullOrUndefined(_getUploadContentsByReference.data)) reject(null);
      else {
        if (isNullOrUndefined(_getUploadContentsByReference.data)) {
          reject(null);
        } else {
          resolve(_getUploadContentsByReference.data);
        }
      }
    });
  }

  /**
   * Gets upload content by category upload content type
   * @param categoryIds
   * @param uploadContentTypeId
   * @param [resources]
   * @param languageIds
   * @param [active]
   * @param [matchingLevel]
   * @param [includeStatistics]
   * @param [rangeFrom]
   * @param [rangeTo]
   * @returns
   */
  public getUploadContentByCategoryUploadContentType(
    categoryIds: Array<string> | Array<number>,
    uploadContentTypeId: number,
    resources: boolean = true,
    languageIds: Array<string>,
    active: boolean = true,
    matchingLevel: boolean = true,
    includeStatistics?: boolean,
    rangeFrom?: number,
    rangeTo?: number,
    orderType?: string
  ): Promise<ItemsList<UploadContentDTO> | Array<UploadContentDTO>> {
    return new Promise<ItemsList<UploadContentDTO> | Array<UploadContentDTO>>(
      async (resolve, reject) => {
        if (!languageIds.length) resources = false;
        const _getUploadContentsByReference = await to(
          this.api.getUploadContentsByReference(
            categoryIds,
            uploadContentTypeId,
            Item_type.CATEGORY,
            resources,
            languageIds,
            active,
            matchingLevel,
            orderType || "BY_ORDERING_ASC",
            includeStatistics,
            rangeFrom ? rangeFrom : 0,
            rangeTo ? rangeTo : -1,
            true
          )
        );
        /* Check And Format Data. */
        if (isNullOrUndefined(_getUploadContentsByReference.data)) reject(null);
        else {
          if (isNullOrUndefined(_getUploadContentsByReference.data)) {
            reject(null);
          } else {
            resolve(_getUploadContentsByReference.data);
          }
        }
      }
    );
  }

  /**
   * Gets upload content by category upload content type
   * @param categoryIds
   * @param uploadContentTypeId
   * @param [resources]
   * @param languageIds
   * @param [active]
   * @param [matchingLevel]
   * @param [includeStatistics]
   * @param [rangeFrom]
   * @param [rangeTo]
   * @returns
   */
  public getUploadContentByIdsContentType(
    uploadContentIds: Array<string>,
    uploadContentTypeId: number,
    resources: boolean = true,
    languageIds: Array<string>,
    metadatas: boolean = true,
    active: boolean = true,
    matchingLevel: boolean = true,
    includeStatistics?: boolean,
    rangeFrom?: number,
    rangeTo?: number
  ): Promise<ItemsList<UploadContentDTO>> {
    return new Promise<ItemsList<UploadContentDTO>>(async (resolve, reject) => {
      if (isNullOrUndefined(languageIds) || !languageIds.length)
        resources = false;
      const _data = await to(
        this.api.getUploadContentsByIds(
          uploadContentIds,
          uploadContentTypeId,
          resources,
          languageIds,
          metadatas,
          active,
          matchingLevel,
          null,
          includeStatistics,
          rangeFrom ? rangeFrom : 0,
          rangeTo ? rangeTo : -1
        )
      );
      /* Check And Format Data. */
      if (isNullOrUndefined(_data.data)) reject(null);
      else {
        if (isNullOrUndefined(_data.data)) {
          reject(null);
        } else {
          resolve(_data.data);
        }
      }
    });
  }

  getUploadContents(
    uploadContentIds?: Array<string>,
    uploadContentTypeId?: number,
    sort?: string,
    rangeFrom?: number,
    rangeTo?: number,
    includeStatistics?: boolean,
    refItemIds?: Array<string>,
    refItemTypeId?: number,
    sortByDate?: boolean,
    descendingOrder?: boolean,
    sortByRating?: boolean,
    resourceIds?: Array<number>,
    requestResources: boolean = true,
    force: boolean = false
  ): Promise<Array<UploadContentDTO>> {
    return new Promise((resolve, reject) => {
      let requiresApiCall = false;
      let storedUploadContents = new Array<UploadContentDTO>();
      if (uploadContentIds) {
        uploadContentIds.forEach((id: string) => {
          const item = this.uploadContentById[id];
          if (item) {
            storedUploadContents.push(item);
          } else {
            requiresApiCall = true;
          }
        });
      } else {
        requiresApiCall = true;
      }

      if (requiresApiCall == false && force == false) {
        resolve(storedUploadContents);
        return;
      }

      // let headers: HttpHeaders = new HttpHeaders();
      // headers = headers.append(TAHeaders.FILL_RESOURCES, 'metadata');
      this.api
        .getUploadContents(
          uploadContentIds,
          uploadContentTypeId,
          sort,
          rangeFrom,
          rangeTo,
          includeStatistics,
          refItemIds,
          refItemTypeId,
          sortByDate,
          descendingOrder,
          sortByRating
        )
        .then((response: Array<UploadContentDTO>) => {
          if (!response) {
            resolve([]);
            return;
          }

          // if (requestResources == false) {
          //     resolve(response);
          //     return;
          // }

          response.forEach((uploadContent) => {
            this.uploadContentById[uploadContent.itemIdForResourceLookup] =
              uploadContent;
          });

          resolve(response);
          //
          // this.resourcesSrv.getResources(response, 'itemIdForResourceLookup', resourceIds, 'itemTypeIdForResourceLookup')
          //     .then(next => {
          //         resolve(response);
          //     }).catch((error) => {
          //     reject(error);
          // });
        })
        .catch(reject);
    });
  }

  /**
   * Get Group By Upload Contents
   * @param {GetUploadContentsGroupByQuery} queryParams
   * @return {Promise<Array<UploadContentsGroupByResponse>>}
   */
  public groupByUploadContent(
    queryParams: GetUploadContentsGroupByQuery
  ): Promise<Array<UploadContentsGroupByResponse>> {
    return new Promise<Array<UploadContentsGroupByResponse>>(
      async (resolve, reject) => {
        this.api
          .groupByUploadContent(TAValues.UserId, queryParams)
          .then((response: Array<UploadContentsGroupByResponse>) => {
            if (!response) {
              resolve([]);
              return;
            }

            resolve(response);
          })
          .catch(reject);
      }
    );
  }

  /**
   * Add Upload Content
   * @param {string} userId
   * @param {UploadContentDataInput} input
   * @param {boolean} requireSession
   * @param {HttpHeaders} headers
   * @return {Promise<string>}
   */
  public addUploadContent(
    userId: string,
    input: UploadContentDataInput,
    requireSession: boolean = true,
    headers?: HttpHeaders
  ): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      this.api
        .addUploadContent(userId, input)
        .then((response: Array<UploadContentDTO>) => {
          resolve("OK");
        })
        .catch(reject);
    });
  }
}

export declare interface WebComponentDTO extends ExtendedUploadContentDTO {
  /**
   * Property Holds Config Resources in Dictionary Format.
   */
  baseConfiguration: KpDictionary<KpDictionary<ResourceDTO>>;
}

export declare interface ExtendedUploadContentDTO extends UploadContentDTO {
  /**
   * Property Holds Resources in Dictionary Format.
   */
  resourcesMapped: KpDictionary<KpDictionary<ResourceDTO>>;
  /**
   * Property Holds References in Dictionary Format.
   */
  referencesMapped: KpDictionary<ExtendedUploadContentReferenceDTO>;

  pointsLabel: string;
}
