import { Injectable } from "@angular/core";
import { CategoryService } from "../../../services/talos/category.service";
import {
  ExtendedUploadContentDTO,
  UploadContentsService,
} from "../../../services/talos/uploadContents.service";
import { AppGlobalsService } from "../../../services/appGlobals.service";
import { CategoryDTO } from "../../../talosApi/models/CategoryDTO";
import { TAConstants } from "../../../talosApi/settings";
import { UploadContentDTO } from "../../../talosApi/models/UploadContentDTO";
import { EventRewardRulesDTO } from "../../../talosApi/models/EventRewardRulesDTO";
import { ResourcesServices } from "../../../services/talos/resources.services";
import { EventService } from "../../../services/talos/event.service";
import { ItemService } from "../../../services/talos/item.service";
import { to } from "../../../../../src/utils/utils";
import { isArray, isNullOrUndefined } from "util";
import { UtilsService } from "src/services/utils.service";
import { ExtendedUploadContentReferenceDTO } from "src/talosApi/models/UploadContentReferenceDTO";
import { KpDictionary } from "src/services/kp_dictionary/kp.dictionary";
import { ItemsList } from "src/talosApi";
import moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { GetUserActionStatusInput } from "../../../talosApi/models/GetUserActionStatusInput";
import { ListQuizQuery } from "../../../talosApi/api/QuizApi";
import { QuizService } from "../quizzes/quiz/quiz.service";
import { QuizDetailService } from "../quizzes/quiz-detail/quiz-detail.service";
import { QuizDTO } from "../../../talosApi/models/QuizDTO";
import { MaterialService, MaterialType } from "../material/material.service";
import { FilterESContentDataInput } from "../../../talosApi/api/RecommendationsApi";
import { RecommendationsService } from "../../../services/talos/recommendations.service";
import { MaterialObject } from "../../../models/MaterialObject";
import Resource_Types = TAConstants.Resource_Types;
import UPLOAD_CONTENT_TYPE = TAConstants.UPLOAD_CONTENT_TYPE;
import ITEM_TYPE = TAConstants.ITEM_TYPE;
import Events_Types = TAConstants.Events_Types;
import Settings = TAConstants.Settings;
import LANGUAGES = TAConstants.Settings.LANGUAGES;
import APPLICATION_SETTING_KEYS = TAConstants.APPLICATION_SETTING_KEYS;
import STATISTIC_TYPES = TAConstants.STATISTIC_TYPES;
import Unit_Types = TAConstants.Unit_Types;
import METADATA_KEY = TAConstants.METADATA_KEY;
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import { UserService } from "src/services/talos/user.service";

@Injectable()
export class ArticleDetailService {
  materialConfig = {};
  eventRewards: Map<number, string>;
  startVideo: boolean = false;

  constructor(
    private categoryService: CategoryService,
    private uploadContentsService: UploadContentsService,
    private resourcesSrv: ResourcesServices,
    private eventSrv: EventService,
    private appGlobalsSrv: AppGlobalsService,
    private utilSrv: UtilsService,
    private itemSrv: ItemService,
    private translateSrv: TranslateService,
    private quizDetailSrv: QuizDetailService,
    private quizSrv: QuizService,
    private materialSrv: MaterialService,
    private recommendationsSrv: RecommendationsService,
    private userSrv: UserService
  ) {
    if (appGlobalsSrv.config)
      this.materialConfig =
        appGlobalsSrv.config[APPLICATION_SETTING_KEYS.MATERIAL_CONFIG] || {};
  }

  getArticleCategory(categoryId: number): Promise<CategoryDTO> {
    return new Promise((resolve, reject) => {
      if (!categoryId) {
        reject("No category found!!");
        return;
      }

      this.categoryService
        .getCategoryById(categoryId, false, false, false, [Resource_Types.NAME])
        .then((category: CategoryDTO) => {
          resolve(category);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Gets upload content by id
   * @param uploadContentId
   */
  public async getUploadContentById(
    uploadContentId: string,
    eventTypeId: number,
    withUserActions: boolean = false,
    uploadContentType: number = UPLOAD_CONTENT_TYPE.GENERAL
  ): Promise<ExtendedUploadContentDTO> {
    return new Promise<ExtendedUploadContentDTO>(async (resolve, reject) => {
      /* Try to get from Page Block. */
      if (!uploadContentId) reject(null);
      else {
        logger.log("uploadContentId", uploadContentId);
        const _data = await to(
          this.uploadContentsService.getUploadContentByIdsContentType(
            [uploadContentId],
            uploadContentType,
            true,
            TAConstants.Settings.LANGUAGES,
            true,
            true,
            null,
            true,
            0,
            1
          )
        );
        if (isNullOrUndefined(_data.data)) reject(null);
        else {
          let _resp = null;
          try {
            const _tmp = _data.data[0] as ExtendedUploadContentDTO;
            let _referencesMapped: KpDictionary<ExtendedUploadContentReferenceDTO> =
              new KpDictionary<ExtendedUploadContentReferenceDTO>();
            if (isArray(_tmp.references) && _tmp.references.length) {
              await _tmp.references.map(
                (reference: ExtendedUploadContentReferenceDTO) => {
                  if (
                    !_referencesMapped.ContainsKey(
                      reference.itemIdForResourceLookup
                    )
                  ) {
                    _referencesMapped.Add(
                      reference.itemIdForResourceLookup,
                      reference
                    );
                  }
                }
              );
            }

            const quizRef = _tmp.references.find((r) => {
              return r.itemTypeId == ITEM_TYPES.QUIZ;
            });

            const contentRef = _tmp.references.find((r) => {
              return r.itemTypeId == ITEM_TYPES.UPLOAD_CONTENT;
              // return r.itemTypeId == ITEM_TYPES.ITEM_TYPE_CONTENT;
            });

            if (withUserActions) {
              await this.itemSrv
                .getUserActions(eventTypeId, null, {
                  itemIds: [uploadContentId],
                  itemTypeId: ITEM_TYPE.UPLOADCONTENT,
                  unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
                  includeLimitations: true,
                })
                .then((ua) => {
                  if (
                    ua &&
                    ua[0] &&
                    ua[0].actionsStatus &&
                    isArray(ua[0].actionsStatus) &&
                    ua[0].actionsStatus.length
                  )
                    _tmp.actionsStatus = ua[0].actionsStatus[0];
                })
                .catch((error) => {
                  logger.log(error);
                });
            }

            if (contentRef) {
              const input: FilterESContentDataInput = {
                // itemTypeIds: [TAConstants.ITEM_TYPES.ITEM_TYPE_CONTENT],
                itemTypeIds: [TAConstants.ITEM_TYPES.UPLOAD_CONTENT],
                itemIds: [contentRef.itemId],
                currentlyRewarding: null,
                sortOrderTypes: ["BY_RECENT"],
                countMode: false,
                userRewarded: null,
                currentlyActive: true,
                matchingLevel: true,
                matchingRules: true,
              };
              // const content = await this.getRecommendation(input);
              const content = await this.getArticleById(
                contentRef.itemId,
                UPLOAD_CONTENT_TYPE.PMI_PLAYABLE_CONTENT
              );
              if (content) {
                const inputActions: GetUserActionStatusInput = {
                  itemIds: [content.uploadContentId],
                  itemTypeId: ITEM_TYPES.UPLOAD_CONTENT,
                  unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
                  includeLimitations: true,
                };
                const actions = await this.itemSrv
                  .getUserActions(
                    Events_Types.GAME_FINISHED,
                    null,
                    inputActions
                  )
                  .catch((err) => {});
                if (actions) {
                  actions.forEach((a) => {
                    // items.forEach(i => {
                    if (
                      a.itemId == content.uploadContentId &&
                      a.actionsStatus &&
                      a.actionsStatus.length > 0
                    ) {
                      content.actionsStatus = a.actionsStatus[0];
                    }
                    // })
                  });
                }
                _tmp.content = {
                  ...content,
                  ...{
                    gameUrl: this.resourcesSrv.getResourcesBasic(
                      content,
                      Resource_Types.SOURCE_URL
                    ),
                  },
                };
                // _tmp.content = content;
              }
            }
            if (quizRef) {
              const quiz = await this.quizDetailSrv
                .getQuizById(quizRef.itemId, null)
                .catch((err) => {});

              if (quiz) {
                const input: GetUserActionStatusInput = {
                  itemIds: [quizRef.itemId],
                  itemTypeId: ITEM_TYPES.QUIZ,
                  unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
                  includeLimitations: true,
                };

                const actions = await this.itemSrv
                  .getUserActions(
                    Events_Types.CONTENT_QUIZ_COMPLETION,
                    null,
                    input
                  )
                  .catch((err) => {});
                if (actions) {
                  actions.forEach((a) => {
                    // items.forEach(i => {
                    if (
                      a.itemId == quiz.id &&
                      a.actionsStatus &&
                      a.actionsStatus.length > 0
                    ) {
                      quiz.actionsStatus = a.actionsStatus[0];
                    }
                    // })
                  });
                }

                const cqId = this.resourcesSrv.getResourcesBasic(
                  _tmp,
                  TAConstants.Resource_Types.REFERENCE_TEXT
                );
                const interactive =
                  quiz.metadata &&
                  this.utilSrv.valueFromKeyValueDTOArray(
                    METADATA_KEY.QUIZ_ILC_ILUMA_TYPE,
                    quiz.metadata
                  ) == "true";
                if (interactive) {
                  _tmp.interactionQuiz = quiz;
                  if (cqId == quiz.id) _tmp.isContentQuiz = true;
                } else _tmp.quiz = quiz;
              }
            }

            _resp = {
              ..._tmp,
              ...{
                resourcesMapped: this.utilSrv.formatItemResources(_tmp),
                referencesMapped: _referencesMapped,
              },
            };
          } catch (e) {
            logger.log(e);
          }
          if (_resp) resolve(_resp);
          else reject(null);
        }
      }
    });
  }

  getArticleById(
    articleId: string,
    uploadContentType: number = UPLOAD_CONTENT_TYPE.GENERAL
  ): Promise<UploadContentDTO> {
    return new Promise((resolve, reject) => {
      if (!articleId) {
        reject("No article found!!");
        return;
      }

      this.uploadContentsService
        .getUploadContents(
          [articleId],
          uploadContentType,
          null,
          0,
          1,
          true,
          null,
          null,
          true,
          false,
          false,
          [
            Resource_Types.NAME,
            Resource_Types.SHORT_DESCRIPTION,
            Resource_Types.DESCRIPTION,
            Resource_Types.URL_PATH,
            Resource_Types.VIDEO_URL,
          ],
          true
        )
        .then((items: Array<UploadContentDTO>) => {
          if (items && items.length > 0) {
            resolve(items[0]);
            return;
          }

          resolve(null);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getRelatedItems(
    categoryIds: Array<string>,
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<UploadContentDTO>> {
    return new Promise((resolve, reject) => {
      this.uploadContentsService
        .getUploadContents(
          null,
          UPLOAD_CONTENT_TYPE.GENERAL,
          null,
          rangeFrom,
          rangeTo,
          true,
          categoryIds,
          ITEM_TYPE.CATEGORY,
          true,
          false,
          false,
          [
            Resource_Types.NAME,
            Resource_Types.SHORT_DESCRIPTION,
            Resource_Types.DESCRIPTION,
            Resource_Types.URL_PATH,
            Resource_Types.VIDEO_URL,
          ],
          true
        )
        .then((items: Array<UploadContentDTO>) => {
          if (items) {
            resolve(items);
            return;
          }

          resolve([]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Gets category counters
   * @param input
   */
  public getCategoryCountersByMutlRefs(
    input: Array<string>,
    refItemTypeId: number
  ): Promise<number> {
    return new Promise<number>(async (resolve, reject) => {
      if (isNullOrUndefined(input) || !isArray(input) || !input.length)
        reject(null);
      else {
        const _tmp = await to(
          this.uploadContentsService.getUploadContentByReferenceAndUploadContentType(
            input,
            refItemTypeId,
            UPLOAD_CONTENT_TYPE.GENERAL,
            false,
            [],
            true,
            true,
            null,
            false,
            0,
            1
          )
        );
        if (isNullOrUndefined(_tmp.data)) reject(null);
        else {
          if (_tmp.data.hasOwnProperty("X-Talos-Item-Count")) {
            resolve(parseInt(_tmp.data["X-Talos-Item-Count"]));
          } else reject(null);
        }
      }
    });
  }

  /**
   * Gets extended related items
   * @param categoryIds
   * @param rangeFrom
   * @param rangeTo
   */
  public async getExtendedRelatedItems(
    categoryIds: Array<string>,
    rangeFrom: number,
    rangeTo: number
  ): Promise<KpDictionary<ExtendedUploadContentDTO>> {
    return new Promise<KpDictionary<ExtendedUploadContentDTO>>(
      async (resolve, reject) => {
        if (
          isNullOrUndefined(categoryIds) ||
          !isArray(categoryIds) ||
          !categoryIds.length
        )
          reject(null);
        else if (!categoryIds.length) reject(categoryIds);
        else {
          const _getUploadContents = await to(
            this.uploadContentsService.getUploadContentByCategoryUploadContentType(
              categoryIds,
              UPLOAD_CONTENT_TYPE.GENERAL,
              true,
              TAConstants.Settings.LANGUAGES,
              true,
              true,
              false,
              rangeFrom,
              rangeTo,
              "BY_RECENT"
            )
          );
          if (isNullOrUndefined(_getUploadContents.data)) reject(null);
          else {
            let _formattedUploadContents: KpDictionary<ExtendedUploadContentDTO> =
              new KpDictionary<ExtendedUploadContentDTO>();
            const content =
              _getUploadContents.data as ItemsList<UploadContentDTO>;

            const ids = content.map((i) => {
              return i.uploadContentId;
            });

            const input: GetUserActionStatusInput = {
              itemIds: ids,
              itemTypeId: ITEM_TYPES.UPLOAD_CONTENT,
              unitTypeIds: [Unit_Types.IQOS_CLUB_IT_POINTS],
              includeLimitations: true,
            };
            const event = Events_Types.ITEM_VIEWED;

            const actions = await this.itemSrv
              .getUserActions(event, null, input)
              .catch((err) => {});
            if (actions) {
              actions.forEach((a) => {
                content.forEach((i) => {
                  if (
                    a.itemId == i.uploadContentId &&
                    a.actionsStatus &&
                    a.actionsStatus.length > 0
                  ) {
                    i.actionsStatus = a.actionsStatus[0];
                  }
                });
              });
            }

            content.map(async (item: ExtendedUploadContentDTO) => {
              this.getArticleText(item);
              let _referencesMapped: KpDictionary<ExtendedUploadContentReferenceDTO> =
                new KpDictionary<ExtendedUploadContentReferenceDTO>();
              if (isArray(item.references) && item.references.length) {
                await item.references.map(
                  (reference: ExtendedUploadContentReferenceDTO) => {
                    if (
                      !_referencesMapped.ContainsKey(
                        reference.itemIdForResourceLookup
                      )
                    ) {
                      _referencesMapped.Add(
                        reference.itemIdForResourceLookup,
                        reference
                      );
                    }
                  }
                );
              }
              _formattedUploadContents.Add(item.uploadContentId, {
                ...item,
                referencesMapped: _referencesMapped,
                resourcesMapped: this.utilSrv.formatItemResources(item),
              });
            });
            if (_formattedUploadContents.Count) {
              resolve(_formattedUploadContents);
            } else reject(null);
          }
        }
      }
    );
  }

  public async getExtendedRelatedItemsNew(
    categoryIds: Array<string>,
    rangeFrom: number,
    rangeTo: number
  ): Promise<Array<MaterialObject>> {
    return new Promise<Array<MaterialObject>>(async (resolve, reject) => {
      const aCategoryId = this.materialSrv.getCategoryIdByType(
        Number(MaterialType.ARTICLE),
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const vCategoryId = this.materialSrv.getCategoryIdByType(
        Number(MaterialType.VIDEO),
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const qCategoryId = this.materialSrv.getCategoryIdByType(
        Number(MaterialType.QUIZ),
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const quickCategoryId = this.materialSrv.getCategoryIdByType(
        Number(MaterialType.QUICK_REPLY),
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const gameCategoryId = this.materialSrv.getCategoryIdByType(
        Number(MaterialType.GAME),
        APPLICATION_SETTING_KEYS.MATERIAL_TYPE_IDS
      );
      const ids = categoryIds.filter((c) => {
        return (
          c != aCategoryId &&
          c != vCategoryId &&
          c != qCategoryId &&
          c != quickCategoryId &&
          c != gameCategoryId
        );
      });

      const dataFilters = [
        {
          property: "categoryIds",
          propertyValue: ids[0],
          mode: 3,
        },
      ];

      if (aCategoryId && aCategoryId != "") {
        ids.push(aCategoryId);
      }

      if (vCategoryId && vCategoryId != "") {
        ids.push(vCategoryId);
      }

      if (qCategoryId && qCategoryId != "") {
        ids.push(qCategoryId);
      }

      if (quickCategoryId && quickCategoryId != "") {
        ids.push(quickCategoryId);
      }

      if (gameCategoryId && gameCategoryId != "") {
        ids.push(gameCategoryId);
      }

      const input: FilterESContentDataInput = {
        itemTypeIds: [
          ITEM_TYPES.UPLOAD_CONTENT,
          ITEM_TYPES.QUIZ,
          // ITEM_TYPES.ITEM_TYPE_CONTENT,
        ],
        categoryIds: ids,
        rangeFrom: rangeFrom,
        rangeTo: rangeTo,
        currentlyRewarding: null,
        sortOrderTypes: ["BY_RECENT"],
        dataFilters: dataFilters,
        countMode: false,
        userRewarded: null,
        currentlyActive: true,
        matchingLevel: true,
        matchingRules: true,
      };

      const items = await this.recommendationsSrv
        .getRecommendationMaterial(input, this.userSrv.user.firstPurchaseDate)
        .catch((err) => {});
      if (!items) {
        reject(null);
        return;
      }

      resolve(items);
    });
  }

  getRecommendation(input: FilterESContentDataInput) {
    return new Promise<MaterialObject>(async (resolve, reject) => {
      const items = await this.recommendationsSrv
        .getRecommendationMaterial(input, this.userSrv.user.firstPurchaseDate)
        .catch((err) => {});
      if (!items || items.length == 0) {
        reject(null);
        return;
      }

      resolve(items[0]);
    });
  }

  getArticleText(obj: ExtendedUploadContentDTO) {
    let activityLim = null;
    let doneLim = null;
    const action = obj.actionsStatus;

    // const getNonWatchedText = () => {
    //     var result = "";
    //     var overviewResult = "";
    //     if (action.actionRewards) {
    //         let amount = 0;
    //
    //         action.actionRewards.forEach(u => {
    //             if (u.amount && u.amount.unitTypeId == Unit_Types.IQOS_CLUB_IT_POINTS) {
    //                 amount += u.amount.amount;
    //             }
    //         })
    //
    //         //obj.reference.amountToEarn = amount;
    //         //obj.reference.canEarn = true;
    //
    //         const publishDate = this.utilSrv.valueFromKeyValueDTOArray(METADATA_KEY.QUIZ_PUBLISH_DATE, obj.metadata);
    //         let pubDate;
    //         if (publishDate && publishDate != "") {
    //             pubDate = moment(publishDate, 'YYYY-MM-DD HH:mm:ss').toDate();
    //         }
    //
    //         const rewardDaysLimit = Number(this.utilSrv.valueFromKeyValueDTOArray(METADATA_KEY.QUIZ_REWARD_DAYS_LIMIT, obj.metadata)) || -1;
    //
    //         if (pubDate && rewardDaysLimit != -1) {
    //             pubDate.setDate(pubDate.getDate() + rewardDaysLimit);
    //             result = `${amount} ${this.translateSrv.instant('PUNTI')} <span style="color:#8B8B8B;font-weight: 500">${this.translateSrv.instant('ENTRO_IL')} ${moment(pubDate).format('DD/MM')}</span>`
    //             overviewResult = `${this.translateSrv.instant('RISPONDI')} ${this.translateSrv.instant('ENTRO_IL')} ${moment(pubDate).format('DD/MM')} <span style="color:#56B286;font-weight: 500">+ ${amount} PT</span>`;
    //
    //         } else {
    //             result = `${amount} ${this.translateSrv.instant('PUNTI')}`
    //             overviewResult = `${this.translateSrv.instant('RISPONDI')} <span style="color:#56B286;font-weight: 500">+ ${amount} PT</span>`;
    //         }
    //     }
    //
    //     obj.pointsLabel = result;
    //     // obj.overviewButton = overviewResult;
    // }

    const getNonWatchedText = () => {
      var result = "";
      var overviewResult = "";
      const label = this.translateSrv.instant("GEGGI");
      if (action.actionRewards) {
        let amount = 0;

        action.actionRewards.forEach((u) => {
          if (
            u.amount &&
            u.amount.unitTypeId == Unit_Types.IQOS_CLUB_IT_POINTS
          ) {
            amount += u.amount.amount;
          }
        });

        const publishDate = this.utilSrv.valueFromKeyValueDTOArray(
          METADATA_KEY.UPLOAD_CONTENT_PUBLISH_DATE,
          obj.metadata
        );
        let pubDate;
        if (publishDate && publishDate != "") {
          pubDate = moment(publishDate, "YYYY-MM-DD HH:mm:ss").toDate();
        }

        const rewardDaysLimit =
          Number(
            this.utilSrv.valueFromKeyValueDTOArray(
              METADATA_KEY.UPLOAD_CONTENT_REWARD_DAYS_LIMIT,
              obj.metadata
            )
          ) || -1;

        if (pubDate && rewardDaysLimit != -1) {
          pubDate.setDate(pubDate.getDate() + rewardDaysLimit);
          overviewResult = `${this.translateSrv.instant(
            "READ"
          )} ${this.translateSrv.instant("ENTRO_IL")} ${moment(pubDate).format(
            "DD/MM"
          )} <span style="color:#56B286;font-weight: 500">+ ${amount} PT</span>`;
          result = `${amount} ${this.translateSrv.instant(
            "PUNTI"
          )} <span style="color:#8B8B8B;font-weight: 500">${this.translateSrv.instant(
            "ENTRO_IL"
          )} ${moment(pubDate).format("DD/MM")}</span>`;
        } else {
          overviewResult = `${this.translateSrv.instant(
            "READ"
          )} <span style="color:#56B286;font-weight: 500">+ ${amount} PT</span>`;
          result = `${amount} ${this.translateSrv.instant("PUNTI")}`;
        }
      }

      obj.pointsLabel = result;
      // obj.overviewButton = overviewResult;
    };

    const getWatchedText = () => {
      var points = "";
      var overviewPoints = "";
      const label = this.translateSrv.instant("LETTO");

      if (action.userRewards) {
        let amount = 0;

        action.userRewards.forEach((u) => {
          if (u.unitTypeId == Unit_Types.IQOS_CLUB_IT_POINTS) {
            amount += u.amount;
          }
        });

        points = `<span style="color:#8B8B8B;font-weight: 500">${amount} ${this.translateSrv.instant(
          "PUNTI_GUADAGNATI"
        )}</span>`;
        overviewPoints = `<span style="color:#56B286;font-weight: 500">+ ${amount} ${this.translateSrv.instant(
          "PT_GUADAGNATI"
        )}</span>`;
      }
      obj.pointsLabel = `${label} ${points}`;
      // obj.overviewButton = `${label} ${overviewPoints}`;
    };

    // const getWatchedText = () => {
    //     var points = "";
    //     var overviewPoints = "";
    //
    //     const label = this.translateSrv.instant('QUIZ_DONE');
    //     if (action.userRewards) {
    //         let amount = 0;
    //
    //         action.userRewards.forEach(u => {
    //             if (u.unitTypeId == Unit_Types.IQOS_CLUB_IT_POINTS) {
    //                 amount += u.amount
    //             }
    //         })
    //
    //         points = `<span style="color:#8B8B8B;font-weight: 500">${amount} ${this.translateSrv.instant('PUNTI_GUADAGNATI')}</span>`;
    //         overviewPoints = `<span style="color:#56B286;font-weight: 500">+ ${amount} ${this.translateSrv.instant('PT_GUADAGNATI')}</span>`;
    //     }
    //     // const result = `${label} ${points}`;
    //     obj.pointsLabel = `${label} ${points}`;
    //     // obj.overviewButton = `${buttonText} ${overviewPoints}`;
    // }

    if (action) {
      if (action.limitations) {
        action.limitations.forEach((a) => {
          if (a.typeId == 1) {
            doneLim = a;
          } else if (a.typeId == 2) {
            activityLim = a;
          }
        });
      }

      const active = !(activityLim && activityLim.limitReached);
      const done = doneLim && doneLim.limitReached;

      if (done) {
        getWatchedText();
      } else {
        if (active) {
          getNonWatchedText();
        } else {
          obj.pointsLabel = this.translateSrv.instant("PUNTI_NON_DISPONIBILI");
          // obj.overviewButton = obj.button;
        }
      }
    }
  }

  getEventRewardsRules(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.eventSrv
        .getEventRewardRules(
          Settings.GAME_TYPE,
          [Events_Types.ITEM_VIEWED],
          true,
          LANGUAGES
        )
        .then((result: Array<EventRewardRulesDTO>) => {
          let r = "";
          if (result && result.length > 0) {
            const e = result[0];
            r =
              e.rewards && e.rewards.length > 0
                ? this.resourcesSrv.getResourcesBasic(
                    e.rewards[0],
                    Resource_Types.NAME
                  )
                : "";
          }
          resolve(r);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getEventRewardsRulesNew(): Promise<Map<number, string>> {
    return new Promise((resolve, reject) => {
      this.eventSrv
        .getEventRewardRules(
          Settings.GAME_TYPE,
          [
            Events_Types.ITEM_VIEWED,
            Events_Types.VIDEO_WATCHED,
            Events_Types.QUIZ_COMPLETION,
            Events_Types.POLL_SURVEY_COMPLETED,
          ],
          true,
          LANGUAGES
        )
        .then((result: Array<EventRewardRulesDTO>) => {
          this.eventRewards = new Map<number, string>();
          if (result) {
            result.forEach((e: EventRewardRulesDTO) => {
              let testString =
                e.rewards && e.rewards.length > 0
                  ? this.resourcesSrv.getResourcesBasic(
                      e.rewards[0],
                      Resource_Types.NAME
                    )
                  : "";
              if (testString == "") {
                testString =
                  e.rewards && e.rewards.length > 1
                    ? this.resourcesSrv.getResourcesBasic(
                        e.rewards[1],
                        Resource_Types.NAME
                      )
                    : "";
              }
              if (testString != "") {
                this.eventRewards[e.eventTypeId] = testString;
              }
            });
          }
          resolve(this.eventRewards);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  sendWatchedEvent(itemId: string, itemTypeId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.itemSrv
        .submitStatistics(
          itemId,
          Number(itemTypeId),
          STATISTIC_TYPES.ITEM_VIEWED,
          1
        )
        .then((result: any) => {
          resolve("SUCCESS");
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getQuizToAnswer(quizId: string): Promise<QuizDTO> {
    return new Promise((resolve, reject) => {
      const query: ListQuizQuery = {
        quizIds: [quizId],
        gameTypeId: Settings.GAME_TYPE,
        includeQuestions: true,
        rangeFrom: 0,
        rangeTo: 1,
        metadatas: true,
        resources: true,
        languageIds: TAConstants.Settings.LANGUAGES,
      };
      this.quizSrv
        .getQuizById(query)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getArticleWatchedThreshold(categoryId: string): number {
    let result = -1;
    if (
      this.materialConfig &&
      this.materialConfig[APPLICATION_SETTING_KEYS.WATCHED_THRESHOLD]
    ) {
      result =
        this.materialConfig[APPLICATION_SETTING_KEYS.WATCHED_THRESHOLD][
          categoryId
        ] || -1;
    }
    return result;
  }
}
