import { Injectable } from "@angular/core";
import { MenuItem } from "../models/MenuItem";
import { BehaviorSubject, Observable } from "rxjs";
import { WelcomePromoContent } from "src/models/WelcomePromoContent";
import { Params } from "@angular/router";
import { BannerConfiguration } from "src/talosApi/models/IconBannerConfigDTO";
import { Mission } from "src/app/pages/profile/profile.service";

@Injectable()
export class AppGlobalsService {
  constructor() {}

  private _config: any = {};
  private _redirectUrl: string = null;
  private _queryParams: Params;
  private _isMobile: boolean;
  private _smallScreenView: boolean;
  private _menuTransparent: boolean;
  private _removePadding: boolean;
  private _promoContent: any;
  private _countDownPromoContent: any;
  private _countDownBannerContent: any;
  private _backgroundContent: any;
  private _hasDonate: boolean;
  private _donateButtons: any;
  private _topBanner: any;
  private _donationHide: boolean;
  private _mainMenu: MenuItem;
  private _profileMenu: MenuItem;
  private _wyng: boolean;
  private _removeFooter: boolean;
  private _appLoaded: boolean;
  private _hasWelcome: boolean;
  private _hasUnicodePopup: boolean;
  private _isPreview: boolean;
  private _bannerConfig: BannerConfiguration;
  private _loginWeeklyAch: Mission;
  private _mustShowWelcomeContent: boolean;
  private _hasOnboardingPopup: boolean;
  private _hasIlumaIPrimePopup: boolean;
  private _isBoardingEligible: boolean;
  private _hasWelcomeBackPopup: boolean;
  private _hasGenericNewPopup: boolean;

  private _slideBannerSubject = new BehaviorSubject<any>(undefined);
  public slideBanner$ = this._slideBannerSubject.asObservable();

  private welcomePromoContentSubject = new BehaviorSubject<WelcomePromoContent>(
    undefined
  );
  public welcomePromoContent$: Observable<WelcomePromoContent> =
    this.welcomePromoContentSubject.asObservable();

  private appLoadedSubject = new BehaviorSubject<boolean>(false);
  public appLoaded$: Observable<boolean> = this.appLoadedSubject.asObservable();

  public loadSlideBanner() {
    return this._slideBannerSubject.getValue();
  }

  public saveSlideBanner(value) {
    this._slideBannerSubject.next(value);
  }

  get removeFooter(): boolean {
    return this._removeFooter;
  }

  set removeFooter(value: boolean) {
    this._removeFooter = value;
  }

  get mainMenu(): MenuItem {
    return this._mainMenu;
  }

  set mainMenu(value: MenuItem) {
    this._mainMenu = value;
  }

  get profileMenu(): MenuItem {
    return this._profileMenu;
  }

  set profileMenu(value: MenuItem) {
    this._profileMenu = value;
  }

  get donationHide(): boolean {
    return this._donationHide;
  }

  set donationHide(value: boolean) {
    this._donationHide = value;
  }

  get appLoaded(): boolean {
    return this._appLoaded;
  }

  set appLoaded(value: boolean) {
    this._appLoaded = value;
  }

  public saveAppLoaded(value: boolean) {
    this.appLoadedSubject.next(value);
  }

  // public loadAppLoaded(){
  //     return this.appLoadedSubject.getValue();
  // }

  public saveWelcomePromo(value: WelcomePromoContent) {
    logger.log("SAVING WELCOME DATA SUBJECT", value);
    this.welcomePromoContentSubject.next(value);
  }

  public loadWelcomePromo(): WelcomePromoContent {
    return this.welcomePromoContentSubject.getValue();
  }

  get hasWelcome(): boolean {
    return this._hasWelcome;
  }

  set hasWelcome(value: boolean) {
    this._hasWelcome = value;
  }

  get hasUnicodePopup(): boolean {
    return this._hasUnicodePopup;
  }

  set hasUnicodePopup(value: boolean) {
    this._hasUnicodePopup = value;
  }

  // get hasOnboardingPopup(): boolean {
  //   return this._hasOnboardingPopup;
  // }

  // set hasOnboardingPopup(value: boolean) {
  //   this._hasOnboardingPopup = value;
  // }

  // get hasWelcomeBackPopup(): boolean {
  //   return this._hasWelcomeBackPopup;
  // }

  // set hasWelcomeBackPopup(value: boolean) {
  //   this._hasWelcomeBackPopup = value;
  // }

  get hasGenericNewPopup(): boolean {
    return this._hasGenericNewPopup;
  }

  set hasGenericNewPopup(value: boolean) {
    this._hasGenericNewPopup = value;
  }

  // get hasIlumaIPrimePopup(): boolean {
  //   return this._hasIlumaIPrimePopup;
  // }

  // set hasIlumaIPrimePopup(value: boolean) {
  //   this._hasIlumaIPrimePopup = value;
  // }

  get topBanner(): any {
    return this._topBanner;
  }

  set topBanner(value: any) {
    this._topBanner = value;
  }

  get config(): any {
    return this._config;
  }

  set config(value: any) {
    this._config = value;
  }

  get donateButtons(): any {
    return this._donateButtons;
  }

  set donateButtons(value: any) {
    this._donateButtons = value;
  }

  get hasDonate(): boolean {
    return this._hasDonate;
  }

  set hasDonate(value: boolean) {
    this._hasDonate = value;
  }

  get isMobile(): boolean {
    return this._isMobile;
  }

  set isMobile(value: boolean) {
    this._isMobile = value;
  }

  get smallScreenView(): boolean {
    return this._smallScreenView;
  }

  set smallScreenView(value: boolean) {
    this._smallScreenView = value;
  }

  get menuTransparent(): boolean {
    return this._menuTransparent;
  }

  set menuTransparent(value: boolean) {
    this._menuTransparent = value;
  }

  get removePadding(): boolean {
    return this._removePadding;
  }

  set removePadding(value: boolean) {
    this._removePadding = value;
  }

  get redirectUrl(): string {
    return this._redirectUrl;
  }

  set redirectUrl(value: string) {
    this._redirectUrl = value;
  }

  get queryParams(): Params {
    return this._queryParams;
  }

  set queryParams(value: Params) {
    this._queryParams = value;
  }

  get promoContent(): any {
    return this._promoContent;
  }

  set promoContent(value: any) {
    this._promoContent = value;
  }

  get countDownPromoContent(): any {
    return this._countDownPromoContent;
  }

  set countDownPromoContent(value: any) {
    this._countDownPromoContent = value;
  }

  get countDownBannerContent(): any {
    return this._countDownBannerContent;
  }

  set countDownBannerContent(value: any) {
    this._countDownBannerContent = value;
  }

  get backgroundContent(): any {
    return this._backgroundContent;
  }

  set backgroundContent(value: any) {
    this._backgroundContent = value;
  }

  get wyng(): boolean {
    return this._wyng;
  }

  set wyng(value: boolean) {
    this._wyng = value;
  }

  get isPreview(): boolean {
    return this._isPreview;
  }

  set isPreview(value: boolean) {
    this._isPreview = value;
  }

  get bannerConfig(): BannerConfiguration {
    return this._bannerConfig;
  }

  set bannerConfig(value: BannerConfiguration) {
    this._bannerConfig = value;
  }

  get loginWeeklyAch(): Mission {
    return this._loginWeeklyAch;
  }

  set loginWeeklyAch(value: Mission) {
    this._loginWeeklyAch = value;
  }

  get mustShowWelcomeContent(): boolean {
    return this._mustShowWelcomeContent;
  }

  set mustShowWelcomeContent(value: boolean) {
    this._mustShowWelcomeContent = value;
  }

  get isBoardingEligible(): boolean {
    return this._isBoardingEligible;
  }

  set isBoardingEligible(value: boolean) {
    this._isBoardingEligible = value;
  }
}
